<template>
<v-row class="page-breadcrumb">
    <v-col cols="12" sm="12" class="pa-0 header-page">
        <v-card class="custom-shaodow pt-4 pl-4">
            <v-toolbar dense flat class="transparent pa-0">
                <!-- TITLE -->
                <v-toolbar-title class="pt-3 pl-1">
                    <span class="text--primary h6">{{ title }}</span>
                </v-toolbar-title>
                <v-toolbar-title class="toobar-extension transparent pb-3" slot="extension">
                    <!-- LINK  -->
                    <v-breadcrumbs divider=">" :items="breadcrumbs" class="pa-0">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item class="primary--text" :disabled="item.disabled">
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
        </v-card>
    </v-col>
</v-row>
</template>

<script>
export default {
    name: "BasePageHeader",

    props: {
        title: String,
        breadcrumbs: Array,
    },

    data: () => ({}),
};
</script>

<style lang="scss">

.header-page {

    margin: -15px 0 15px 0;

    .pt-4 {
        padding: 0 !important;
    }

    .pt-3 {
        padding-top: 0 !important;
    }

    .v-toolbar__content {
        margin-top: 10px;
    }

    .v-toolbar__content,
    .v-toolbar__extension {
        height: auto !important;
    }

    .theme--light {
        .text--primary {
            color: #fff !important;
            font-size: 20px;
        }
    }

    header {
        height: auto !important;
    }
    
    .v-breadcrumbs__item {
        color: rgb(185, 185, 185) !important;
    }

    .theme--light.v-card {
        background-color: #1e88e5;
    }
}

</style>
