import axios from 'axios';
import { getUnixTime } from "date-fns";

const api = axios.create({
	baseURL: process.env.VUE_APP_BASE_URL
});


api.interceptors.request.use((config) => {
	// Reseta tempo para 10 minutos sempre que o usuario fizer um request
	if (localStorage.isLogged && localStorage.autoLoggout) {
		const minutos = 10
		localStorage.setItem('expire', getUnixTime(new Date) + (minutos * 60))
	}
	return config;
});


export default api;
