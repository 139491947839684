
<template>
<v-dialog v-model="show" max-width="800px">
    <v-card>
        <!-- TEXT CHANGE IF IS NEW OR EDIT -->
        <v-card-text v-if="register == true" class="pa-5 border-bottom">
            <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Vincular região.</h3>
            <h6 class="subtitle-2 font-weight-light">Preencha todas os campos abaixo e confirme o cadastro de uma nova aposta.</h6>
        </v-card-text>

        <v-card-text v-if="register == true" class="pa-5 border-bottom">
            <v-form v-model="valid" lazy-validation ref="formConfigRegion">
                <v-row class="ma-0 pa-0 justify-space-between">

                    <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="py-0 my-0">
                        <v-select dense no-data-text="Sem dados disponíveis." outlined :rules="[value => !!value || 'Selecione a região']" :loading="loading.regions" :disabled="loading.regions" item-text="name" item-value="id" v-model="configRegion.region_id" :items="regions" label="Região"></v-select>
                    </v-col> 
                   
                    <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="py-0 my-0">
                        <v-select dense no-data-text="Sem dados disponíveis." outlined :rules="[value => !!value || 'Selecione o admin de região']" :loading="loading.admin_regions" :disabled="loading.admin_regions" item-text="name" item-value="id" v-model="configRegion.admin_region_id" :items="admin_regions" label="Admin de região"></v-select>
                    </v-col> 

                    <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="py-0 my-0">
                        <v-select dense no-data-text="Sem dados disponíveis." outlined :rules="[value => !!value || 'Selecione o sub admin.']" :loading="loading.sub_admins" :disabled="loading.sub_admins" item-text="name" item-value="id" v-model="configRegion.sub_admin_id" :items="sub_admins" label="Sub admin"></v-select>
                    </v-col>

                </v-row>
            </v-form>
        </v-card-text>

        <!-- ACTIONS CHANGE IF IS NEW OR EDIT -->
        <v-card-actions >
            <v-btn color="red darken-1" text @click="close">CANCELAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" :disabled="!valid" text @click="configRegionSave">NOVO RELACIONAMENTO</v-btn>
        </v-card-actions>

    </v-card>
</v-dialog>
</template>

<script>

export default {
    name: "ModalConfigRegionNewEdit",

    props: {
        configRegion: Object,
        register: Boolean,
        dialog: Boolean,
    },

    data: () => ({
        // SNACKBAR
        snackbar: {},
        item:{},
        
        avaliableAward: {},
        
        regions: [],
        sub_admins: [],
        admin_regions: [],
        
        loading: {
            sub_admins: false,
            admin_regions: false,
            regions: false
        },

        // DIALOG 
        valid: false,
        checkbox: false,
        show: false,
    }),

    watch: {
        // WATCH THE VALUE TO OPEN THE MODAL
        dialog: function (val) {
            this.show = val;
        },

        // IF USER CLICKS OUTSIDE THE DIALOG 
        show: function (val) {
            val || this.close();
        },
    },

    computed: {
        // FORMAT DATE
        formatNewDate: {
            get() {
                return this.formatDate(this.ex_date);
            },

            set() {
                this.ex_date = null;
            }
        },
    },

    created() { 
        this.loadRegions() 
        this.loadAdminRegions()    
        this.loadSubAdmins()        
    },
    
    methods: {
        // CLOSE MODAL   
        close() {
            //this.resetFields();
            this.$emit('close');
            this.$refs.formConfigRegion.reset();
        },

        // RELOAD PARENT
        reload() {
            this.close();
            this.$emit('reload');
        },


        // LOAD AWARDS
        loadRegions() {
            this.loading.regions = true;

            this.$api
                .get(`/regions`, { 
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                })
                .then((response) => {
                    this.regions = response.data;
                    this.loading.regions = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar as regiões.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);

                    this.table.loading = false;
                });

              
        },

         // LOAD AWARDS
        loadSubAdmins() {
            
            this.$api
                .get(`/users/subAdmins`, {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                })
                .then((response) => {
                    this.sub_admins = response.data;
                    this.loading.sub_admins = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar os prêmios.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);

                    this.loading.sub_admins = false;
                });

        },

        loadAdminRegions() {
            
            this.$api
                .get(`/users/admins`, {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                })
                .then((response) => {
                    this.admin_regions = response.data;
                    this.loading.admin_regions = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar os prêmios.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);

                    this.loading.admin_regions = false;
                });

        },

        resetFields() {
            this.admin_regions = []
            this.regions = []
            this.sub_admins = []
        },

        // NEW SELLER AWARD
        configRegionSave() {
          
            if (this.$refs.formConfigRegion.validate()) {

                this.$api
                .post("/config_regions", this.configRegion, {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    }
                })
                .then(() => {
                    this.snackbar.text = "Relacionamento cadastrado com sucesso!";
                    this.snackbar.type = "green";
                    this.snackbarShow(this.snackbar);
                })
                .catch(() => {
                    this.snackbar.text = "Erro ao cadastrar nova relacionamento!";
                    this.snackbar.type = "error";
                    this.snackbarShow(this.snackbar);
                });
        
        
                this.reload();

            }
             
        },
    }
}
</script>
