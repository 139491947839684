<template>
<v-dialog v-model="show" max-width="800px">
    <v-card>
        <!-- TEXT CHANGE IF IS REGISTER OR EDIT -->
        <v-card-text v-if="register == true" class="pa-5 border-bottom">
            <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Cadastro de novo auxiliar.</h3>
            <h6 class="subtitle-2 font-weight-light">Preencha todas os campos abaixo e confirme o cadastro de um novo auxiliar no sistema.</h6>
        </v-card-text>

        <v-card-text v-else class="pa-5 border-bottom">
            <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Perfil do usuário: <b v-text="assistant.username"></b>.</h3>
            <h6 class="subtitle-2 font-weight-light">Preencha todas os campos abaixo e confirme a edição do cadastro do usuário no sistema.</h6>
        </v-card-text>

        <!-- FORM -->
        <v-card-text class="pa-5 border-bottom">
            <v-form v-model="valid" lazy-validation ref="formassistant">
                <v-row>
                    <v-col cols="12" lg="9" md="9" sm="12" xs="12" class="py-0 my-0">
                        <v-select multiple dense v-model="addRegions" label="Regiões" :items="regions" :loading="loading.regions" :disabled="loading.regions" item-text="name" item-value="id" no-data-text="Sem dados disponível." outlined></v-select>
                    </v-col>

                    <!-- NEW REGION  -->
                    <v-col v-if="register == false" cols="12" lg="3" md="3" sm="3" xs="12" class="py-0 my-0">
                        <v-btn style="width: 100%;" color="primary" height="40px" dark block @click="regionNew">NOVA REGIÃO</v-btn>
                    </v-col>

                    <v-col v-if="register == false" cols="12" lg="12" md="12" sm="12" xs="12" class="py-0 my-0" style="margin-bottom: 15px !important;">
                        <template>
                            <v-data-table
                                :headers="headers" :items="assistant_region"
                                class="elevation-1" hide-default-footer
                                no-data-text="Não há usuários." no-results-text="Sem resultados." 
                                loading-text="Carregando..."
                                :loading="loading.assistant_region" :disabled="loading.assistant_region"
                            >
                            
                            <!-- ICON REMOVE -->
                            <template v-slot:[`item.remove`]="{ item }">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn @click="regionAssistantRemove(item)" v-bind="attrs" v-on="on" color="red" icon>
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </template>

                                    <span>
                                        <div>REMOVER REGIÃO</div>
                                    </span>
                                </v-tooltip>
                            </template>

                            </v-data-table>
                        </template>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" lg="6" md="6" sm="12" xs="12" class="py-0 my-0">
                        <v-text-field dense label="Username" v-model="assistant.username" :rules="[value => !!value || 'Informe username.']" outlined clearable></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="6" md="6" sm="12" xs="12" class="py-0 my-0">
                        <v-text-field dense label="Nome de usuário" v-model="assistant.name" :rules="[value => !!value || 'Informe o nome de usuário.']" outlined clearable></v-text-field>
                    </v-col>
                    
                    <v-col v-if="register == true" cols="12" lg="6" md="6" sm="12" xs="12" class="py-0 my-0">
                        <v-text-field dense label="Senha de usuário" v-model="assistant.password" :rules="[value => !!value || 'Informe a senha de usuário.']" outlined clearable></v-text-field>
                    </v-col>

                    <v-col v-if="register == true" cols="12" lg="6" md="6" sm="12" xs="12" class="py-0 my-0">
                        <v-text-field dense label="Confirmar senha" v-model="assistant.password_confirmation" outlined clearable></v-text-field>
                    </v-col>
       
                    <v-col cols="12" xs="12" class="py-0 my-0">
                        <v-checkbox label="Confirmar usuário?" v-model="checkbox" :rules="[value => !!value || 'É necessário confirmar.']" dense required class="ma-0" color="green"></v-checkbox>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>

        <!-- ACTIONS CHANGE IF IS REGISTER OR EDIT -->
        <v-card-actions v-if="register == true">
            <v-btn color="red darken-1" text @click="close">CANCELAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" :disabled="!valid" text @click="assistantSave">SALVAR NOVO AUXILIAR</v-btn>
        </v-card-actions>

        <v-card-actions v-else>
            <v-btn color="red darken-1" text @click="close">CANCELAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" :disabled="!valid" text @click="assistantEdit(assistant)">EDITAR AUXILIAR</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
export default {
    name: "ModalassistantNewEdit",

    props: {
        assistant: Object,
        edit: Boolean,
        register: Boolean,
        dialog: Boolean,
    },


    data: () => ({
        // SNACKBAR
        snackbar: {},

        loading: {
            regions: true,
            assistant_region: true
        },

        addRegions: [],

        regions: [],

        // DIALOG 
        valid: false,
        checkbox: false,
        show: false,
        region_new: false,

        headers: [
            { text: 'Nome', value: 'name' },
            { text: 'Remover', value: 'remove' },
        ],

        item: [],
        assistant_region: []
    }),

    watch: {

        // WATCH THE VALUE TO OPEN THE MODAL
        dialog: function (val) {
            this.show = val;
        },

        // IF USER CLICKS OUTSIDE THE DIALOG 
        show: function (val) {
            val || this.close();
        },

        assistant: function() {
            this.loadAssistantRegion();
        }
    },

    created() {
        this.loadRegions();
    },

    methods: {

        loadAssistantRegion() {

            this.loading.assistant_region = true;

            this.$api
                .get(`/regions/assistant_region`, { 
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                    params: { 
                        assistant_id: this.assistant.assistant_id 
                    }
                })
                .then((response) => {
                    this.assistant_region = response.data;
                    this.loading.assistant_region = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar as regiões.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);
                    this.loading.assistant_region = false;

                });
              
        },

        // DELETE USER
        regionAssistantRemove(item) {

            this.item = {
                region_id: item.id,
                assistant_id: this.assistant.assistant_id
            }

            this.$api
                .post(`/regions/assistantregion`, this.item, {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                })
                .then(() => {
                    this.snackbar.text = "Região deletada com sucesso!";
                    this.snackbar.type = "green";
                    this.snackbarShow(this.snackbar);
                    this.close();
                })
                .catch(() => {
                    this.snackbar.text = "Erro ao excluir a Região!";
                    this.snackbar.type = "error";
                    this.snackbarShow(this.snackbar);
                });

        },

        // OPEN DIALOG NEW AWARD
        regionNew() {

            var region_assistant = {
                region_id: this.addRegions,
                assistant_id: this.assistant.assistant_id
            }

            if(this.addRegions.length > 0) {

                this.$api
                    .post(`regions/add_assistant_region`, {...region_assistant}, {
                        headers: {
                            Authorization: `Bearer ${this.accessToken()}`
                        }, 
                    })
                    .then(() => {
                        this.snackbar.text = "Região adicionada com sucesso!";
                        this.snackbar.type = "green";
                        this.snackbarShow(this.snackbar);
                        this.close();
                    })
                    .catch(() => {
                        this.snackbar.text = "Erro ao adicionar a Região!";
                        this.snackbar.type = "error";
                        this.snackbarShow(this.snackbar);
                    });
            } else {
                this.snackbar.text = "Nenhuma Região selecionada!";
                this.snackbar.type = "error";
                this.snackbarShow(this.snackbar);
            }
        },

        // CLOSE MODAL   
        close() {
            this.$emit('close');
            this.$refs.formassistant.reset();
        },

        loadRegions() {
            this.loading.regions = true;

            this.$api
                .get(`/regions`, { 
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                })
                .then((response) => {
                    this.regions = response.data;
                    this.loading.regions = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar as regiões.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);

                    this.table.loading = false;
                });
              
        },

        // EDIT assistant
        assistantEdit() {
            if (this.$refs.formassistant.validate()) {
                
                this.$api
                    .patch(`/users/${this.assistant.user_id}`, this.assistant, {
                        headers: {
                            Authorization: `Bearer ${this.accessToken()}`
                        },
                    })
                    .then(() => {
                        this.snackbar.text = "Usuário atualizado com sucesso!";
                        this.snackbar.type = "green";
                        this.snackbarShow(this.snackbar);

                        this.close();
                    })
                    .catch(() => {
                        this.snackbar.text = "Erro na atualização do usuário!";
                        this.snackbar.type = "error";
                        this.snackbarShow(this.snackbar);
                    });
            }
        },

        // NEW assistant
        assistantSave() {
            if (this.$refs.formassistant.validate()) {
                
                this.assistant = {
                    username: this.assistant.username,
                    name: this.assistant.name,
                    type: 'auxiliar',
                    password: this.assistant.password,
                    password_confirmation: this.assistant.password_confirmation,
                    regions: this.addRegions
                }

                this.$api
                    .post(`/users/store`, this.assistant, {
                        headers: {
                            Authorization: `Bearer ${this.accessToken()}`
                        },
                        // params: {
                        //     assistant: this.assistant,
                        //     regions: this.addRegions 
                        // }
                    })
                    .then(() => {
                        this.snackbar.text = "Auxiliar cadastrado com sucesso!";
                        this.snackbar.type = "green";
                        this.snackbarShow(this.snackbar);
                        this.close();
                    })
                    .catch(() => {
                        this.snackbar.text = "Erro ao cadastrar novo Auxiliar!";
                        this.snackbar.type = "error";
                        this.snackbarShow(this.snackbar);
                    });
            } 

        },

    }
}
</script>
