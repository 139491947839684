<template>
    <v-app id="materialpro" :class="`${!$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'}`">
        <router-view />

        <Dialog class="dialog-discharge" :visible.sync="dialogDischarge" :modal="true" :containerStyle="{width: '350px'}">
            <span class="title-discharge-notification">
                <i class="fas fa-exclamation-triangle"></i>
                <h3>EXISTEM PALPITES QUE NÃO FORAM DESCARREGADOS</h3>
            </span>
            <template #footer>
                <button class="btn-discharge-notification">
                    <router-link style="color: #fff;" v-if="loggedUser().type != 'admin_regiao'" :to="`/${loggedUser().type}/discharges/launch`">DESCARREGAR</router-link>
                    <router-link style="color: #fff;" v-else :to="`/admin_region/discharges/launch`">IR PARA A PÁGINA</router-link>
                </button>
            </template>
        </Dialog>

        <audio id="audio" src="https://cdn.freesound.org/previews/413/413691_3906011-lq.mp3"></audio>
    </v-app>
</template>

<script>

import Vue from 'vue'
export const eventBus = new Vue();
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import mixin from "@/mixins/mixin";
import store from "@/store/store";

export default {
    
    name: 'App',

    components: {
        Button,
        Dialog,
    },

    data:() => ({
        dialogDischarge: false,
        notifications: '',
        logoutTimer: null
    }),
    
    created() {
        // Cria autoLoggout (true por padrao)
        if (localStorage.autoLoggout == undefined){
            localStorage.autoLoggout = 'true'
        }
        
        // Verifica cada segundo
        setInterval((e)=>{
            // Se estiver logado
            // Se o tempo tiver expirado
            // Se tiver habilitado o autoLoggout
            
            if ((localStorage.isLogged == 'true') && (!mixin.methods.verifyExpirate())) {
                if (localStorage.autoLoggout == 'true'){
                    // Faz logout
                    this.logOut()
                    window.location.reload()
                }
            }
        },1000)
    },

    mounted() {
        
        /*
        this.Echo().channel(`SendDischargeNotification`)
        .listen('NewDischargeNotification', () => {
            this.$api.get('discharges/notifications', {
                headers: {
                    Authorization: `Bearer ${this.accessToken()}`
                },
            })
            .then((response) => {
                this.notifications = response.data;

                if(this.notifications.total_excess > 0) {
                    this.dialogDischarge = true;
                    this.startingAudio();
                } else {

                }
            })
            .catch(() => {

            })
        })*/
    },

    methods: {
        startingAudio() {
            var audio = document.getElementById('audio').play();
            return audio
        },

        dischargeRoute() {
            this.$router.path = 'discharges/launch';
            this.dialogDischarge = false;
        }
    }

};
</script>

<style>
.p-dialog .p-dialog-footer button {
    width: 100%;
    background-color: red;
    color: #fff;
}

.dialog-discharge .p-dialog-header {
    justify-content: end;
}

.v-data-table-header-mobile {
    display: none;
}

input[type="date"]::-webkit-input-placeholder{ 
    visibility: hidden !important;
}

.title-discharge-notification i {
    font-size: 50px;
    color: red;
    margin-bottom: 20px;
}

input::-webkit-calendar-picker-indicator{
    display: none;
}
.pa-1 {
    padding: 4px !important;
    height: 50px;
}

*::-webkit-scrollbar {
	width: 5px;
	height: 5px;
	background-color: #fff;
}

.btn-discharge-notification {
    width: 100%;
    background-color: red;
    border-radius: 5px;
    cursor: pointer;
    padding: 15px;
}

*::-webkit-scrollbar-thumb {
	background-color: #1e88e5;
    margin: 5px;
}

.btn-table-simple {
    width: 100%;
    background-color: #1e88e5 !important;
    color: #fff !important;
    height: 40px !important;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th,
.v-application--is-ltr .v-data-table > .v-data-table__wrapper > table > tbody > tr > th {
    text-align: center !important;
    font-size: 13px !important;
    font-weight: bold !important;
    color: #fff !important;
    padding: 5px;
    height: 0;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    text-align: center !important;
    font-size: 13px !important;
    color: #000 !important;
    padding: 5px;
    height: 0 !important;
}

.v-text-field--outlined fieldset {
    border-color: #1e88e5;
    border-style: solid;
    border-width: 2px;
}

.theme--light.v-select .v-select__selection--comma,
.theme--light.v-input input, 
.theme--light.v-label,
.theme--light.v-icon {
    color: #000;
}

.theme--light.v-pagination .v-pagination__navigation .theme--light.v-icon {
    color: #000;
}

/* .theme--light.v-footer {
    background-color: #3c3c3c;
} */

.v-select__slot input {
    cursor: pointer;
}

.theme--light.v-application .text--primary,
.theme--light.v-breadcrumbs .v-breadcrumbs__divider,
.theme--light.v-breadcrumbs .v-breadcrumbs__item--disabled{
    color: #fff !important;
}

.v-list--nav .v-list-item:not(:last-child):not(:only-child) {
    margin-bottom: 0;
}

.printr {
    display: none;
}

@media screen and (min-width: 600px) {

    .header-btns {
        display: flex;
    }

}

@media screen and (max-width: 700px) {

    .table-primevue .p-datatable-thead > tr > th {
        min-width: 130px;
    }

}

@media print {

    .print {
        display: none;
    }

    .printr {
        display: block;
    }

    @page {
        margin: 0;
    }

}

.v-data-table__progress .column {
    background-color: #fff !important;
}

.btn-search {
    height: 40px !important;
    width: 100%;
    background-color: #6c888a  !important;
    color: #fff !important;
}

.table-primevue {
    text-align: center;
}

.table-primevue .p-datatable-tbody > tr > td {
    justify-content: center;
    padding: 5px;
    text-align: center;
    font-size: 14px !important;
    color: #000;
    background-color: #fff;
}

.table-primevue .p-datatable-thead > tr > th {
    justify-content: center;
    padding: 5px;
    font-weight: bold !important;
}

.table-primevue  .p-datatable-tfoot > tr > td {
    background-color: #1e88e5;
    color: #fff;
    font-weight: bold;
    padding: 0;
    text-align: center;
}

.p-datatable .p-sortable-column .p-sortable-column-badge {
    margin: 5px;
    background: #fff;
    padding: 5px;
    border-radius: 50%;
    height: 20px;
}

.table-primevue .p-sortable-column .p-sortable-column-icon {
    color: #fff;
    font-size: 10px;
}

.table-primevue .p-sortable-column .p-sortable-column-icon:hover {
    color: #fff5;
}

.table-primevue .p-sortable-column .p-sortable-column-badge {
    color: #1e88e5;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: #ffffff;
    border: 1px solid #1e88e5;
    color: #1e88e5;
}

.p-datatable .p-column-header-content {
    text-align: center;
}

.container-qtd-sales {
    display: flex;
    justify-content: center;
}

.item-qtd-sales {
    margin: 0 5px;
    font-weight: bold;
}

.font-mono {
    font-family: 'Roboto Mono', monospace;
}

.dialog-discharge {
    text-align: center;
}

</style>
