<template>
<v-dialog v-model="display" max-width="600px ">
    <v-card class="pa-5" v-if="ticket">
        <v-card-actions class="border-bottom">
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="close" icon><v-icon>mdi-close</v-icon></v-btn>
        </v-card-actions>

        <template v-if="ticket.extraction_id">
            <div style="margin-bottom: 10px" v-if="ticket.extraction_id" class="pa-5 elevation-1">
                <div style="display: flex; justify-content: space-between;">
                    <span style="font-size: 1rem;" class="black--text"> Extração:</span>
                    <span style="font-size: 1rem;" class="black--text">{{ticket.games[0].extraction_name}}</span>
                </div> 

                <div style="display: flex; justify-content: space-between;">
                    <span style="font-size: 1rem;" class="black--text"> Acertos:</span>
                    <span style="font-size: 1rem;" class="green--text">{{ticket.games_won}}</span>
                </div>

                <div style="display: flex; justify-content: space-between;">
                    <span style="font-size: 1rem;" class="black--text"> Erros:</span>
                    <span style="font-size: 1rem;" class="red--text">{{ticket.games_lost}}</span>
                </div>

                <div style="display: flex; justify-content: space-between;">
                    <span style="font-size: 1rem;" class="black--text"> Valor a receber:</span>
                    <span style="font-size: 1rem;" class="black--text">{{ calcularTotalRecebimento(ticket) }}</span>
                </div>

                <div style="display: flex; justify-content: space-between;">
                    <span style="font-size: 1rem;" class="black--text">{{ticket.paid? 'Bilhete autenticado' : 'Bilhete não autenticado'}}</span>
                </div>
            </div>

            <v-card style="padding: 30px" class="mx-auto elevation-5" color="white darken-2" dark max-width="600">
                <!-- TICKET INFO -->
                <div style="display: flex; flex-direction: column; align-items: center; margin-bottom: 20px; border-bottom: dashed black; border-top: dashed black;">
                    <span class="text-h7 black--text">BANCA ÚNICA</span>
                </div>

                <div style="display: flex; flex-direction: column; border-bottom: dashed black 2px; padding-bottom: 10px; margin-bottom: 10px;">
                    <span class="text-h7 black--text">PONTO: {{ticket.point_name}}</span>
                    <span class="text-h7 black--text">BILHETE: {{ticket.id}}</span>
                    <div style="display: flex: gap: 10px;">
                        <span class="text-h7 black--text">CÓDIGO: {{showCode ? ticket.code : '********'}}</span>
                        <button style="color: grey; height: 30px; width: 30px; border-radius: 50%; font-size: 20px;" @click="toggleCode()">
                            <i v-if="showCode" class="fas fa-eye"></i>
                            <i v-else class="fas fa-eye-slash"></i>
                        </button>
                    </div>
                    <span class="text-h7 black--text">EMISSÃO: {{ formatDateFull(ticket.created_at) }}</span>
                    <span class="text-h7 black--text">SORTEIO: {{ formatDateFull(ticket.extraction_date)}}</span>
                    <span class="text-h7 black--text">EXTRAÇÃO: {{ ticket.games[0].extraction_name}}</span>
                </div>

                <!-- TICKET GAMES -->
                <div v-if="this.$store.getters.getLogged && this.loggedUser().type != 'cambista'">
                    <div style="display: flex; flex-direction: column; border-bottom: dashed black 2px; padding-bottom: 10px;" v-for="(item, index) of ticket.games" :key="index">
                        <div v-if="item.status == 'win'" style="display: flex; justify-content: space-between; align-items: center; margin-top: 5px;">
                            <span class="text-h7 black--text" :class="{'font-weight-medium' : item.status == 'win'}"> 
                                Modalidade: 
                                {{
                                    item.modality_name 
                                    + isCombined(item) 
                                    + (item.modality_name == 'Milhar Brinde' ? (' (x' + item.modality_cotation + ')') : '')
                                }} 
                            </span>
                            <i class="fas fa-check-circle" style="color: lime; font-size: 30px"></i>
                        </div>
                        <span v-else class="text-h7 black--text">
                            Modalidade: 
                                {{
                                    item.modality_name 
                                    + isCombined(item) 
                                    + (item.modality_name == 'Milhar Brinde' ? (' (x' + item.modality_cotation + ')') : '')
                                }} 
                        </span>
                        <span class="text-h7 black--text" :class="{'font-weight-medium' : item.status == 'win'}"> Prêmios: {{ item.award_name }}</span>
                        <span class="text-h7 black--text" :class="{'font-weight-medium' : item.status == 'win'}"> Palpites: {{ JSON.parse(item.selected_numbers).join(', ') + ' (' + JSON.parse(item.selected_numbers).length + ')' }}</span>
                        <span class="text-h7 black--text" :class="{'font-weight-medium' : item.status == 'win'}"> Lado: {{ JSON.parse(item.selected_positions).join(', ') }}</span>
                        <span class="text-h7 black--text" :class="{'font-weight-medium' : item.status == 'win'}"> Sub-total: {{ item.value }}</span>
                    </div>
                </div>

                <div v-else>
                    <div style="display: flex; flex-direction: column; border-bottom: dashed black 2px; padding-bottom: 10px;" v-for="(item, index) of ticket.games" :key="index">
                        <div v-if="ticket.paid == 1 && item.status == 'win'" style="display: flex; justify-content: space-between; align-items: center; margin-top: 5px;">
                            <span class="text-h7 black--text" :class="{'font-weight-medium' : ticket.paid == 1 && item.status == 'win'}"> 
                                Modalidade: 
                                {{
                                    item.modality_name 
                                    + isCombined(item) 
                                    + (item.modality_name == 'Milhar Brinde' ? (' (x' + item.modality_cotation + ')') : '')
                                }} 
                            </span>
                            <i class="fas fa-check-circle" style="color: lime; font-size: 30px"></i>
                        </div>
                        <span class="text-h7 black--text" v-else>
                            Modalidade: 
                                {{
                                    item.modality_name 
                                    + isCombined(item) 
                                    + (item.modality_name == 'Milhar Brinde' ? (' (x' + item.modality_cotation + ')') : '')
                                }} 
                        </span>
                        <span class="text-h7 black--text" :class="{'font-weight-medium' : ticket.paid == 1 && item.status == 'win'}"> Prêmios: {{ item.award_name }}</span>
                        <span class="text-h7 black--text" :class="{'font-weight-medium' : ticket.paid == 1 && item.status == 'win'}"> Palpites: {{ JSON.parse(item.selected_numbers).join(', ') + ' (' + JSON.parse(item.selected_numbers).length + ')' }}</span>
                        <span class="text-h7 black--text" :class="{'font-weight-medium' : item.status == 'win'}"> Lado: {{ JSON.parse(item.selected_positions).join(', ') }}</span>
                        <span class="text-h7 black--text" :class="{'font-weight-medium' : ticket.paid == 1 && item.status == 'win'}"> Sub-total: {{ item.value }}</span>
                    </div>
                </div>
                <div>
                    <span class="text-h7 black--text">Total: R$ {{ticket.total_value}}</span>
                </div>                    
            </v-card>
        </template>
       
        <template v-if="ticket.contest_id">
            <div style="margin-bottom: 10px" class="pa-5 elevation-1">
                <div style="display: flex; justify-content: space-between;">
                    <span style="font-size: 1rem;" class="black--text"> Concurso:</span>
                    <span style="font-size: 1rem;" class="black--text">{{ticket.contest_name}}</span>
                </div> 

                <div style="display: flex; justify-content: space-between;">
                    <span style="font-size: 1rem;" class="black--text"> Valor a receber:</span>
                    <span style="font-size: 1rem;" class="black--text">{{ ticket.premio }}</span>
                </div>

                <div style="display: flex; justify-content: space-between;">
                    <span style="font-size: 1rem;" class="black--text">{{ticket.paid? 'Bilhete autenticado' : 'Bilhete não autenticado'}}</span>
                </div>
            </div>

            <v-card style="padding: 30px" class="mx-auto elevation-5" color="white darken-2" dark max-width="600">
                <!-- TICKET INFO -->
                <div style="display: flex; flex-direction: column; align-items: center; margin-bottom: 20px; border-bottom: dashed black; border-top: dashed black;">
                    <span class="text-h7 black--text">BANCA ÚNICA</span>
                </div>

                <div style="display: flex; flex-direction: column; border-bottom: dashed black 2px; padding-bottom: 10px; margin-bottom: 10px;">
                    <span class="text-h7 black--text">PONTO: {{ticket.point_name}}</span>
                    <span class="text-h7 black--text">BILHETE: {{ticket.id}}</span>
                    <div style="display: flex: gap: 10px;">
                        <span class="text-h7 black--text">CÓDIGO: {{showCode ? ticket.code : '********'}}</span>
                        <button style="color: grey; height: 30px; width: 30px; border-radius: 50%; font-size: 20px;" @click="toggleCode()">
                            <i v-if="showCode" class="fas fa-eye"></i>
                            <i v-else class="fas fa-eye-slash"></i>
                        </button>
                    </div>
                    <span class="text-h7 black--text">EMISSÃO: {{ formatDateFull(ticket.created_at) }}</span>
                    <span class="text-h7 black--text">Concurso: {{ ticket.contest_name }}</span>
                </div>

                <!-- TICKET GAMES -->
                <div v-if="this.$store.getters.getLogged && this.loggedUser().type != 'cambista'">
                    <div style="display: flex; flex-direction: column; border-bottom: dashed black 2px; padding-bottom: 10px;">
                        <div v-if="ticket.paid == true" style="display: flex; justify-content: space-between; align-items: center; margin-top: 5px;">
                            <i class="fas fa-check-circle" style="color: lime; font-size: 30px"></i>
                        </div>
                        <span class="text-h7 black--text" > Modalidade: {{ ticket.modality }}</span>
                        <span class="text-h7 black--text" > Palpites: {{ JSON.parse(ticket.selected_numbers).join(', ') + ' (' + JSON.parse(ticket.selected_numbers).length + ')' }}</span>
                        <span class="text-h7 black--text" > Sub-total: {{ ticket.total_value }}</span>
                    </div>
                </div>

                <div v-else>
                    <div style="display: flex; flex-direction: column; border-bottom: dashed black 2px; padding-bottom: 10px;">
                        <span class="text-h7 black--text" > Modalidade: {{ ticket.modality }}</span>
                        <span class="text-h7 black--text" > Palpites: {{ JSON.parse(ticket.selected_numbers).join(', ') + ' (' + JSON.parse(ticket.selected_numbers).length + ')' }}</span>
                        <span class="text-h7 black--text" > Sub-total: {{ ticket.total_value }}</span>
                    </div>
                </div>


                <div>
                    <span class="text-h7 black--text">Total: R$ {{ticket.total_value}}</span>
                </div>                    


            </v-card>
        </template>

        <template v-if="ticket.round_id" >
            <div style="margin-bottom: 10px" class="pa-5 elevation-1">
                <div style="display: flex; justify-content: space-between;">
                    <span style="font-size: 1rem;" class="black--text"> Rodada:</span>
                    <span style="font-size: 1rem;" class="black--text">{{ticket.round_number}}</span>
                </div> 

                <div style="display: flex; justify-content: space-between;">
                    <span style="font-size: 1rem;" class="black--text"> Valor a receber:</span>
                    <span style="font-size: 1rem;" class="black--text">{{ ticket.premio }}</span>
                </div>

                <div style="display: flex; justify-content: space-between;">
                    <span style="font-size: 1rem;" class="black--text">{{ticket.paid? 'Bilhete autenticado' : 'Bilhete não autenticado'}}</span>
                </div>
            </div>

            <v-card style="padding: 30px" class="mx-auto elevation-5" color="white darken-2" dark max-width="600">
                <!-- TICKET INFO -->
                <div style="display: flex; flex-direction: column; align-items: center; margin-bottom: 20px; border-bottom: dashed black; border-top: dashed black;">
                    <span class="text-h7 black--text">BANCA ÚNICA</span>
                </div>

                <div style="display: flex; flex-direction: column; border-bottom: dashed black 2px; padding-bottom: 10px; margin-bottom: 10px;">
                    <span class="text-h7 black--text">PONTO: {{ticket.point_name}}</span>
                    <span class="text-h7 black--text">BILHETE: {{ticket.id}}</span>
                    <div style="display: flex: gap: 10px;">
                        <span class="text-h7 black--text">CÓDIGO: {{showCode ? ticket.code : '********'}}</span>
                        <button style="color: grey; height: 30px; width: 30px; border-radius: 50%; font-size: 20px;" @click="toggleCode()">
                            <i v-if="showCode" class="fas fa-eye"></i>
                            <i v-else class="fas fa-eye-slash"></i>
                        </button>
                    </div>
                    <span class="text-h7 black--text">EMISSÃO: {{ formatDateFull(ticket.created_at) }}</span>
                    <span class="text-h7 black--text">Rodada: {{ ticket.round_number }}</span>
                </div>

                <!-- TICKET GAMES -->
                <div v-if="this.$store.getters.getLogged && this.loggedUser().type != 'cambista'">
                    <div style="display: flex; flex-direction: column; border-bottom: dashed black 2px; padding-bottom: 10px;">
                        <div v-if="ticket.paid == true" style="display: flex; justify-content: space-between; align-items: center; margin-top: 5px;">
                            <i class="fas fa-check-circle" style="color: lime; font-size: 30px"></i>
                        </div>
                        <span class="text-h7 black--text" > Palpites: {{ JSON.parse(ticket.selected_numbers).join(', ') + ' (' + JSON.parse(ticket.selected_numbers).length + ')' }}</span>
                        <span class="text-h7 black--text" > Sub-total: {{ ticket.total_value }}</span>
                    </div>
                </div>

                <div v-else>
                    <div style="display: flex; flex-direction: column; border-bottom: dashed black 2px; padding-bottom: 10px;">
                        <span class="text-h7 black--text" > Palpites: {{ JSON.parse(ticket.selected_numbers).join(', ') + ' (' + JSON.parse(ticket.selected_numbers).length + ')' }}</span>
                        <span class="text-h7 black--text" > Sub-total: {{ ticket.total_value }}</span>
                    </div>
                </div>


                <div>
                    <span class="text-h7 black--text">Total: R$ {{ticket.total_value}}</span>
                </div>                    


            </v-card>
        </template>

        <!-- <BaseTicketOnline :ticket="ticket"></BaseTicketOnline> -->
    </v-card>
</v-dialog>
</template>

<script>
export default {
    name: "ModalTicketGame",

    props: {
        ticket_ref: Object,
        show: Boolean
    },

    data: () => ({
        display: false,
        ticket: null,
        showCode: false
    }),


    watch: {
        // WATCH THE VALUE TO DO THE REQUEST AND OPEN THE MODAL
        show: function (val) {
            this.display = val;
            this.loadFullTicket();
        },

        // IF USER CLICKS OUTSIDE THE DIALOG 
        display: function (val) {
            val || this.close();
        }
    },

    methods: {
        // CLOSE MODAL 
        close() {
            this.showCode = false;
            this.display = false;
            this.$emit('close');
        },

        toggleCode() {
            this.showCode = !this.showCode
            if(this.showCode) {
                this.$api
                .post(`/tickets/showcode`, { ticket: this.ticket }, {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                })
            }
        },

        loadFullTicket() {
            if(this.ticket_ref.extraction_id) {
                this.$api
                .get(`/tickets/getTicketById/${this.ticket_ref.id}`, {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                })
                .then((response) => {
                    this.ticket = response.data;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar o bilhete.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);

                    this.table.loading = false;
                });
            } else {
                this.ticket = this.ticket_ref
            }
            
        },

        calcularTotalRecebimento(ticket) {
            let total = 0;

            if(ticket.extraction_id) {
                const wins = ticket.games.filter(game => game.status == 'win');
                total = wins.reduce((total, game) => total + parseFloat(game.expected_value), 0);
            } else {
                total = ticket.expected_value
            }

            return total
        },
    }
}
</script>
