import Vue from "vue";
import Router from "vue-router";
import goTo from "vuetify/es5/services/goto";

Vue.use(Router);

const router = new Router({
	base: process.env.BASE_URL,

	// This is for the scroll top when click on any router link
	scrollBehavior: (to, from, savedPosition) => {
		let scrollTo = 0;

		if (to.hash) {
			scrollTo = to.hash;
		} else if (savedPosition) {
			scrollTo = savedPosition.y;
		}

		return goTo(scrollTo);
	},

	// This is for the scroll top when click on any router link
	routes: [
		// MASTER
		{
			path: "/master",
			name: "master",
			meta: { require_auth: true },
			component: () => import("@/layouts/FullLayout"),
			children: [
				{
					name: "Master Home",
					path: "dashboard",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterDashboard"),
				},
				{
					name: "Master Profile",
					path: "register/profile",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterProfile"),
				},
				{
					name: "Master Logs",
					path: "logs",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterLogs"),
				},
				{
					name: "Master Users Admin",
					path: "users/admins",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterUserAdmin"),
				},
				{
					name: "Master Users SubAdmin",
					path: "users/subAdmins",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterUserSubAdmin"),
				},
				{
					name: "Master Users Manager",
					path: "users/managers",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterUserManager"),
				},
				{
					name: "Master Users Assistant",
					path: "users/assistant",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterUserAssistant"),
				},
				{
					name: "Master Users Operator",
					path: "users/operators",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterUserOperator"),
				},
				{
					name: "Master Users Seller",
					path: "users/sellers",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterUserSeller"),
				},
				{
					name: "Master Analytics Daily",
					path: "analytics/daily",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticDaily"),
				},
				{
					name: "Master Analytics Retreat",
					path: "analytics/retreat",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticRetreat"),
				},
				{
					name: "Master Analytics Location",
					path: "analytics/location",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticLocation"),
				},
				{
					name: "Master Analytics Solicitations",
					path: "analytics/solicitations",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticSolicitations"),
				},
				{
					name: "Master Analytics Extractions",
					path: "analytics/extractions",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticExtraction"),
				},
				{
					name: "Master Analytics Point Extractions",
					path: "analytics/pointExtractions",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticPointExtraction"),
				},
				{
					name: "Master Analytics Tickets",
					path: "analytics/tickets",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterTicket"),
				},
				{
					name: "Master Analytics Tickets Autenticados",
					path: "analytics/tickets-autenticados",
					meta: { require_auth: true },
					component: () =>
						import("@/views/master/MasterTicketAutenticado"),
				},
				{
					name: "Master Analytics Cane Tickets",
					path: "analytics/cane-tickets",
					meta: { require_auth: true },
					component: () =>
						import("@/views/master/MasterAnalyticsCaneTickets"),
				},
				{
					name: "Master Bolao Ball Map",
					path: "bolao/rounds",
					meta: { require_auth: true },
					component: () =>
						import("@/views/master/MasterAnalyticBallMap"),
				},
				{
					name: "Master Bolao Rounds",
					path: "bolao/point-rounds",
					meta: { require_auth: true },
					component: () =>
						import("@/views/master/MasterAnalyticRounds"),
				},
				{
					name: 'Master Bolao Config',
					path: "bolao/rounds_config",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterRoundsConfig"),
				},
				{
					name: 'Master Bolao Income',
					path: "bolao/incomes",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterBolaoIncome"),
				},
				{
					name: "Master Seller's Balance",
					path: "bolao/sellers-balance",
					meta: { require_auth: true },
					component: () =>
						import("@/views/master/MasterAnalyticRoundSeller"),
				},
				{
					name: "Master Analytics Seller's Balance",
					path: "analytics/sellers-balance",
					meta: { require_auth: true },
					component: () =>
						import("@/views/master/MasterAnalyticSeller"),
				},
				{
					name: "Master Analytics Operators's Balance",
					path: "analytics/operators-balance",
					meta: { require_auth: true },
					component: () =>
						import("@/views/master/MasterAnalyticOperator"),
				},
				{
					name: "Master Analytics Session",
					path: "analytics/sessions",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticSession"),
				},
				{
					name: "Master Analytics Region",
					path: "analytics/regions",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticRegion"),
				},
				{
					name: "Master Analytics Modalities",
					path: "analytics/modalities",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticModalities"),
				},
				{
					name: "Master Analytics Discharge",
					path: "discharges/discharges",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticDischarge"),
				},
				{
					name: "Master Analytics DischargeSumary",
					path: "discharges/dischargeSummary/",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticDischargeSummary"),
				},
				{
					name: "Master Analytics DischargeOverall",
					path: "discharges/dischargesOverall/",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticDischargeOverall"),
				},
				{
					name: "Master Manual Discharge",
					path: "discharges/manualDischarge/",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterManualDischarge"),
				},
				{
					name: "Master Analytics Discharge Dates",
					path: "discharges/dischargeDates/",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticDischargeDates"),
				},
				{
					name: "Master Analytics Discharge Extraction Tips",
					path: "discharges/extractionTips/",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticExtractionTips"),
				},
				{
					name: "Master Estatistics Region",
					path: "statistics/regions",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterStatisticsRegions"),
				},
				{
					name: "Master Estatistics Session",
					path: "statistics/sessions",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterStatisticsSessions"),
				},
				{
					name: "Master Estatistics Point",
					path: "statistics/points",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterStatisticsPoints"),
				},
				{
					name: "Master Estatistics Product",
					path: "statistics/products",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterStatisticsProducts"),
				},
				{
					name: "Master Releases Results",
					path: "releases/results",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterResult"),
				},
				{
					name: "Master Releases Incomes",
					path: "releases/incomes",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterIncome"),
				},
				{
					name: "Master Releases Expenses",
					path: "releases/expenses",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterExpense"),
				},
				{
					name: "Master Releases Session Expenses",
					path: "releases/session_expenses",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterSessionExpense"),
				},
				{
					name: "Master Releases Claims",
					path: "releases/claims",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterClaim"),
				},
				{
					name: "Master Messages",
					path: "releases/messages",
					meta: { require_auth: true },
					component: () => import("@/views/master/Message"),
				},
				{
					name: "Master Register Modalities",
					path: "register/modalities",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterModality"),
				},
				{
					name: "Master Register QuotedBets",
					path: "register/quoted_bets",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterQuotedBet"),
				},
				{
					name: "Master Register Awards",
					path: "register/awards",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAward"),
				},
				{
					name: "Master Register Lotteries",
					path: "register/lotteries",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterLottery"),
				},
				{
					name: "Master Register Extractions",
					path: "register/extractions",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterExtraction"),
				},
				{
					name: "Master Register Avaliable Awards",
					path: "register/avaliables",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAvaliableAward"),
				},
				{
					name: "Master Register Config Ticket Footer",
					path: "register/ticket_footer",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterTicketFooter"),
				},
				{
					name: "Master Register Config Extraction Time",
					path: "register/configExtractionTime",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterConfigExtractionTime"),
				},
				{
					name: "Master Register Config bau",
					path: "lottery_config/bau_config",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterJbBauConfig"),
				},
				{
					name: "Master Register Config Ligeirinha",
					path: "lottery_config/ligeirinha_config",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterLigeirinhaConfig"),
				},
				{
					name: "Master Register Config Instantaneo",
					path: "lottery_config/instantaneo_config",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterInstantaneoConfig"),
				},
				{
					name: 'Master Register Config Lt',
					path: "lottery_config/lt_config",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterLtConfig"),
				},
				{
					name: 'Master Register Config Seninha',
					path: "lottery_config/seninha_config",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterSeninhaConfig"),
				},
				{
					name: 'Master Register Config Quininha',
					path: "lottery_config/quininha_config",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterQuininhaConfig"),
				},
				{
					name: 'Master Register Config QuinaBrasil',
					path: "lottery_config/quinabrasil_config",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterQuinaBrasilConfig"),
				},
				{
					name: "Master Register Config Region",
					path: "register/configRegion",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterConfigRegion"),
				},
				{
					name: "Master Validate Ticket",
					path: "register/validateTicket",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterValidateTicket"),
				},
				{
					name: "Master Discharge",
					path: "discharges/launch",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterDischarge"),
				},
				{
					name: "Master Discharge Limit",
					path: "discharges/limits",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterDischargeLimit"),
				},
				{
					name: "Master Discharge Target",
					path: "discharges/targets",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterDischargeTarget"),
				},
				{
					name: "Master Awards",
					path: "awards",
					meta: { require_auth: true },
					component: () =>
						import("@/views/master/MasterAward"),
				},
				{
					name: "Master Sessions",
					path: "locations/sessions",
					meta: { require_auth: true },
					component: () =>
						import("@/views/master/MasterSession"),
				},
				{
					name: "Master Regions",
					path: "locations/regions",
					meta: { require_auth: true },
					component: () =>
						import("@/views/master/MasterRegion"),
				},
				{
					name: "Master Points",
					path: "locations/points",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterPoint"),
				},
				{
					name: "Master Fixed Expenses",
					path: "locations/fixedExpenses",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterFixedExpense"),
				},
				{
					name: "Master Fixed Session Expenses",
					path: "locations/fixedSessionExpenses",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterFixedSessionExpense"),
				},
				{
					name: "Master Expense Categories",
					path: "locations/expenseCategories",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterExpenseCategories"),
				},

			],
		},

		// ADMIN DE REGIÃO 
		{
			path: "/admin_region",
			name: "admin_region",
			meta: { require_auth: true },
			component: () => import("@/layouts/FullLayout"),
			children: [
				{
					name: "Admin Home",
					path: "dashboard",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterDashboard"),
				},
				{
					name: "Admin Profile",
					path: "register/profile",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterProfile"),
				},
				{
					name: "Admin Logs",
					path: "logs",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterLogs"),
				},
				{
					name: "Admin Users SubAdmin",
					path: "users/subAdmins",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterUserSubAdmin"),
				},
				{
					name: "Admin Users Manager",
					path: "users/managers",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterUserManager"),
				},
				{
					name: "Admin Users Assistant",
					path: "users/assistant",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterUserAssistant"),
				},
				{
					name: "Admin Users Operator",
					path: "users/operators",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterUserOperator"),
				},
				{
					name: "Admin Users Seller",
					path: "users/sellers",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterUserSeller"),
				},
				{
					name: "Admin Releases Results",
					path: "releases/results",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterResult"),
				},
				{
					name: "Admin Releases Incomes",
					path: "releases/incomes",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterIncome"),
				},
				{
					name: "Admin Releases Expenses",
					path: "releases/expenses",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterExpense"),
				},
				{
					name: "Admin Releases Session Expenses",
					path: "releases/session_expenses",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterSessionExpense"),
				},
				{
					name: "Admin Releases Claims",
					path: "releases/claims",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterClaim"),
				},
				{
					name: "Admin Bolao Ball Map",
					path: "bolao/rounds",
					meta: { require_auth: true },
					component: () =>
						import("@/views/master/MasterAnalyticBallMap"),
				},
				{
					name: "Admin Bolao Rounds",
					path: "bolao/point-rounds",
					meta: { require_auth: true },
					component: () =>
						import("@/views/master/MasterAnalyticRounds"),
				},
				{
					name: 'Admin Bolao Config',
					path: "bolao/rounds_config",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterRoundsConfig"),
				},
				{
					name: "Admin Seller's Balance",
					path: "bolao/sellers-balance",
					meta: { require_auth: true },
					component: () =>
						import("@/views/master/MasterAnalyticRoundSeller"),
				},
				{
					name: 'Admin Bolao Income',
					path: "bolao/incomes",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterBolaoIncome"),
				},
				{
					name: "Admin Messages",
					path: "releases/messages",
					meta: { require_auth: true },
					component: () => import("@/views/master/Message"),
				},
				{
					name: "Admin Analytics Daily",
					path: "analytics/daily",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticDaily"),
				},
				{
					name: "Admin Analytics Retreat",
					path: "analytics/retreat",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticRetreat"),
				},
				{
					name: "Admin Analytics Modalities",
					path: "analytics/modalities",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticModalities"),
				},
				{
					name: "Admin Analytics Extractions",
					path: "analytics/extractions",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticExtraction"),
				},
				{
					name: "Admin Analytics Point Extractions",
					path: "analytics/pointExtractions",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticPointExtraction"),
				},
				{
					name: "Admin Analytics Location",
					path: "analytics/location",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticLocation"),
				},
				{
					name: "Admin Analytics Tickets",
					path: "analytics/tickets",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterTicket"),
				},
				{
					name: "Admin Analytics Tickets Autenticados",
					path: "analytics/tickets-autenticados",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterTicketAutenticado"),
				},
				{
					name: "Admin Analytics Cane Tickets",
					path: "analytics/cane-tickets",
					meta: { require_auth: true },
					component: () =>
						import("@/views/master/MasterAnalyticsCaneTickets"),
				},
				{
					name: "Admin Analytics Seller's Balance",
					path: "analytics/sellers-balance",
					meta: { require_auth: true },
					component: () =>
						import("@/views/master/MasterAnalyticSeller"),
				},
				{
					name: "Admin Analytics Operators's Balance",
					path: "analytics/operators-balance",
					meta: { require_auth: true },
					component: () =>
						import("@/views/master/MasterAnalyticOperator"),
				},
				{
					name: "Admin Analytics Session",
					path: "analytics/sessions",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticSession"),
				},
				{
					name: "Admin Analytics Region",
					path: "analytics/regions",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticRegion"),
				},
				{
					name: "Admin Analytics Discharge",
					path: "discharges/discharges",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticDischarge"),
				},
				{
					name: "Admin Analytics DischargeSumary",
					path: "discharges/dischargeSummary/",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticDischargeSummary"),
				},
				{
					name: "Admin Analytics DischargeOverall",
					path: "discharges/dischargesOverall/",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticDischargeOverall"),
				},
				{
					name: "Admin Manual Discharge",
					path: "discharges/manualDischarge/",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterManualDischarge"),
				},
				{
					name: "Admin Analytics Discharge Dates",
					path: "discharges/dischargeDates/",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticDischargeDates"),
				},
				{
					name: "Admin Analytics Discharge Extraction Tips",
					path: "discharges/extractionTips/",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticExtractionTips"),
				},
				{
					name: "Admin Estatistics Region",
					path: "statistics/regions",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterStatisticsRegions"),
				},
				{
					name: "Admin Estatistics Session",
					path: "statistics/sessions",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterStatisticsSessions"),
				},
				{
					name: "Admin Estatistics Point",
					path: "statistics/points",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterStatisticsPoints"),
				},
				{
					name: "Admin Estatistics Product",
					path: "statistics/products",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterStatisticsProducts"),
				},
				{
					name: "Admin Register Extractions",
					path: "register/extractions",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterExtraction"),
				},
				{
					name: "Admin Register QuotedBets",
					path: "register/quoted_bets",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterQuotedBet"),
				},
				{
					name: "Admin Register Config Ticket Footer",
					path: "register/ticket_footer",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterTicketFooter"),
				},
				{
					name: "Admin Register Config Extraction Time",
					path: "register/configExtractionTime",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterConfigExtractionTime"),
				},
				{
					name: "Admin Register Config bau",
					path: "lottery_config/bau_config",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterJbBauConfig"),
				},
				{
					name: "Admin Register Config Bau",
					path: "lottery_config/bau",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterJbBauConfig"),
				},
				{
					name: "Admin Register Config Ligeirinha",
					path: "lottery_config/ligeirinha_config",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterLigeirinhaConfig"),
				},
				{
					name: "Admin Register Config Instantaneo",
					path: "lottery_config/instantaneo_config",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterInstantaneoConfig"),
				},
				{
					name: 'Admin Register Config Lt',
					path: "lottery_config/lt_config",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterLtConfig"),
				},
				{
					name: 'Admin Register Config Seninha',
					path: "lottery_config/seninha_config",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterSeninhaConfig"),
				},
				{
					name: 'Admin Register Config Quininha',
					path: "lottery_config/quininha_config",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterQuininhaConfig"),
				},
				{
					name: 'Master Register Config QuinaBrasil',
					path: "lottery_config/quinabrasil_config",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterQuinaBrasilConfig"),
				},
				{
					name: "Admin Discharge",
					path: "discharges/launch",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterDischarge"),
				},
				{
					name: "Admin Discharge Limit",
					path: "discharges/limits",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterDischargeLimit"),
				},
				{
					name: "Admin Regions",
					path: "locations/regions",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterRegion"),
				},
				{
					name: "Admin Sessions",
					path: "locations/sessions",
					meta: { require_auth: true },
					component: () =>
						import("@/views/master/MasterSession"),
				},
				{
					name: "Admin Points",
					path: "locations/points",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterPoint"),
				},
				{
					name: "Admin Fixed Expenses",
					path: "locations/fixedExpenses",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterFixedExpense"),
				},
				{
					name: "Admin Fixed Session Expenses",
					path: "locations/fixedSessionExpenses",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterFixedSessionExpense"),
				},
				{

					name: "Admin Expense Categories",
					path: "locations/expenseCategories",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterExpenseCategories"),
				}
			],
		},

		// SUBADMIN
		{
			path: "/sub_admin",
			name: "sub_admin",
			meta: { require_auth: true },
			component: () => import("@/layouts/FullLayout"),
			children: [
				{
					name: "SubAdmin Home",
					path: "dashboard",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterDashboard"),
				},
				{
					name: "Subadmin Profile",
					path: "profile",
					meta: { require_auth: true },
					component: () => import("@/views/master/SubAdminProfile"),
				},
				{
					name: "Subadmin Profile",
					path: "register/profile",
					meta: { require_auth: true },
					component: () => import("@/views/master/SubAdminProfile"),
				},
				{
					name: "SubAdmin Logs",
					path: "logs",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterLogs"),
				},
				{
					name: "SubAdmin Users Manager",
					path: "users/managers",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterUserManager"),
				},
				{
					name: "SubAdmin Users Operator",
					path: "users/operators",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterUserOperator"),
				},
				{
					name: "SubAdmin Users Seller",
					path: "users/sellers",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterUserSeller"),
				},
				{
					name: "SubAdmin Releases Results",
					path: "releases/results",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterResult"),
				},
				{
					name: "SubAdmin Releases Incomes",
					path: "releases/incomes",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterIncome"),
				},
				{
					name: "SubAdmin Bolao Ball Map",
					path: "bolao/rounds",
					meta: { require_auth: true },
					component: () =>
						import("@/views/master/MasterAnalyticBallMap"),
				},
				{
					name: "SubAdmin Bolao Rounds",
					path: "bolao/point-rounds",
					meta: { require_auth: true },
					component: () =>
						import("@/views/master/MasterAnalyticRounds"),
				},
				{
					name: 'SubAdmin Bolao Config',
					path: "bolao/rounds_config",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterRoundsConfig"),
				},
				{
					name: "SubAdmin Seller's Balance",
					path: "bolao/sellers-balance",
					meta: { require_auth: true },
					component: () =>
						import("@/views/master/MasterAnalyticRoundSeller"),
				},
				{
					name: 'SubAdmin Bolao Income',
					path: "bolao/incomes",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterBolaoIncome"),
				},
				{
					name: "SubAdmin Messages",
					path: "releases/messages",
					meta: { require_auth: true },
					component: () => import("@/views/master/Message"),
				},
				{
					name: "SubAdmin Releases Expenses",
					path: "releases/expenses",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterExpense"),
				},
				{
					name: "SubAdmin Releases Session Expenses",
					path: "releases/session_expenses",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterSessionExpense"),
				},
				{
					name: "SubAdmin Releases Claims",
					path: "releases/claims",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterClaim"),
				},
				{
					name: "SubAdmin Analytics Daily",
					path: "analytics/daily",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticDaily"),
				},
				{
					name: "SubAdmin Analytics Retreat",
					path: "analytics/retreat",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticRetreat"),
				},
				{
					name: "SubAdmin Analytics Modalities",
					path: "analytics/modalities",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticModalities"),
				},
				{
					name: "SubAdmin Analytics Extractions",
					path: "analytics/extractions",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticExtraction"),
				},
				{
					name: "SubAdmin Analytics Point Extractions",
					path: "analytics/pointExtractions",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticPointExtraction"),
				},
				{
					name: "SubAdmin Analytics Tickets",
					path: "analytics/tickets",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterTicket"),
				},
				{
					name: "SubAdmin Analytics Tickets Autenticados",
					path: "analytics/tickets-autenticados",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterTicketAutenticado"),
				},
				{
					name: "SubAdmin Analytics Cane Tickets",
					path: "analytics/cane-tickets",
					meta: { require_auth: true },
					component: () =>
						import("@/views/master/MasterAnalyticsCaneTickets"),
				},
				{
					name: "SubAdmin Analytics Location",
					path: "analytics/location",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticLocation"),
				},
				{
					name: "SubAdmin Analytics Seller's Balance",
					path: "analytics/sellers-balance",
					meta: { require_auth: true },
					component: () =>
						import("@/views/master/MasterAnalyticSeller"),
				},
				{
					name: "SubAdmin Analytics Session",
					path: "analytics/sessions",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticSession"),
				},
				{
					name: "SubAdmin Analytics Region",
					path: "analytics/regions",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticRegion"),
				},
				{
					name: "Subadmin Analytics Discharge",
					path: "discharges/discharges",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticDischarge"),
				},
				{
					name: "Admin Manual Discharge",
					path: "discharges/manualDischarge/",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterManualDischarge"),
				},
				{
					name: "SubAdmin Analytics DischargeSumary",
					path: "discharges/dischargeSummary/",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticDischargeSummary"),
				},
				{
					name: "SubAdmin Analytics DischargeOverall",
					path: "discharges/dischargesOverall/",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticDischargeOverall"),
				},
				{
					name: "SubAdmin Analytics Discharge Dates",
					path: "discharges/dischargeDates/",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticDischargeDates"),
				},
				{
					name: "SubAdmin Analytics Discharge Extraction Tips",
					path: "discharges/extractionTips/",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticExtractionTips"),
				},
				{
					name: "SubAdmin Estatistics Region",
					path: "statistics/regions",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterStatisticsRegions"),
				},
				{
					name: "SubAdmin Estatistics Session",
					path: "statistics/sessions",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterStatisticsSessions"),
				},
				{
					name: "SubAdmin Estatistics Point",
					path: "statistics/points",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterStatisticsPoints"),
				},
				{
					name: "SubAdmin Estatistics Product",
					path: "statistics/products",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterStatisticsProducts"),
				},
				{
					name: "SubAdmin Register Extractions",
					path: "register/extractions",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterExtraction"),
				},
				{
					name: "SubAdmin Register QuotedBets",
					path: "register/quoted_bets",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterQuotedBet"),
				},
				{
					name: "SubAdmin Register Config Ticket Footer",
					path: "register/ticket_footer",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterTicketFooter"),
				},
				{
					name: "SubAdmin Register Config Extraction Time",
					path: "register/configExtractionTime",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterConfigExtractionTime"),
				},
				{
					name: "SubAdmin Register Config bau",
					path: "lottery_config/bau_config",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterJbBauConfig"),
				},
				{
					name: "SubAdmin Register Config Bau",
					path: "lottery_config/bau",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterJbBauConfig"),
				},
				{
					name: "SubAdmin Register Config Ligeirinha",
					path: "lottery_config/ligeirinha_config",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterLigeirinhaConfig"),
				},
				{
					name: "SubAdmin Register Config Instantaneo",
					path: "lottery_config/instantaneo_config",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterInstantaneoConfig"),
				},
				{
					name: 'SubAdmin Register Config Lt',
					path: "lottery_config/lt_config",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterLtConfig"),
				},
				{
					name: 'SubAdmin Register Config Seninha',
					path: "lottery_config/seninha_config",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterSeninhaConfig"),
				},
				{
					name: 'SubAdmin Register Config Quininha',
					path: "lottery_config/quininha_config",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterQuininhaConfig"),
				},
				{
					name: 'SubAdmin Register Config QuinaBrasil',
					path: "lottery_config/quinabrasil_config",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterQuinaBrasilConfig"),
				},
				{
					name: "SubAdmin Discharge",
					path: "discharges/launch",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterDischarge"),
				},
				{
					name: "SubAdmin Discharge Limit",
					path: "discharges/limits",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterDischargeLimit"),
				},
				{
					name: "SubAdmin Regions",
					path: "locations/regions",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterRegion"),
				},
				{
					name: "SubAdmin Sessions",
					path: "locations/sessions",
					meta: { require_auth: true },
					component: () =>
						import("@/views/master/MasterSession"),
				},
				{
					name: "SubAdmin Points",
					path: "locations/points",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterPoint"),
				},
				{
					name: "SubAdmin Fixed Expenses",
					path: "locations/fixedExpenses",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterFixedExpense"),
				},
				{
					name: "SubAdmin Fixed Session Expenses",
					path: "locations/fixedSessionExpenses",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterFixedSessionExpense"),
				},
				{

					name: "SubAdmin Expense Categories",
					path: "locations/expenseCategories",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterExpenseCategories"),
				}
			],
		},

		// AUXILIAR
		{
			path: "/auxiliar",
			name: "auxiliar",
			meta: { require_auth: true },
			component: () => import("@/layouts/FullLayout"),
			children: [
				{
					name: "Auxiliar Profile",
					path: "profile",
					meta: { require_auth: true },
					component: () => import("@/views/master/AssistantProfile"),
				},
				{
					name: "Auxiliar Users Seller",
					path: "users/sellers",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterUserSeller"),
				},
				{
					name: "Auxiliar Analytics Discharge",
					path: "discharges/discharges",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticDischarge"),
				},
				{
					name: "Auxiliar Discharge",
					path: "discharges/launch",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterDischarge"),
				},
				{
					name: "Auxiliar Points",
					path: "locations/points",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterPoint"),
				},
				{
					name: "Auxiliar Analytics DischargeSumary",
					path: "discharges/dischargeSummary/",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticDischargeSummary"),
				},
				{
					name: "Auxiliar Analytics DischargeOverall",
					path: "discharges/dischargesOverall/",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticDischargeOverall"),
				},
				{
					name: "Auxiliar Manual Discharge",
					path: "discharges/manualDischarge/",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterManualDischarge"),
				},
				{
					name: "Auxiliar Analytics Discharge Dates",
					path: "discharges/dischargeDates/",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticDischargeDates"),
				},
				{
					name: "Auxiliar Analytics Discharge Extraction Tips",
					path: "discharges/extractionTips/",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticExtractionTips"),
				},
				{
					name: "Auxiliar Register Profile",
					path: "register/profile",
					meta: { require_auth: true },
					component: () => import("@/views/master/AssistantProfile"),
				},
				{
					name: "Auxiliar Register QuotedBets",
					path: "register/quoted_bets",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterQuotedBet"),
				},
				{
					name: "Auxiliar Releases Results",
					path: "releases/results",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterResult"),
				},
			]
		},

		// GERENTE
		{
			path: "/manager",
			name: "manager",
			meta: { require_auth: true },
			component: () => import("@/layouts/FullLayout"),
			children: [
				{
					name: "Manager Profile",
					path: "profile",
					meta: { require_auth: true },
					component: () => import("@/views/master/ManagerProfile"),
				},
				{
					name: "Manager Home",
					path: "dashboard",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterDashboard"),
				},
				{
					name: "Manager Profile",
					path: "register/profile",
					meta: { require_auth: true },
					component: () => import("@/views/master/ManagerProfile"),
				},
				{
					name: "Manager Users Operator",
					path: "users/operators",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterUserOperator"),
				},
				{
					name: "Manager Users Seller",
					path: "users/sellers",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterUserSeller"),
				},
				{
					name: "Manager Analytics Daily",
					path: "analytics/daily",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticDaily"),
				},
				{
					name: "Manager Analytics Retreat",
					path: "analytics/retreat",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticRetreat"),
				},
				{
					name: "Manager Analytics Extractions",
					path: "analytics/extractions",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticExtraction"),
				},
				{
					name: "Manager Analytics Point Extractions",
					path: "analytics/pointExtractions",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticPointExtraction"),
				},
				{
					name: "Manager Analytics Tickets",
					path: "analytics/tickets",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterTicket"),
				},
				{
					name: "Manager Analytics Tickets Autenticados",
					path: "analytics/tickets-autenticados",
					meta: { require_auth: true },
					component: () =>
						import("@/views/master/MasterTicketAutenticado"),
				},
				{
					name: "Manager Analytics Seller's Balance",
					path: "analytics/sellers-balance",
					meta: { require_auth: true },
					component: () =>
						import("@/views/master/MasterAnalyticSeller"),
				},
				{
					name: "Manager Analytics Session",
					path: "analytics/sessions",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterAnalyticSession"),
				},
				{
					name: "Manager Releases Results",
					path: "releases/results",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterResult"),
				},
				{
					name: "Manager Releases Incomes",
					path: "releases/incomes",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterIncome"),
				},
				{
					name: "Manager Releases Expenses",
					path: "releases/expenses",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterExpense"),
				},
				{
					name: "Manager Releases Session Expenses",
					path: "releases/session_expenses",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterSessionExpense"),
				},
				{
					name: "Manager Sessions",
					path: "locations/sessions",
					meta: { require_auth: true },
					component: () =>
						import("@/views/master/MasterSession"),
				},
				{
					name: "Manager Points",
					path: "locations/points",
					meta: { require_auth: true },
					component: () => import("@/views/master/MasterPoint"),
				},
			],
		},
		// TICKET VIEW
		{
			name: "Ticket View",
			path: "/tickets/:id",
			meta: { guest: true },
			component: () => import("@/views/TicketView"),
		},

		{
			name: "Ticket View",
			path: "/tickets/:id/:type",
			meta: { guest: true },
			component: () => import("@/views/TicketView"),
		},

		// TICKET VIEW
		/* {
			name: "Ticket View",
			path: "/tickets/:id/:type",
			meta: { guest: true },
			component: () => import("@/views/TicketView"),
		}, */

		// LOGIN
		{
			path: "/login",
			name: "Login",
			component: () => import("@/views/authentication/Login"),
		},

		// ERROR
		{
			path: "*",
			component: () => import("@/views/authentication/Error"),
		},
	],
});


// NAVIGATION GUARDS
import mixin from './mixins/mixin';
import store from "./store/store";

router.beforeEach((to, from, next) => {

	// WHEN THE ROUTER DON'T NEED AUTHENTICATION
	if (to.matched.some(record => record.meta.guest)) {
		next();
		return;
	}

	// WHEN THE ROUTER NEEDS AUTHENTICATION
	if (to.matched.some(record => record.meta.require_auth)) {
		// CHECK IF THE USER IS LOGGED AND HAS A VALID TOKEN
		if (mixin.methods.verifyExpirate() || store.getters.getLogged) {
			next();
		} else {
			next({ name: "Login" });
		}
	}

	// WHEN USER TRIES TO ENTER A DIFFERENT URL
	if ((to.name == undefined && from.name == null) && to.name != "Login") {
		next({ name: 'Login' })
		return
	}

	next();

});

export default router;
