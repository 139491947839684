<template>
    <div>
        <v-dialog v-model="display" max-width="650px">
            <v-simple-table>
                <template>
                    <thead>
                        <tr>
                            <th>Revendedores</th>
                            <th>Vendas</th>
                            <th>Prêmios</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="seller in sellers" :key="seller.id">
                            <td>{{ seller.nome }}</td>
                            <td>{{ seller.vendas }}</td>
                            <td>{{ seller.premios }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-dialog>
    </div>
</template>

<script>

export default {
    name: "ModalSellersDashboard",

    props: {
        point_id: Number,
        show: Boolean,
        date_search: Array
    },

    data: () => ({

        display: false,
        sellers: [],

    }),

    created() {
    },

    methods: {

        close() {
            this.display = false;
            this.$emit('close');
            this.sellers = [];
        },

    },

    watch: {

        point_id: function (point_id) {
            if(point_id) {

                console.log(point_id)

                this.$api
                    .get(`/reports/sellerPeriodReport`, {
                        headers: {
                            Authorization: `Bearer ${this.accessToken()}`
                        },
                        params: {
                            point_search: this.point_id,
                            from: this.date_search[0],
                            to: this.date_search[1],
                            dashboard: 1
                        }
                    })
                    .then(response => {
                        this.sellers = response.data;
                    })
                    .catch(() => {
                        
                    });
            }
        },

        // WATCH THE VALUE TO DO THE REQUEST AND OPEN THE MODAL
        show: function (val) {
            this.display = val;
        },

        // IF USER CLICKS OUTSIDE THE DIALOG 
        display: function (val) {
            val || this.close();
        },
    },
    
}

</script>