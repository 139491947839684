<template>
<v-dialog v-model="show" max-width="800px">
    <v-card>
        <!-- TEXT CHANGE IF IS NEW OR EDIT -->
        <v-card-text v-if="register == true" class="pa-5 border-bottom">
            <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Cadastro de nova extração.</h3>
            <h6 class="subtitle-2 font-weight-light">Preencha todas os campos abaixo e confirme o cadastro de uma nova extração no sistema.</h6>
        </v-card-text>

        <v-card-text v-else class="pa-5 border-bottom">
            <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Editar a extração: <b v-text="extraction.name"></b>.</h3>
            <h6 class="subtitle-2 font-weight-light">Preencha todas os campos abaixo e confirme a edição do cadastro da extração no sistema.</h6>
        </v-card-text>

        <v-card-text class="pa-5 border-bottom">
            <v-form v-model="valid" lazy-validation ref="formExtraction">
                <v-row>
                    <v-col cols="12" class="py-0 my-0">
                        <v-text-field dense outlined clearable :rules="rules" v-model="extraction.name" label="Nome da extração"></v-text-field>
                    </v-col>

                    <v-col cols="12" class="py-0 my-0">
                        <v-menu :close-on-content-click="true" transition="scale-transition" min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field dense v-on="on" type="date" reandoly v-bind="attrs" outlined :rules="rules" v-model="ex.date" label="Data da extração"></v-text-field>
                            </template>
                            <v-date-picker v-model="ex.date" :min="new Date().toJSON()" color="primary" locale="pt-br"></v-date-picker>
                        </v-menu>
                    </v-col>

                    <v-col cols="12" class="py-0 my-0">
                        <v-menu :close-on-content-click="false" transition="scale-transition" min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field dense type="time" v-on="on" v-bind="attrs" outlined :rules="rules" v-model="ex.time" label="Hora da extração"></v-text-field>
                            </template>
                            <v-time-picker format="24hr" :min="new Date().toLocaleTimeString()" v-model="ex.time" color="primary" locale="pt-br"></v-time-picker>
                        </v-menu>
                    </v-col>

                    <v-col cols="12" class="py-0 my-0">
                        <v-switch class="mt-0" :true-value="1" :false-value="0" v-model="extraction.visibled" label="Extração ficará visível?"></v-switch>
                    </v-col>

                    <v-col cols="12" class="py-0 my-0">
                        <v-checkbox required v-model="checkbox" :rules="[value => !!value || 'É necessário confirmar.']" label="Confirmar extração?" class="ma-0" color="green"></v-checkbox>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>

        <!-- ACTIONS CHANGE IF IS NEW OR EDIT -->
        <v-card-actions v-if="register == true">
            <v-btn color="red darken-1" text @click="close">CANCELAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" :disabled="!valid" text @click="extractionSave">SALVAR NOVA EXTRAÇÃO</v-btn>
        </v-card-actions>

        <v-card-actions v-else>
            <v-btn color="red darken-1" text @click="close">CANCELAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" :disabled="!valid" text @click="extractionEdit">EDITAR EXTRAÇÃO</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
import {
    format
} from "date-fns";

export default {
    name: "ModalExtractionNewEdit",

    props: {
        extraction: Object,
        edit: Boolean,
        register: Boolean,
        dialog: Boolean,
    },

    data: () => ({
        // SNACKBAR
        snackbar: {},

        // DIALOG 
        valid: false,
        checkbox: false,
        show: false,
        ex: {
            date: null,
            time: null
        },
        rules: [
            value => !!value || 'Campo obrigatório.'
        ],
    }),

    watch: {
        // WATCH THE VALUE TO OPEN THE MODAL
        dialog: function (val) {
            this.show = val;
        },

        // IF USER CLICKS OUTSIDE THE DIALOG 
        show: function (val) {
            val || this.close();
        },

        // WATCH IF USER CHOOSES EDIT
        edit: function (val) {
            val == true ? (this.ex.date = format(new Date(this.extraction.datetime), "yyyy-MM-dd"), this.ex.time = this.formatTime(this.extraction.datetime)) : this.ex = {};
        }
    },

    methods: {
        // CLOSE MODAL   
        close() {
            this.$emit('close');
            this.$refs.formExtraction.reset();
        },

        // RELOAD PARENT
        reload() {
            this.close();
            this.$emit('reload');
        },

        // EDIT EXTRACTION 
        extractionEdit() {
            if (this.$refs.formExtraction.validate()) {

                this.extraction.datetime = `${this.ex.date} ${this.ex.time}`;

                this.$api
                    .put(`/extraction/${this.extraction.id}`, this.extraction, {
                        headers: {
                            Authorization: `Bearer ${this.accessToken()}`
                        }
                    })
                    .then(() => {
                        this.snackbar.text = "Extração atualizada com sucesso!";
                        this.snackbar.type = "green";
                        this.snackbarShow(this.snackbar);

                        this.reload();
                    })
                    .catch(() => {
                        this.snackbar.text = "Erro ao atualizar extração!";
                        this.snackbar.type = "error";
                        this.snackbarShow(this.snackbar);
                    });
            }
        },

        // NEW EXTRACTION 
        extractionSave() {
            if (this.$refs.formExtraction.validate()) {
                this.extraction.launched = 0;
                this.extraction.datetime = `${this.ex.date} ${this.ex.time}`;

                this.$api
                    .post("/extraction", this.extraction, {
                        headers: {
                            Authorization: `Bearer ${this.accessToken()}`
                        }
                    })
                    .then(() => {
                        this.snackbar.text = "Extração cadastrada com sucesso!";
                        this.snackbar.type = "green";
                        this.snackbarShow(this.snackbar);

                        this.reload();
                    })
                    .catch(() => {
                        this.snackbar.text = "Erro ao cadastrar extração!";
                        this.snackbar.type = "error";
                        this.snackbarShow(this.snackbar);
                    });
            }
        },
    }
}
</script>
