var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"800px "},model:{value:(_vm.display),callback:function ($$v) {_vm.display=$$v},expression:"display"}},[_c('v-card',[_c('v-card-text',{staticClass:"pa-5 border-bottom"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading.region_lotteries,"no-data-text":"Não há loterias.","no-results-text":"Sem resultados.","hide-default-footer":"","loading-text":"Carregando...","disable-sort":"","headers":_vm.headers,"items":_vm.region_lotteries},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"pa-1"},[_c('v-form',{ref:"formRegionLotteries",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"ma-0 pa-0 justify-space-between"},[_c('v-col',{staticClass:"pa-1",attrs:{"cols":"12","lg":"10","md":"10","sm":"10"}},[_c('v-select',{attrs:{"hide-details":"","items":_vm.lotteries,"item-text":"name","no-data-text":"Sem dados disponível.","item-value":"id","label":"Nova Loteria","loading":_vm.loading.lotteries,"rules":[function (value) { return !!value || 'Selecione o administrador.'; }],"outlined":"","dense":"","clearable":""},model:{value:(_vm.new_lott_id),callback:function ($$v) {_vm.new_lott_id=$$v},expression:"new_lott_id"}})],1),_c('v-col',{staticClass:"pa-1 text-right",attrs:{"cols":"12","lg":"2","md":"2","sm":"2"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"height":"40px","disabled":!_vm.valid,"block":"","color":"primary"},on:{"click":_vm.regionNewLott}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_c('div',[_vm._v("NOVA LOTERIA")])])])],1)],1)],1)],1),_c('v-divider')]},proxy:true},{key:"item.remove",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"red","icon":""},on:{"click":function($event){return _vm.regionLotteriesRemove(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,true)},[_c('span',[_c('div',[_vm._v("REMOVER LOTERIA")])])])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.created_at).toLocaleDateString()))])]}}],null,true)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.close}},[_vm._v("FECHAR")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }