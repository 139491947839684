<template>
<v-dialog v-model="show" max-width="800px">
    <v-card>
        <v-card-text class="pa-5 border-bottom">
            <h3 class="title blue-grey--text text--darken-2 font-weight-regular">
                Loterias da banca:
                <b v-text="bank.name"></b>.
            </h3>

            <!-- CHANGE THE SUBTITLE IF USER IS DIFFERENT -->
            <h6 v-if="loggedUser().type == 'master'" class="subtitle-2 font-weight-light">Gerencie as loterias da banca, adicionando novas no campo de "Nova Loteria" ou removendo existentes no campo de "Remover".</h6>
            <h6 v-else class="subtitle-2 font-weight-light">Visualize mais detalhes sobre as loterias da banca <b v-text="bank.name"></b>.</h6>
        </v-card-text>

        <v-card-text class="pa-5 border-bottom">
            <!-- DATA-TABLE LOTTERIES -->
            <v-data-table :loading="loading" no-data-text="Não há loterias." no-results-text="Sem resultados." :items-per-page="lotteries != undefined ? lotteries.length : 12" hide-default-footer loading-text="Carregando..." disable-sort class="elevation-1" :headers="headers" :items="lotteries">
                <!-- DATA-TABLE HEADER ONLY FOR MASTER USER -->
                <template v-slot:top v-if="loggedUser().type == 'master'">
                    <div class="pa-1">
                        <v-form v-model="valid" lazy-validation ref="formNewLott">
                            <v-row class="ma-0 pa-0 justify-space-between">
                                <!-- SELECT NEW LOTTERY -->
                                <v-col cols="12" lg="10" md="10" sm="10" class="pa-1">
                                    <v-select hide-details :items="lotts" v-model="new_lott_id" item-text="name" no-data-text="Sem dados disponível." item-value="id" label="Nova Loteria" :loading="loading_lott" :rules="[value => !!value || 'Selecione o administrador.']" outlined dense clearable></v-select>
                                </v-col>

                                <!-- ADD NEW LOTTERY -->
                                <v-col cols="12" lg="2" md="2" sm="2" class="pa-1 text-right">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn height="40px" :disabled="!valid" block v-bind="attrs" v-on="on" color="primary" @click="bankNewLott">
                                                <v-icon>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>
                                            <div>NOVA LOTERIA</div>
                                        </span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </v-form>
                    </div>
                    <v-divider></v-divider>
                </template>

                <!-- ICON REMOVE -->
                <template v-slot:item.remove="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn @click="bankRemoveLott(item)" v-bind="attrs" v-on="on" color="red" icon>
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>
                            <div>REMOVER LOTERIA</div>
                        </span>
                    </v-tooltip>
                </template>

                <!-- DATE FORMAT -->
                <template v-slot:item.created_at="{ item }">
                    <span>{{ new Date(item.created_at).toLocaleDateString() }}</span>
                </template>
            </v-data-table>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="close">FECHAR</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
export default {
    name: "ModalBankLottery",

    props: {
        bank: Object,
        lotteries: Array,
        dialog: Boolean,
    },

    data: () => ({
        // SNACKBAR
        snackbar: {},

        // DATA TABLE 
        headers: [{
                text: "DATA DE ATRIBUIÇÃO",
                value: "created_at",
                class: "primary white--text"
            },
            {
                text: "NOME",
                value: "name",
                class: "primary white--text"
            },
            {
                text: "REFERÊNCIA",
                value: "display_name",
                class: "primary white--text"
            },
            {
                text: "REMOVER",
                value: "remove",
                sortable: false,
                align: "center",
                class: "primary white--text"
            }
        ],
        loading: false,
        lotts: [],

        // DIALOG 
        new_lott_id: "",
        loading_lott: false,
        valid: false,
        show: false,
    }),

    created() {
        if (this.loggedUser().type !== 'master') {
            this.headers = [{
                    text: "DATA DE ATRIBUIÇÃO",
                    value: "created_at",
                    class: "primary white--text"
                },
                {
                    text: "NOME",
                    value: "name",
                    class: "primary white--text"
                },
                {
                    text: "REFERÊNCIA",
                    value: "display_name",
                    class: "primary white--text"
                },
            ]

            return;
        }

        this.loadLotts();
    },

    watch: {
        // WATCH THE VALUE TO OPEN THE MODAL
        dialog: function (val) {
            this.show = val;
        },

        // IF USER CLICKS OUTSIDE THE DIALOG 
        show: function (val) {
            val || this.close();
        }
    },

    methods: {
        // CLOSE MODAL   
        close() {
            this.$emit('close');

            if (this.loggedUser().type == 'master') {
                this.$refs.formNewLott.reset();
            }
        },

        // RELOAD PARENT
        reload() {
            this.$emit('reload');
        },

        // LOAD LOTTERIES
        loadLotts() {
            this.loading_lott = true;

            this.$api
                .get("/lotteries", {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    }
                })
                .then(response => {
                    this.lotts = response.data;
                    this.loading_lott = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar as loterias.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);

                    this.loading_lott = false;
                });
        },

        // NEW BANK LOTTERY
        bankNewLott() {
            if (this.$refs.formNewLott.validate()) {
                this.loading = true;

                this.$api
                    .get(
                        `bank/${this.bank.id}/lotteries/${this.new_lott_id}`, {
                            headers: {
                                Authorization: `Bearer ${this.accessToken()}`
                            }
                        }
                    )
                    .then(() => {
                        this.snackbar.text = "Loteria adicionada com sucesso!";
                        this.snackbar.type = "green";
                        this.snackbarShow(this.snackbar);

                        this.loading = false;
                        this.close();
                        this.reload();
                    })
                    .catch(() => {
                        this.snackbar.text = "Erro ao adicionar a loteria!";
                        this.snackbar.type = "error";
                        this.snackbarShow(this.snackbar);

                        this.loading = false;
                    });
            }
        },

        // REMOVE BANK LOTTERY
        bankRemoveLott(item) {
            this.loading = true;

            this.$api
                .delete(`bank/${this.bank.id}/lotteries/${item.id}`, {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    }
                })
                .then(() => {
                    this.snackbar.text = "Loteria removida com sucesso!";
                    this.snackbar.type = "green";
                    this.snackbarShow(this.snackbar);

                    this.loading = false;
                    this.close();
                    this.reload();
                })
                .catch(() => {
                    this.snackbar.text = "Erro ao remover loteria!";
                    this.snackbar.type = "error";
                    this.snackbarShow(this.snackbar);

                    this.loading = false;
                });
        },
    }
}
</script>
