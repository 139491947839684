<template>
<v-dialog v-model="show" max-width="800px">
    <v-card>

        <!-- TEXT CHANGE IF IS NEW OR EDIT -->
         <v-card-text class="pa-5 border-bottom">
            <h3 class="title blue-grey--text text--darken-2 font-weight-regular"> Confirmação de descarga </h3>
        </v-card-text>

        <v-card-text class="pa-5 border-bottom">

            <v-form v-model="valid" lazy-validation ref="formDischarge">
                <v-row>
                    <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="py-0 my-0">
                        <v-autocomplete dense clearable :rules="[value => !!value || 'Informe a região de descarga']" no-data-text="Sem dados disponível." :loading="loading.regions" :disabled="loading.regions" v-model="region_id" label="Região de descarga" color="primary" item-text="receiving_region_name" item-value="receiving_region" outlined :items="regions"/>
                    </v-col>
                </v-row>
            </v-form>

            <v-btn block color="blue darken-1" text @click="roundExcess()">
                <span>Arredondar</span>
                <v-icon>mdi-refresh</v-icon>
            </v-btn>

          <!--   <v-data-table :mobile-breakpoint="0" hide-default-footer :loading="table.loading" no-data-text="Não há dados." no-results-text="Sem resultados." :items-per-page="999999" disable-sort :headers="table.headers" :items="discharges" class="border elevation-4">
                
                <template v-slot:top>
                     <v-btn block color="blue darken-1" text @click="roundExcess(item)">
                        <v-text>Arredondar</v-text> 
                        <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                </template>

                <template slot="body.append">
                    <tr>
                        <th>Total</th>
                        <th></th>
                        <th>{{ formatCurrency(sumField('excess')) }}</th>
                    </tr>
                </template>
                
                <template v-slot:[`item.excess`]="{ item }">
                    {{ formatCurrency(item.excess) }}
                </template>

            </v-data-table> -->
        </v-card-text>

        <v-card-text class="pa-5 border-bottom ">
            <DataTable :value="discharges" editMode="cell" @cell-edit-complete="onCellEditComplete" class="editable-cells-table p-datatable-sm" responsiveLayout="scroll">
                <Column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field" :footer="sumField(col.field)">
                    <template #editor="{ data, field }">
                        <InputNumber v-if="field == 'excess'" v-model="data[field]" mode="decimal" :minFractionDigits="2" />
                    </template>
                </Column>
            </DataTable>
        </v-card-text>


        <v-card-actions>
            <v-btn color="red darken-1" text @click="close">CANCELAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" :loading="loading.confirm" text @click="confirmDischarge">CONFIRMAR</v-btn>
        </v-card-actions>

    </v-card>
</v-dialog>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputNumber from 'primevue/inputnumber';

export default {
    
    name: "ModalDischarge",

    components: {
        DataTable,
        Column, 
        InputNumber
    },

    props: {
        dialog: Boolean,
        region: Number,
        discharges: Array
    },

    data: () => ({
        // SNACKBAR
        snackbar: {},

        columns: [
            {field: 'modality_code', header: 'Modalidade'},
            {field: 'number', header: 'Palpite'},
            {field: 'excess', header: 'Valor'},
        ],

        table: {
            headers: [
                {
                    text: "Modalidade",
                    value: "modality_code"
                },
                {
                    text: "Palpite",
                    value: "number"
                },
                {
                    text: "valor",
                    value: "excess"
                },
            ],

            loading: false,

           pagination: {
                current: 1,
                total: 0,
                perpage: 999999
            },
            total_perpages: [
                999999
            ],
        },

        // DIALOG 
        show: false,
        valid: false,
        regions: [],
        region_id: null,

        loading: {
            regions: false,
            confirm: false,
        },
    }),

    watch: {
        // WATCH THE VALUE TO OPEN THE MODAL
        dialog: function (val) {
            if (val) {
                this.loadDischargeRegions();
            }
            this.show = val;
        },

        // IF USER CLICKS OUTSIDE THE DIALOG 
        show: function (val) {
            val || this.close();
        },
    },


    methods: {

        onCellEditComplete (event) {
            let { data, newValue, field } = event;

            switch (field) {
                case 'excess':
                    data[field] = newValue; 
                break;

            }
        },

        sumField(key) {
            // sum data in give key (property)
            if(key == 'excess') {
                let total = this.discharges.reduce((a, b) => parseFloat(a) + parseFloat((b[key] || 0)), 0)
                return total
            } else {
                return ''
            }
           
        },
    

        roundExcess() {
            for(let i=0; i< this.discharges.length; i++) {
                this.discharges[i].excess = Math.ceil(this.discharges[i].excess) == 0 ? 1 : Math.ceil(this.discharges[i].excess)
            }
        },

        confirmDischarge() {

            if (this.$refs.formDischarge.validate()) {

                for(let i=0; i< this.discharges.length; i++) {
                    this.discharges[i].receiving_region = this.region_id
                }

                this.loading.confirm = true

                this.$api
                    .put(`/discharges`, { palpites: this.discharges }, { 
                        headers: {
                            Authorization: `Bearer ${this.accessToken()}`
                        }
                    })
                    .then(() => {
                        this.snackbar.text = "Palpite descarregado com sucesso!";
                        this.snackbar.type = "green";
                        this.snackbarShow(this.snackbar);
                        this.loading.confirm = false

                        this.reload();
                    })
                    .catch(() => {
                        this.snackbar.text = "Erro ao descarregar palpite";
                        this.snackbar.type = "error";
                        this.snackbarShow(this.snackbar);
                        this.loading.confirm = false
                    });
            }
        },

        loadDischargeRegions() {
            this.loading.regions = true;

            this.$api
            .get("dischargeRegions", {
                headers: {
                    Authorization: `Bearer ${this.accessToken()}`
                },
                params: {
                    search_region: this.region,
                }
            })
            .then(response => {
                this.regions = response.data;
                this.loading.regions = false;
            })
            .catch(() => {
                this.loading.regions = false;
            });
 
        },

        close() {
            this.$emit('close');
            this.$emit('reload');
        },

        // RELOAD PARENT
        reload() {
            this.close();
            this.$emit('reload');
        },

    }
}
</script>

<style> 
.p-datatable.p-datatable-sm .p-datatable-header {
  display: flex;
  justify-content: center;
}

.p-datatable-sm td {
  text-align: center !important;
  padding: 0.3rem 0.3rem !important;
}

.p-column-header-content {
  justify-content: center;
}


</style>