<template>
<v-dialog v-model="show" max-width="800px">
    <v-card>
        <v-card-text class="pa-5 border-bottom">
            <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Loterias para a região <b v-text="region.name"></b>.</h3>
            <h6 class="subtitle-2 font-weight-light">Selecione as loterias disponíveis para essa região.</h6>
        </v-card-text>

        <!-- FORM -->
        <v-card-text class="pa-5 border-bottom">
            <v-form v-model="valid" lazy-validation ref="formRegionLotteries">
                <v-row>

                    <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="py-0 my-0">
                        <v-select label="Ação" v-model="selectedAction" :rules="[value => !!value || 'Selecione a ação']" :items="actions" no-data-text="Sem dados disponível." outlined></v-select> 
                    </v-col>

                    <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="py-0 my-0">
                        <v-select multiple label="Loteria" v-model="selectedLotteries" :rules="[value => !!value || 'Selecione as loterias que ficarão visíveis para essa região.']" :items="lotteries" :loading="loading.lotteries" item-text="name" item-value="id" no-data-text="Sem dados disponível." outlined></v-select> 
                    </v-col>

                    <v-col cols="12" xs="12" class="py-0 my-0">
                        <v-checkbox label="Confirmar loterias?" v-model="checkbox" :rules="[value => !!value || 'É necessário confirmar.']" dense required class="ma-0" color="green"></v-checkbox>
                    </v-col>

                </v-row>
            </v-form>
        </v-card-text>

        <v-card-actions>
            <v-btn color="red darken-1" text @click="close">CANCELAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" :disabled="!valid" text @click="regionLotteryEdit(region)">SALVAR</v-btn>
        </v-card-actions>

    </v-card>
</v-dialog>
</template>

<script>
export default {
    name: "ModalRegionLotteriesNewEdit",

    props: {
        region: Object,
        dialog: Boolean,
    },


    data: () => ({
        // SNACKBAR
        snackbar: {},
        actions: ['Adicionar', 'Remover'],
        lotteries: [],

        selectedLotteries: [],

        loading: {
            lotteries: true,
        },

        // DIALOG 
        valid: false,
        checkbox: false,
        show: false,
    }),

    created() {
    },

    watch: {

        // WATCH THE VALUE TO OPEN THE MODAL
        dialog: function (val) {
            if(val == true) {
                this.loadLotteries();
            }
            this.show = val;
        },

        // IF CLICKS OUTSIDE THE DIALOG 
        show: function (val) {
            val || this.close();
        }
    },

    methods: {
        // CLOSE MODAL   
        close() {
            this.$emit('close');
            this.$refs.formRegionLotteries.reset();
        },

        // RELOAD PARENT
        reload() {
            this.close();
            this.$emit('reload');
        },

        loadLotteries() {
            this.loading.lotteries = true;

            this.$api
               .get(`/lotteries`, {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                })
                .then(response => {
                    this.lotteries = response.data;
                    this.loading.lotteries = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar as loterias.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);

                    this.loading.lotteries = false;
                });
        },

        // EDITAR COMISSÃO
        regionLotteryEdit () {

            if (this.$refs.formRegionLotteries.validate()) {

                for(let i=0; i<this.selectedLotteries.length; i++) {

                    let region_lottery = {
                        lottery_id: this.selectedLotteries[i],
                        region_id: this.region.id,
                        action: this.selectedAction
                    }

                    this.$api
                        .patch(`/lotteries/region_lotteries`, {...region_lottery}, {
                            headers: {
                                Authorization: `Bearer ${this.accessToken()}`
                            },
                        })
                        .then(() => {
                            this.snackbar.text = "Região atualizada com sucesso!";
                            this.snackbar.type = "green";
                            this.snackbarShow(this.snackbar);
                        })
                        .catch(() => {
                            this.snackbar.text = "Erro na atualização da região!";
                            this.snackbar.type = "error";
                            this.snackbarShow(this.snackbar);
                        });
                }

                this.reload();
            } 
        },


    }
}
</script>
