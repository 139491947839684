<template>
    <v-dialog v-model="show" max-width="800px">
        <v-card>    
            <v-card-text class="pa-5 border-bottom">
                <v-form v-model="valid" lazy-validation ref="formConfig">
                    <v-row class="ma-0 pa-0 justify-space-between">
                        <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="py-0 my-0">
                            <v-select dense @change="loadConfigs" label="Região" v-model="region_search" :rules="[value => !!value || 'Informe a região.']" :items="regions" :loading="loading.regions" :disabled="loading.regions" item-text="name" item-value="id" no-data-text="Sem dados disponível." outlined></v-select>
                        </v-col>

                        <div class="row">
                            <DataTable style="width: 100% !important;" :value="configs" editMode="cell" @cell-edit-complete="onCellEditComplete" class="editable-cells-table p-datatable-sm" responsiveLayout="scroll">
                                <Column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field">
                                    <template #editor="{ data, field }">
                                        <InputNumber v-if="field != 'quantity'" v-model="data[field]" mode="decimal" :minFractionDigits="2" />
                                    </template>
                                </Column>
                            </DataTable>
                        </div>

                    </v-row>
                </v-form>
            </v-card-text>
    
            <v-card-actions>
                <v-btn color="red darken-1" text @click="close">CANCELAR</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" :disabled="!valid" text @click="saveConfig">SALVAR ALTERAÇÃO</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    </template>
    
    <script>
    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';
    import InputNumber from 'primevue/inputnumber';

    export default {
        name: "ModalLotteryConfig",

        components: {
            DataTable,
            Column, 
            InputNumber
        },

        props: {
            lottery: Object,
            dialog: Boolean,
        },
    
        data: () => ({
            // SNACKBAR
            snackbar: {},
            
            loading: {
                regions: false,
                configs: false,
            },
            
            regions: [],
            configs: [],
            config: {},

            columns: [
                {field: 'quantity', header: 'Quantidade de dezenas'},
                {field: 'quotation', header: 'Cotação'},
                {field: 'min_value', header: 'Valor mínimo'},
                {field: 'max_value', header: 'Valor máximo'},
            ],

            region_search: null,
            rules: [value => !!value || "Informe o valor."],
            
            // DIALOG 
            valid: false,
            checkbox: false,
            show: false,
        }),
    
        watch: {
            // WATCH THE VALUE TO OPEN THE MODAL
            dialog: function (val) {
                this.show = val;
            },
    
            // IF USER CLICKS OUTSIDE THE DIALOG 
            show: function (val) {
                val || this.close();
            },
        },
    
        computed: {
            // FORMAT DATE
            formatNewDate: {
                get() {
                    return this.formatDate(this.ex_date);
                },
    
                set() {
                    this.ex_date = null;
                }
            },
        },
    
        created() {
            this.loadRegions();
        },

        methods: {
            loadRegions() {
                this.loading.regions = true

                this.$api
                    .get(`/regions`, { 
                        headers: {
                            Authorization: `Bearer ${this.accessToken()}`
                        },
                    })
                    .then((response) => {
                        this.regions = response.data;
                        this.loading.regions = false
                    })
                    .catch(() => {
                        this.snackbar.text = "Não foi possível carregar as regiões.";
                        this.snackbar.type = "warning";
                        this.snackbarShow(this.snackbar);

                        this.loading.regions = false
                    });    
            },

            loadConfigs() {
                this.loading.configs = true

                this.$api
                    .get('/partial_quotations', { 
                        headers: {
                            Authorization: `Bearer ${this.accessToken()}`
                        },
                        params: {
                            region_search: [this.region_search],
                            caixa_lottery_search: 5
                        }
                    })
                    .then((response) => {
                        this.configs = response.data;
                        this.loading.configs = false
                    })
                    .catch(() => {
                        this.snackbar.text = "Não foi possível carregar as configurações para essa região";
                        this.snackbar.type = "warning";
                        this.snackbarShow(this.snackbar);

                        this.loading.configs = false
                    });    
            },

            onCellEditComplete (event) {
                let { data, newValue, field } = event;

                if(field != 'quantity') {
                    data[field] = newValue; 
                }
            },

            // CLOSE MODAL   
            close() {
                this.configs = []
                this.$emit('close');
                this.$refs.formConfig.reset();
            },
    
            // RELOAD PARENT
            reload() {
                this.close();
                this.$emit('reload');
            },
         
            // NEW LOTTERY
            lotterySave() {
                if (this.$refs.formConfig.validate()) {
    
                    this.$api
                        .post("/lotteries", this.lottery, {
                            headers: {
                                Authorization: `Bearer ${this.accessToken()}`
                            }
                        })
                        .then(() => {
                            this.snackbar.text = "Loteria cadastrada com sucesso!";
                            this.snackbar.type = "green";
                            this.snackbarShow(this.snackbar);
    
                            this.reload();
                        })
                        .catch(() => {
                            this.snackbar.text = "Erro ao cadastrar nova loteria!";
                            this.snackbar.type = "error";
                            this.snackbarShow(this.snackbar);
                        });
                }
            },
    
            // UPDATE EXISTING EXPENSE
            saveConfig(){
                
                if (this.$refs.formConfig.validate()) {

                    this.$api
                    .patch(`/partial_quotations`, {partial_quotations: this.configs}, {
                        headers: {
                            Authorization: `Bearer ${this.accessToken()}`
                        }
                    })
                    .then(() => {
                        this.snackbar.text = "Edição realizada com sucesso!";
                        this.snackbar.type = "green";
                        this.snackbarShow(this.snackbar);
    
                        this.reload();
                    })
                    .catch(() => {
                        this.snackbar.text = "Erro ao editar loteria!";
                        this.snackbar.type = "error";
                        this.snackbarShow(this.snackbar);
                    });
                    
                }
            }
            
        }
    }
    </script>
    