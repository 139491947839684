<template>
<v-dialog v-model="confirmation.dialog" max-width="500px" persistent>
    <v-card>
        <v-card-text class="pa-5 border-bottom">
            <h3 class="title blue-grey--text text--darken-2 font-weight-regular" v-text="confirmation.title"></h3>
        </v-card-text>

        <v-card-text class="pa-5 border-bottom">
            <h5 class="subtitle-2 font-weight-light" v-text="confirmation.text"></h5>
        </v-card-text>

        <v-card-actions>
            <v-btn color="red darken-1" text @click="reject">CANCELAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="confirm">CONFIRMAR</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
export default {
    name: "ModalConfirmation",

    props: {
        confirmation: {
            title: String,
            text: String,
            dialog: Boolean,
        }
    },

    methods: {
        // CONFIRM DELETE 
        confirm() {
            this.$emit('confirmDelete');
        },

        // REJECT DELETE 
        reject() {
            this.$emit('rejectDelete');
        },
    },
}
</script>
