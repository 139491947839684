<template>
<v-dialog v-if="show" v-model="show" max-width="800px">

     <!-- DIALOG CONFIRM -->
    <ModalConfirmation :confirmation="confirmation" @confirmDelete="resultSave" @rejectDelete="confirmation.dialog = false"></ModalConfirmation>
    
    <v-card>
        <v-card-text class="pa-5 border-bottom">
            <h3 class="title blue-grey--text text--darken-2 font-weight-regular"> Confirmar resultado para extração {{ extraction.name }}</h3>
        </v-card-text>

        <v-card-text class="pa-5 border-bottom">
            <v-simple-table class="elevation-2">
                <template v-slot:default>
                    <thead class="primary">
                        <tr>
                            <th>
                                Prêmio
                            </th>
                            <th>
                                Milhar
                            </th>
                            <th>
                                Grupo
                            </th>
                            <th>
                                Animal
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1º</td>
                            <td>{{result[0].value}}</td>
                            <td>{{result[0].grupo}}</td>
                            <td>{{getAnimal(result[0].grupo)}}</td>
                        </tr>
                        <tr>
                            <td>2º</td>
                            <td>{{result[1].value}}</td>
                            <td>{{result[1].grupo}}</td>
                            <td>{{getAnimal(result[1].grupo)}}</td>
                        </tr>
                        <tr>
                           <td>3º</td>
                           <td>{{result[2].value}}</td>
                           <td>{{result[2].grupo}}</td>
                           <td>{{getAnimal(result[2].grupo)}}</td>
                        </tr>
                        <tr>
                           <td>4º</td>
                           <td>{{result[3].value}}</td>
                           <td>{{result[3].grupo}}</td>
                           <td>{{getAnimal(result[3].grupo)}}</td>
                        </tr>
                        <tr>
                            <td>5º</td>
                            <td>{{result[4].value}}</td>
                            <td>{{result[4].grupo}}</td>
                            <td>{{getAnimal(result[4].grupo)}}</td>
                        </tr>
                        <tr>
                            <td>6º</td>
                            <td>{{result[5].value}}</td>
                            <td>{{result[5].grupo}}</td>
                            <td>{{getAnimal(result[5].grupo)}}</td>
                        </tr>
                        <tr>
                            <td>7º</td>
                            <td>{{result[6].value}}</td>
                            <td>{{result[6].grupo}}</td>
                            <td>{{getAnimal(result[6].grupo)}}</td>
                        </tr>
                        <tr>
                            <td>8º</td>
                            <td>{{result[7].value}}</td>
                            <td>{{result[7].value}}</td>
                            <td>{{getAnimal(result[7].value)}}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card-text>

        <v-card-actions v-if="register == true">
            <v-btn color="red darken-1" text @click="close">CANCELAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="resultConfirm">CONFIRMAR</v-btn>
        </v-card-actions>

        <v-card-actions v-else>
            <v-btn color="red darken-1" text @click="close">CANCELAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="resultUpdate">CONFIRMAR EDIÇÃO</v-btn>
        </v-card-actions>

      
    </v-card>
</v-dialog>
</template>


<script>
export default {
    name: "ModalResultNewEdit",

    props: {
        register: Boolean,
        dialog: Boolean,
        result: Array,
        resultEdit: Object,
        extraction: Object,
        lottery: Number,
        date: String,
        regions: Array,
    },

    data: () => ({
        // SNACKBAR
        snackbar: {},
        loading: false,

        // DIALOG
        show: false,

        animals :[
        'Avestruz', 'Águia', 'Burro', 'Borboleta', 'Cachorro', 'Cabra', 'Carneiro', 'Camelo',
        'Cobra', 'Coelho', 'Cavalo', 'Elefante', 'Galo', 'Gato', 'Jacaré', 'Leão', 'Macaco',
        'Porco', 'Pavão', 'Peru', 'Touro', 'Tigre', 'Urso', 'Veado', 'Vaca'],

        confirmation: {
            title: "Deseja lançar esse resultado?",
            text: "Essa não poderá ser revertida.",
            dialog: false
        },
        
    }),

    watch: {
        // WATCH THE VALUE TO OPEN THE MODAL
        dialog: function (val) {
            this.show = val;
        },

        // IF USER CLICKS OUTSIDE THE DIALOG
        show: function (val) {
            val || this.close();
        },

    },

    methods: {
        // CLOSE MODAL

        close() {
            this.$emit("reload");
        },
        
        getAnimal (grupo)  {
            let animal = ''

            this.animals.map((item, index) => {
                if(parseInt(grupo) == index+1){
                    animal = item
                } 
            })

            return animal
        }, 


        resultUpdate(){

            this.$api
            .get(`/results/update`, { 
                headers: {
                    Authorization: `Bearer ${this.accessToken()}`
                },
                params: {
                    id: this.resultEdit.id,
                    lottery_id: this.lottery,
                    extraction_id: this.extraction.id,
                    extraction_name: this.extraction.name,
                    first: this.result[0].value + "-" + this.result[0].grupo, 
                    second: this.result[1].value + "-" + this.result[1].grupo, 
                    third: this.result[2].value + "-" + this.result[2].grupo, 
                    fourth: this.result[3].value + "-" + this.result[3].grupo, 
                    fifth: this.result[4].value + "-" + this.result[4].grupo, 
                    sixth: this.result[5].value + "-" + this.result[5].grupo, 
                    seventh: this.result[6].value + "-" + this.result[6].grupo, 
                    eighth: this.result[7].value 
                }
            })
            .then(() => {
                this.snackbar.text = "Resultado atualizado com sucesso!";
                this.snackbar.type = "green";
                this.snackbarShow(this.snackbar);
                this.close()
            })
            .catch(() => {
                this.snackbar.text = "Erro ao atualizar resultado";
                this.snackbar.type = "error";
                this.snackbarShow(this.snackbar);
                this.close()
            });
            
        },

        // salvando resultado
        resultSave() {               
            this.$api
            .get(`/results/store`, {
                headers: {
                    Authorization: `Bearer ${this.accessToken()}`
                },
                params: {
                    regions: this.regions,
                    lottery_id: this.lottery,
                    extraction_name: this.extraction.name,
                    first: this.result[0].value + "-" + this.result[0].grupo, 
                    second: this.result[1].value + "-" + this.result[1].grupo, 
                    third: this.result[2].value + "-" + this.result[2].grupo, 
                    fourth: this.result[3].value + "-" + this.result[3].grupo, 
                    fifth: this.result[4].value + "-" + this.result[4].grupo, 
                    sixth: this.result[5].value + "-" + this.result[5].grupo, 
                    seventh: this.result[6].value + "-" + this.result[6].grupo, 
                    eighth: this.result[7].value ,
                    date: this.date
                }
            })
            .then(() => {
                this.snackbar.text = "Resultado cadastrado com sucesso!";
                this.snackbar.type = "green";
                this.snackbarShow(this.snackbar);
            })
            .catch(() => {
                this.snackbar.text = "Erro ao cadastrar novo resultado!";
                this.snackbar.type = "error";
                this.snackbarShow(this.snackbar);
            });

            this.close();
        },

        resultConfirm() {
            this.confirmation.dialog = true;
        },
    }
};
</script>
