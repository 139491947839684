<template>
    <v-dialog v-model="show" max-width="800px">
        <v-card>
            <!-- TEXT CHANGE IF IS NEW OR EDIT -->
            <v-card-text v-if="register == true" class="pa-5 border-bottom">
                <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Cadastro de nova região.</h3>
                <h6 class="subtitle-2 font-weight-light">Preencha todas os campos abaixo e confirme o cadastro de uma
                    nova região.</h6>
            </v-card-text>

            <v-card-text v-else class="pa-5 border-bottom">
                <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Edição de região: <b
                        v-text="region.name"></b>.</h3>
                <h6 class="subtitle-2 font-weight-light">Preencha todas os campos abaixo e confirme a edição do cadastro
                    de uma região existente no sistema.</h6>
            </v-card-text>

            <v-card-text class="pa-5 border-bottom">
                <v-form v-model="valid" lazy-validation ref="formRegion">
                    <v-row class="ma-0 pa-0 justify-space-between">

                        <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="py-0 my-0">
                            <v-text-field dense label="Nome da região" v-model="region.name"
                                :rules="[value => !!value || 'Informe o nome da região']" outlined
                                clearable></v-text-field>
                        </v-col>

                    </v-row>
                </v-form>
            </v-card-text>

            <!-- ACTIONS CHANGE IF IS NEW OR EDIT -->
            <v-card-actions v-if="register == true">
                <v-btn color="red darken-1" text @click="close">CANCELAR</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" :disabled="!valid" text @click="regionSave">NOVA REGIÃO</v-btn>
            </v-card-actions>

            <v-card-actions v-else>
                <v-btn color="red darken-1" text @click="close">CANCELAR</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" :disabled="!valid" text @click="regionUpdate">Salvar alteração</v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "ModalRegionNewEdit",

    props: {
        region: Object,
        edit: Boolean,
        register: Boolean,
        dialog: Boolean,
    },

    data: () => ({
        // SNACKBAR
        snackbar: {},

        disabled: {
            regions: false,
            sub_admins: true,
        },

        discharge_types: [
            'manual',
            'automatico'
        ],

        discharge_limits: [
            'por modalidade',
            'fixo'
        ],

        award_types: [
            'automatico',
            'manual'
        ],

        loading: {
            sub_admins: false,
            admins: false,
            regions: false,
            lotteries: false
        },

        admins: [],
        sub_admins: [],

        admin_search: null,

        // DIALOG 
        valid: false,
        checkbox: false,
        show: false,
    }),

    watch: {
        // WATCH THE VALUE TO OPEN THE MODAL
        dialog: function (val) {
            if (val) {
                this.loadAdmins();
                this.loadLotteries();
            }
            this.show = val;
        },

        edit: function () {
            if (this.edit == true) {
                this.region.discharge_comission = parseInt(this.region.discharge_comission)
            }
        },

        // IF USER CLICKS OUTSIDE THE DIALOG 
        show: function (val) {
            val || this.close();
        },
    },

    computed: {
        // FORMAT DATE
        formatNewDate: {
            get() {
                return this.formatDate(this.ex_date);
            },

            set() {
                this.ex_date = null;
            }
        },
    },

    created() {
        this.loadAdmins();
    },

    methods: {
        // CLOSE MODAL   
        close() {
            this.$emit('close');
            this.$refs.formRegion.reset();
        },

        // RELOAD PARENT
        reload() {
            this.close();
            this.$emit('reload');
        },



        loadAdmins() {

            this.loading.admins = true;

            this.$api
                .get("/users/admins", {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    }
                })
                .then(response => {
                    this.admins = response.data;
                    this.loading.admins = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar os admins de região.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);
                    this.loading.admins = false;
                });

        },


        loadLotteries() {
            this.loading.lotteries = true;

            this.$api
                .get("/lotteries", {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                })
                .then((response) => {
                    this.lotteries = response.data;
                    this.loading.lotteries = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar as loterias.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);

                    this.loading.lotteries = false;
                });
        },


        // NEW REGION
        regionSave() {

            if (this.$refs.formRegion.validate()) {
                this.$api
                    .post("/regions", this.region, {
                        headers: {
                            Authorization: `Bearer ${this.accessToken()}`
                        }
                    })
                    .then(() => {
                        this.snackbar.text = "Região cadastrada com sucesso!";
                        this.snackbar.type = "green";
                        this.snackbarShow(this.snackbar);
                        this.reload();
                    })
                    .catch(() => {
                        this.snackbar.text = "Erro ao cadastrar nova região!";
                        this.snackbar.type = "error";
                        this.snackbarShow(this.snackbar);
                    });

            }
        },

        // UPDATE EXISTING REGION
        regionUpdate() {
            if (this.$refs.formRegion.validate()) {
                this.$api
                .patch(`/regions`, this.region, { // faltando argumentos
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    }
                })
                .then(() => {
                    this.snackbar.text = "Edição realizada com sucesso!";
                    this.snackbar.type = "green";
                    this.snackbarShow(this.snackbar);

                    this.reload();
                })
                .catch(() => {
                    this.snackbar.text = "Erro ao editar região!";
                    this.snackbar.type = "error";
                    this.snackbarShow(this.snackbar);
                });
            }
        }


    }
}
</script>
