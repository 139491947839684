<template>
    <Dialog class="dialog" :v-model:visible="show" :showHeader="false" :modal="true" :dismissableMask="true">
        <InputText placeholder="Parecer" v-model="solicitation.statement" />
        <Button label="Confirmar" @click="solicitationSave()" icon="pi pi-angle-right" icon-pos="right" :loading="loading"></Button>
    </Dialog>
</template>
    
<script>
    import Dialog from 'primevue/dialog';
    import InputText from 'primevue/inputtext';
    import Button from 'primevue/button';

    export default {
        name: "ModalConfirmSolicitation",

        components: {
           Dialog,
           InputText,
           Button,
        },

        props: {
            solicitation: Object,
            action: String,
            dialog: Boolean
        },
    
        data: () => ({
            show: false,
            loading: false,
        }),
    
        created() {
            console.log(dialog)
        },
        
        watch: {
            // WATCH THE VALUE TO OPEN THE MODAL
            dialog: function (val) {
                this.show = val;
                console.log(val)
            },
    
            // IF USER CLICKS OUTSIDE THE DIALOG 
            show: function (val) {
                val || this.close();
            },
        },
    
        methods: {
            // CLOSE MODAL   
            close() {
                this.resetFields();
                this.$emit('close');
                this.$refs.formSolicitation.reset();
            },
    
            // RELOAD PARENT
            reload() {
                this.close();
                this.$emit('reload');
            },
      
            
            // nova reclamacao
            solicitationSave() {
                this.loading = true

                if (this.$refs.formSolicitation.validate()) {
    
                    this.$api
                    .post(`/solicitations/updateStatus`, { solicitation: this.solicitation, action: action }, {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`,
                    },
                    }).then(() => {
                        this.snackbar.text = "Solicitação aprovada com sucesso!";
                        this.snackbar.type = "green";
                        this.snackbarShow(this.snackbar);
                        this.loading = false
                    })
                    .catch(() => {
                        this.snackbar.text = "Erro ao aprovar solicitação.";
                        this.snackbar.type = "error";
                        this.snackbarShow(this.snackbar);
                        this.loading = false
                    });
                    
                }
            },
        }
    }
    </script>
    