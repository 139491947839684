var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"800px "},model:{value:(_vm.display),callback:function ($$v) {_vm.display=$$v},expression:"display"}},[_c('v-card',{staticClass:"pa-5 border-bottom"},[_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":"","icon":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('span',{staticClass:"text-h5 "},[_vm._v(" "+_vm._s(_vm.ticket.sender_region_name)+" ")])]),_c('v-card-text',{attrs:{"elevation-1":""}},[_c('div',{staticClass:"pa-3 elevation-1"},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('span',{staticClass:"text-h7 black--text"},[_vm._v(" Bilhete:")]),_c('span',{staticClass:"text-h7 black--text"},[_vm._v(_vm._s(_vm.ticket.id))])]),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('span',{staticClass:"text-h7 black--text"},[_vm._v(" Extração:")]),_c('span',{staticClass:"text-h7 black--text"},[_vm._v(_vm._s(_vm.ticket.extraction_name))])]),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('span',{staticClass:"text-h7 black--text"},[_vm._v(" Data:")]),_c('span',{staticClass:"text-h7 black--text"},[_vm._v(_vm._s(new Date(_vm.ticket.created_at).toLocaleString('pt-BR')))])])])]),_c('v-card-text',{staticClass:"pa-3 border-bottom"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"no-data-text":"Não há loterias.","no-results-text":"Sem resultados.","mobile-breakpoint":0,"hide-default-footer":"","loading-text":"Carregando...","disable-sort":"","headers":_vm.table.headers,"items":_vm.ticket.games,"items-per-page":_vm.table.pagination.perpage},on:{"page-count":function($event){_vm.table.pagination.total = $event}},scopedSlots:_vm._u([{key:"item.selected_numbers",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.selected_numbers.replace('[', '').replace(']', '').replace('"', '').replace('"', ''))+" ")]}},{key:"item.expected_value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(item.expected_value))+" ")]}},{key:"item.modality_code",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.modality_code[0].toUpperCase() + item.modality_code.substr(1))+" ")]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(item.value))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status == 'win')?_c('p',[_vm._v("Ganhou")]):_vm._e(),(item.status == 'lose')?_c('p',[_vm._v("Perdeu")]):_vm._e(),(item.status == 'open')?_c('p',[_vm._v("Aberto")]):_vm._e()]}},{key:"item.award",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.processAwardName(item.award))+" ")]}},{key:"item.selected_positions",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.processPositionName(JSON.parse(item.selected_positions)[0]))+" ")]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',[_c('th',[_vm._v("Total")]),_c('th'),_c('th'),_c('th'),_c('th',[_vm._v(_vm._s(_vm.formatCurrency(_vm.sumField('value'))))]),_c('th',[_vm._v(_vm._s(_vm.formatCurrency(_vm.sumField('expected_value'))))]),_c('th')])])],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }