<template>
<v-dialog v-model="show" max-width="800px">
    <v-card>
        <!-- TEXT CHANGE IF IS NEW OR EDIT -->
        <v-card-text v-if="register == true" class="pa-5 border-bottom">
            <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Cadastro de nova mensagem.</h3>
            <h6 class="subtitle-2 font-weight-light">Preencha todas os campos abaixo e confirme o cadastro de uma nova mensagem.</h6>
        </v-card-text>

        <v-card-text v-else class="pa-5 border-bottom">
            <h6 class="subtitle-2 font-weight-light">Preencha todas os campos abaixo e confirme a edição de uma mensagem existente no sistema.</h6>
        </v-card-text>

        <v-card-text class="pa-5 border-bottom">
            <v-form v-model="valid" lazy-validation ref="formTicketFooter">
                <v-row class="ma-0 pa-0 justify-space-between">

                    <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="py-0 my-0">
                        <v-select dense label="Região"  v-model="ticketFooter.region_id" :rules="[value => !!value || 'Selecione a região.']" :items="regions" :loading="loading.regions" :disabled="loading.regions" item-text="name" item-value="id" no-data-text="Sem dados disponível." outlined></v-select>
                    </v-col>
   
                    <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="py-0 my-0">
                        <v-textarea outlined clearable  label="Descrição" v-model="ticketFooter.description" :rules="[value => !!value || 'Informe a descrição.']"></v-textarea>
                    </v-col>
            
                </v-row>
            </v-form>
        </v-card-text>

        <!-- ACTIONS CHANGE IF IS NEW OR EDIT -->
        <v-card-actions v-if="register == true">
            <v-btn color="red darken-1" text @click="close">CANCELAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" :disabled="!valid" text @click="ticketFooterSave">NOVA MENSAGEM</v-btn>
        </v-card-actions>

        <v-card-actions v-else>
            <v-btn color="red darken-1" text @click="close">CANCELAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" :disabled="!valid" text @click="ticketFooterUpdate">SALVAR ALTERAÇÃO</v-btn>
        </v-card-actions>
        

    </v-card>
</v-dialog>
</template>

<script>
export default {
    name: "ModalTicketFooterNewEdit",

    props: {
        ticketFooter: Object,
        edit: Boolean,
        register: Boolean,
        dialog: Boolean,
    },

    data: () => ({
        // SNACKBAR
        snackbar: {},
        
        // COMPONENT
        regions: [],
        
        loading: {
            regions: false,
        },

        // DIALOG 
        valid: false,
        checkbox: false,
        show: false,
    }),

    watch: {
        // WATCH THE VALUE TO OPEN THE MODAL
        dialog: function (val) {
            this.show = val;
        },
/* 
        edit: function () {
            if(this.edit == true) {
                this.loadRegions();
            }
        }, */

        // IF USER CLICKS OUTSIDE THE DIALOG 
        show: function (val) {
            val || this.close();
        },
    },

    computed: {
        // FORMAT DATE
        formatNewDate: {
            get() {
                return this.formatDate(this.ex_date);
            },

            set() {
                this.ex_date = null;
            }
        },
    },

    created() {
       this.loadRegions()
    },

    methods: {
        // CLOSE MODAL   
        close() {
            this.$emit('close');
            this.$refs.formTicketFooter.reset();
        },

        // RELOAD PARENT
        reload() {
            this.close();
            this.$emit('reload');
        },

         // LOAD SELLER POINTS
        loadRegions() {
            this.loading.regions = true;

            this.$api
                .get("/regions", {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                })
                .then(response => {
                    this.regions = response.data;
                    this.loading.regions = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar os pontos de venda.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);

                    this.loading.regions = false;
                });
        },

        // novo
        ticketFooterSave() {
          
            if (this.$refs.formTicketFooter.validate()) {

                this.$api
                .post("/ticket_footer", this.ticketFooter, {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    }
                })
                .then(() => {
                    this.snackbar.text = "Mensagem cadastrada com sucesso!";
                    this.snackbar.type = "green";
                    this.snackbarShow(this.snackbar);
                    this.reload();
                })
                .catch(() => {
                    this.snackbar.text = "Erro ao cadastrar mensagem!";
                    this.snackbar.type = "error";
                    this.snackbarShow(this.snackbar);
                    });
                
            }
        },

        // UPDATE EXISTING ticketFooter
        ticketFooterUpdate(){

            if (this.$refs.formTicketFooter.validate()) {
                
            this.$api
            .put(`/ticket_footer`, this.ticketFooter, { // faltando argumentos
                headers: {
                    Authorization: `Bearer ${this.accessToken()}`
                }
            })
            .then(() => {
                this.snackbar.text = "Edição realizada com sucesso!";
                this.snackbar.type = "green";
                this.snackbarShow(this.snackbar);

                this.reload();
            })
            .catch(() => {
                this.snackbar.text = "Erro ao editar mensagem";
                this.snackbar.type = "error";
                this.snackbarShow(this.snackbar);
            });
            
        }
        }
    }
}
</script>
