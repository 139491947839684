<template>
<v-dialog v-model="show" max-width="800px">

    <ModalResultConfirm :regions="search_region" :date="date_search" :register="register" :extraction="extraction" :lottery="lottery" :result="result" :dialog="dialog_confirm" @close="dialog_confirm = false" @reload="reload"/>
    
    <v-card>
        <v-card-text v-if="register == true" class="pa-5 border-bottom">
            <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Cadastro de novo resultado.</h3>
            <h6 class="subtitle-2 font-weight-light">Preencha todos os campos abaixo e confirme o cadastro de um novo resultado para uma extração existente no nosso sistema.</h6>
        </v-card-text>

        <v-card-text v-else class="pa-5 border-bottom">
            <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Edição de resultado.</h3>
            <h6 class="subtitle-2 font-weight-light">Preencha todas os campos abaixo e confirme a edição de um resultado existente no sistema.</h6>
        </v-card-text>

        <v-card-text class="pa-5 border-bottom">
            <v-form v-model="valid" lazy-validation ref="formResult">
                <v-row>

                    <!-- LOTTERIES -->
                    <v-col cols="12" lg="6" md="6" sm="6" xs="12" class="py-0 my-0">
                        <v-select multiple dense :disabled="loading.lotteries" :loading="loading.lotteries" no-data-text="Sem dados disponível." outlined :rules="[value => !!value || 'Selecione a região.']" item-text="name" item-value="id" v-model="search_region" :items="regions" label="Região"></v-select>
                    </v-col>

                    <v-col cols="12" lg="6" md="6" sm="6" xs="12" class="py-0 my-0">
                        <v-select dense :disabled="loading.lotteries" :loading="loading.lotteries" no-data-text="Sem dados disponível." outlined :rules="[value => !!value || 'Selecione a loteria.']" item-text="name" item-value="id" v-model="lottery" :items="lotteries" label="Loteria"></v-select>
                    </v-col>

                    <!-- DATE -->
                    <v-col cols="12" lg="6" md="6" sm="6" xs="12" class="py-0 my-0">
                        <v-menu :close-on-content-click="true" transition="scale-transition" min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field dense :disabled="!lottery" v-on="on" v-bind="attrs" readonly type="date" outlined :rules="[value => !!value || 'Informe a data da extração.']" v-model="date_search" label="Data da extração"></v-text-field>
                            </template>
                            <v-date-picker :disabled="!lottery" :max="new Date().toJSON()" @change="loadExtractions" color="primary" v-model="date_search" locale="pt-br"></v-date-picker>
                        </v-menu>
                    </v-col>

                    <!-- EXTRACTION -->
                    <v-col cols="12" lg="6" md="6" sm="6" xs="12" class="py-0 my-0">
                        <v-select dense :disabled="loading.extractions || date_search == null" :loading="loading.extractions" no-data-text="Sem dados disponível." outlined :rules="[value => !!value || 'Selecione a extração.']" item-text="name" return-object v-model="extraction" :items="list_extractions" label="Extração"></v-select>
                    </v-col>

                    <!-- AWARDS -->
                    <template v-if="extraction && extraction.name.includes('FED')">
                        <v-col cols="12" lg="6" md="6" sm="6" xs="12" class="py-0 my-0">
                            <v-text-field dense @change="processGroup(0); getSixthAward(); getSeventhAward(); getEighthAward()" maxlength="5" outlined :rules="award_rules" v-model="result[0].value" label="1º prêmio"></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="6" md="6" sm="6" xs="12" class="py-0 my-0">
                            <v-text-field dense outlined readonly v-model="result[0].grupo" label="Grupo do 1º prêmio"></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="6" md="6" sm="6" xs="12" class="py-0 my-0">
                            <v-text-field dense @change="processGroup(1); getSixthAward(); getSeventhAward()" maxlength="5" outlined :rules="award_rules" v-model="result[1].value" label="2º prêmio"></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="6" md="6" sm="6" xs="12" class="py-0 my-0">
                            <v-text-field dense outlined readonly v-model="result[1].grupo" label="Grupo do 2º prêmio"></v-text-field>
                        </v-col>
                    </template>

                    <template v-else>
                        <v-col cols="12" lg="6" md="6" sm="6" xs="12" class="py-0 my-0">
                            <v-text-field dense @change="processGroup(0); getSixthAward(); getSeventhAward(); getEighthAward()" maxlength="4" outlined :rules="award_rules" v-model="result[0].value" label="1º prêmio"></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="6" md="6" sm="6" xs="12" class="py-0 my-0">
                            <v-text-field dense outlined readonly v-model="result[0].grupo" label="Grupo do 1º prêmio"></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="6" md="6" sm="6" xs="12" class="py-0 my-0">
                            <v-text-field dense @change="processGroup(1);getSixthAward(); getSeventhAward()" maxlength="4" outlined :rules="award_rules" v-model="result[1].value" label="2º prêmio"></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="6" md="6" sm="6" xs="12" class="py-0 my-0">
                            <v-text-field dense outlined readonly v-model="result[1].grupo" label="Grupo do 2º prêmio"></v-text-field>
                        </v-col>
                    </template>

                    <v-col cols="12" lg="6" md="6" sm="6" xs="12" class="py-0 my-0">
                        <v-text-field dense @change="processGroup(2); getSixthAward()" maxlength="4" outlined :rules="award_rules" v-model="result[2].value" label="3º prêmio"></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6" md="6" sm="6" xs="12" class="py-0 my-0">
                        <v-text-field dense outlined readonly v-model="result[2].grupo" label="Grupo do 3º prêmio"></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="6" md="6" sm="6" xs="12" class="py-0 my-0">
                        <v-text-field dense @change="processGroup(3); getSixthAward()" maxlength="4" outlined :rules="award_rules" v-model="result[3].value" label="4º prêmio"></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6" md="6" sm="6" xs="12" class="py-0 my-0">
                        <v-text-field dense outlined readonly v-model="result[3].grupo" label="Grupo do 4º prêmio"></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="6" md="6" sm="6" xs="12" class="py-0 my-0">
                        <v-text-field dense @change="processGroup(4); getSixthAward()" maxlength="4" outlined :rules="award_rules" v-model="result[4].value" label="5º prêmio"></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6" md="6" sm="6" xs="12" class="py-0 my-0">
                        <v-text-field dense outlined readonly v-model="result[4].grupo" label="Grupo do 5º prêmio"></v-text-field>
                    </v-col>

                    <v-col v-if="extraction && search_region.includes(1)" cols="12" lg="6" md="6" sm="6" xs="12" class="py-0 my-0">
                        <v-text-field dense  @change="processGroup(5)" maxlength="4" outlined :rules="award_rules" v-model="result[5].value" label="6º prêmio"></v-text-field>
                    </v-col>

                    <v-col v-else cols="12" lg="6" md="6" sm="6" xs="12" class="py-0 my-0">
                        <v-text-field dense  @change="processGroup(5)" maxlength="3" outlined :rules="award_rules" v-model="result[5].value" label="6º prêmio"></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="6" md="6" sm="6" xs="12" class="py-0 my-0">
                        <v-text-field dense outlined  maxlength="2" v-model="result[5].grupo" label="Grupo do 6º prêmio"></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="6" md="6" sm="6" xs="12" class="py-0 my-0">
                        <v-text-field dense @change="processGroup(6)" maxlength="3" outlined :rules="award_rules" v-model="result[6].value" label="7º prêmio"></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="6" md="6" sm="6" xs="12" class="py-0 my-0">
                        <v-text-field dense outlined maxlength="2" v-model="result[6].grupo" label="Grupo do 7º prêmio"></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="6" md="6" sm="6" xs="12" class="py-0 my-0">
                        <v-text-field dense maxlength="2" outlined :rules="award_rules" v-model="result[7].value" label="8º prêmio"></v-text-field>
                    </v-col>

                    <v-col cols="12" xs="12" class="py-0 my-0">
                        <v-checkbox required v-model="checkbox" :rules="[value => !!value || 'É necessário confirmar.']" label="Confirmar resultado?" class="ma-0" color="green"></v-checkbox>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>

        <v-card-actions v-if="register == true">
            <v-btn color="red darken-1" text @click="close">CANCELAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" :disabled="!valid" text @click="resultConfirm">NOVO RESULTADO</v-btn>
        </v-card-actions>

        <v-card-actions v-else>
            <v-btn color="red darken-1" text @click="close">CANCELAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" :disabled="!valid" text @click="resultConfirm">SALVAR ALTERAÇÃO</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>


<script>
export default {
    name: "ModalResultNewEdit",

    props: {
        dialog: Boolean,
        register: Boolean,
    },

    data: () => ({
        // SNACKBAR
        snackbar: {},

        // COMPONENT
        result: [{
                value: "",
                grupo: ""
            },
            {
                value: "",
                grupo: ""
            },
            {
                value: "",
                grupo: ""
            },
            {
                value: "",
                grupo: ""
            },
            {
                value: "",
                grupo: ""
            },
            {
                value: "",
                grupo: ""
            },
            {
                value: "",
                grupo: ""
            },
            {
                value: "",
                grupo: ""
            }
        ],

        loading : {
            lotteries: false,
            extractions: false,
            regions: false
        },

        list_extractions: [],
        search_region: [],
        lotteries: [],
        regions: [],
        extraction: null,
        lottery: null,
        date_search: null,

        // DIALOG
        dialog_confirm: false,
        valid: false,
        checkbox: false,
        show: false,

        award_rules: [
            value => !!value || "Informe a milhar.",
            value => !isNaN(value) || "Informe somente números.",
        ],

    }),

    watch: {
        // WATCH THE VALUE TO OPEN THE MODAL
        dialog: function (val) {
            if(val){
                this.loadRegions();
                this.loadLotteries()
            }
            this.show = val;
        },

        extraction: function (val) {
            if(val) {
                this.result = [
                    {
                        value: "",
                        grupo: ""
                    },
                    {
                        value: "",
                        grupo: ""
                    },
                    {
                        value: "",
                        grupo: ""
                    },
                    {
                        value: "",
                        grupo: ""
                    },
                    {
                        value: "",
                        grupo: ""
                    },
                    {
                        value: "",
                        grupo: ""
                    },
                    {
                        value: "",
                        grupo: ""
                    },
                    {
                        value: "",
                        grupo: ""
                    }                
                ]
            }
        },

        // IF USER CLICKS OUTSIDE THE DIALOG
        show: function (val) {
            val || this.close();
        },

    },

    methods: {
        // CLOSE MODAL
        close() {
            this.$emit("close");
            this.$refs.formResult.reset();
            this.dialog_confirm = false;
        },

        // RELOAD PARENT
        reload() {
            this.close();
            this.$emit("reload");
        },

        processGroup(index){
            if(!this.result[index].value) return 

            let milhar = this.result[index].value
            let grupo = ("00" + (Math.ceil(milhar.slice(-2)/4))).slice(-2) 
            this.result[index].grupo = grupo == '00' ? '25' : grupo
        },

        getSixthAward() {
            if(!(this.result[0].value && this.result[1].value && this.result[2].value && this.result[3].value && this.result[4].value) || this.extraction.name == "UNIÃO 11:20") return

            var soma = parseInt(this.result[0].value) + parseInt(this.result[1].value) + parseInt(this.result[2].value) + parseInt(this.result[3].value) + parseInt(this.result[4].value)
            this.result[5].value = this.extraction.name.includes('UNIÃO') ? soma.toString().slice(-4) : soma.toString().slice(-3)
            this.processGroup(5)
        },

        getSeventhAward() {
            if(!(this.result[0].value && this.result[1].value)) return

            var multiplicacao = parseInt((parseInt(this.result[0].value) * parseInt(this.result[1].value)) / 1000)
            this.result[6].value = multiplicacao.toString().slice(-3)
            this.processGroup(6)
        },

        getEighthAward() {
            if(!(this.result[0].value)) return

            let dezena = this.result[0].value.substr(-2)

            if (parseInt(dezena.substr(-2)) % 25 == 0){
                this.result[7].value = '25'
            } else {
                this.result[7].value = ('00' + parseInt(dezena.substr(-2)) % 25).substr(-2)
            }

            //this.processGroup(7)
        },

        loadLotteries() {
            this.loading.lotteries = true;

            this.$api
                .get("/lotteries", {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                })
                .then(response => {
                    this.lotteries = response.data;
                    this.loading.lotteries = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar as loterias.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);

                    this.loading.lotteries = false;
                });
        },

        // carregando lista de extrações
        loadExtractions() {
            if(!this.lottery || !this.date_search) return

            this.loading.extractions = true;

            this.$api
                .get("/extraction", {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                    params: {
                        date_search: this.date_search,
                        lottery_search: this.lottery,
                        search_region: this.search_region,
                        launched: false
                    }
                })
                .then(response => {
                    this.list_extractions = response.data;
                    this.loading.extractions = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar as extrações.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);

                    this.loading.extractions = false;
                });
        },

        // carregando lista de extrações
        loadRegions() {
            this.loading.regions = true;

            this.$api
                .get("regions", {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    }
                })
                .then(response => {
                    this.regions = response.data;
                    this.loading.regions = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar as regiões.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);

                    this.loading.regions = false;
                });
        },

        resultConfirm() {
            if (this.$refs.formResult.validate()) {
                this.dialog_confirm = true;
            }
        },

        resetFields() {
            this.date_search = null
            this.extraction = null
            this.result = [{
                    value: "",
                    grupo: ""
                },
                {
                    value: "",
                    grupo: ""
                },
                {
                    value: "",
                    grupo: ""
                },
                {
                    value: "",
                    grupo: ""
                },
                {
                    value: "",
                    grupo: ""
                },
                {
                    value: "",
                    grupo: ""
                },
                {
                    value: "",
                    grupo: ""
                },
                {
                    value: "",
                    grupo: ""
                }
            ]
        }
    }
};
</script>
