<template>
<v-dialog v-model="show" max-width="800px">
    <v-card>
        <v-card-text class="pa-5 border-bottom">
            <h3 class="title blue-grey--text text--darken-2 font-weight-regular">
                Administradores da banca:
                <b v-text="bank.name"></b>.
            </h3>
            <h6 class="subtitle-2 font-weight-light" v-if="loggedUser().type == 'master'">Gerencie os administradores da banca, adicionando novos no campo de "Novo Administrador" ou removendo existentes no campo de "Remover".</h6>
            <h6 v-else class="subtitle-2 font-weight-light">Visualize mais detalhes sobre os administradores da banca <b v-text="bank.name"></b>.</h6>
        </v-card-text>

        <v-card-text class="pa-5 border-bottom">
            <!-- DATA-TABLE ADMINISTRATORS  -->
            <v-data-table :loading="loading" :items-per-page="admins != undefined ? admins.length : 12" no-data-text="Não há administradores." no-results-text="Sem resultados." hide-default-footer loading-text="Carregando..." disable-sort class="elevation-1" :headers="headers" :items="admins">
                <!-- DATA-TABLE HEADER -->
                <template v-slot:top v-if="loggedUser().type == 'master'">
                    <div class="pa-1">
                        <v-form v-model="valid" lazy-validation ref="formNewAdm">
                            <v-row class="ma-0 pa-0 justify-space-between mx-1">
                                <!-- SELECT NEW ADMINISTRATOR -->
                                <v-col cols="12" lg="10" md="10" sm="10" class="pa-1">
                                    <v-select hide-details :items="adms" v-model="new_adm_id" item-text="username" no-data-text="Sem dados disponível." item-value="id" label="Novo Administrador" :loading="loading_adm" :rules="[value => !!value || 'Selecione o administrador.']" outlined dense clearable></v-select>
                                </v-col>

                                <!-- ADD NEW ADMINISTRATOR  -->
                                <v-col cols="12" lg="2" md="2" sm="2" class="pa-1 text-right">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn height="40px" :disabled="!valid" v-bind="attrs" v-on="on" block color="primary" @click="bankNewAdm">
                                                <v-icon>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>
                                            <div>NOVO ADMINISTRADOR</div>
                                        </span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </v-form>
                    </div>
                    <v-divider></v-divider>
                </template>

                <!-- ICON REMOVE -->
                <template v-slot:item.remove="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn @click="bankRemoveAdm(item)" v-bind="attrs" v-on="on" color="red" icon>
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>
                            <div>REMOVER ADMINISTRADOR</div>
                        </span>
                    </v-tooltip>
                </template>

                <!-- DATE FORMAT -->
                <template v-slot:item.created_at="{ item }">
                    <span>{{ new Date(item.created_at).toLocaleDateString() }}</span>
                </template>
            </v-data-table>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="close">FECHAR</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
export default {
    name: "ModalBankAdmin",

    props: {
        bank: Object,
        admins: Array,
        dialog: Boolean,
    },

    data: () => ({
        // SNACKBAR
        snackbar: {},

        // DATA TABLE 
        headers: [{
                text: "DATA DE ATRIBUIÇÃO",
                value: "created_at",
                class: "primary white--text"
            },
            {
                text: "NOME",
                value: "username",
                class: "primary white--text"
            },
            {
                text: "PONTO DE VENDA",
                value: "point_name",
                class: "primary white--text"
            },
            {
                text: "REMOVER",
                value: "remove",
                sortable: false,
                align: "center",
                class: "primary white--text"
            }
        ],
        loading: false,
        adms: [],

        // DIALOG 
        new_adm_id: "",
        loading_adm: false,
        valid: false,
        show: false,
    }),

    created() {
        if (this.loggedUser().type !== 'master') {
            this.headers = [{
                    text: "DATA DE ATRIBUIÇÃO",
                    value: "created_at",
                    class: "primary white--text"
                },
                {
                    text: "NOME",
                    value: "username",
                    class: "primary white--text"
                },
                {
                    text: "PONTO DE VENDA",
                    value: "point_name",
                    class: "primary white--text"
                },
            ]

            return;
        }

        this.loadAdms();
    },

    watch: {
        // WATCH THE VALUE TO OPEN THE MODAL
        dialog: function (val) {
            this.show = val;
        },

        // IF USER CLICKS OUTSIDE THE DIALOG 
        show: function (val) {
            val || this.close();
        }
    },

    methods: {
        // CLOSE MODAL   
        close() {
            this.$emit('close');

            if (this.loggedUser().type == 'master') {
                this.$refs.formNewAdm.reset();
            }
        },

        // RELOAD PARENT
        reload() {
            this.$emit('reload');
        },

        // LOAD ADMINISTRATORS
        loadAdms() {
            this.loading_adm = true;

            this.$api
                .get("/users/list?type=adm", {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    }
                })
                .then(response => {
                    this.adms = response.data;
                    this.loading_adm = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar os administradores.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);

                    this.loading_adm = false;
                });
        },

        // NEW BANK ADMIN
        bankNewAdm() {
            if (this.$refs.formNewAdm.validate()) {
                this.loading = true;

                this.$api
                    .get(
                        `bank/${this.bank.id}/add-manager/${this.new_adm_id}`, {
                            headers: {
                                Authorization: `Bearer ${this.accessToken()}`
                            }
                        }
                    )
                    .then(() => {
                        this.snackbar.text = "Administrador adicionado com sucesso!";
                        this.snackbar.type = "green";
                        this.snackbarShow(this.snackbar);

                        this.loading = false;
                        this.close();
                        this.reload();
                    })
                    .catch(() => {
                        this.snackbar.text = "Erro na atribuição do administrador!";
                        this.snackbar.type = "error";
                        this.snackbarShow(this.snackbar);

                        this.loading = false;
                    });
            }
        },

        // REMOVE BANK ADMIN
        bankRemoveAdm(item) {
            this.loading = true;

            this.$api
                .get(`bank/${this.bank.id}/remove-manager/${item.id}`, {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    }
                })
                .then(() => {
                    this.snackbar.text = "Administrador removido com sucesso!";
                    this.snackbar.type = "green";
                    this.snackbarShow(this.snackbar);

                    this.loading = false;
                    this.close();
                    this.reload();
                })
                .catch(() => {
                    this.snackbar.text = "Erro ao remover o administrador!";
                    this.snackbar.type = "error";
                    this.snackbarShow(this.snackbar);

                    this.loading = false;
                });
        },
    }
}
</script>
