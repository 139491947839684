<template>
<v-dialog v-model="show" max-width="800px">
    <v-card>
        <!-- TEXT CHANGE IF IS REGISTER OR EDIT -->
        <v-card-text v-if="register == true" class="pa-5 border-bottom">
            <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Cadastro de nova banca.</h3>
            <h6 class="subtitle-2 font-weight-light">Preencha todas os campos abaixo e confirme o cadastro de uma nova banca no sistema.</h6>
        </v-card-text>

        <v-card-text v-else class="pa-5 border-bottom">
            <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Cadastro da banca: <b v-text="bank.name"></b>.</h3>
            <h6 class="subtitle-2 font-weight-light">Preencha todas os campos abaixo e confirme a edição do cadastro da banca existente no sistema.</h6>
        </v-card-text>

        <!-- FORM -->
        <v-card-text class="pa-5 border-bottom">
            <v-form v-model="valid" lazy-validation ref="formBank">
                <v-row>
                    <v-col cols="6" lg="6" md="6" sm="12" xs="12" class="py-0 my-0">
                        <v-text-field outlined clearable :rules="[value => !!value || 'Informe o nome da banca.']" v-model="bank.name" label="Nome da banca"></v-text-field>
                    </v-col>

                    <v-col cols="12" xs="12" class="py-0 my-0">
                        <v-checkbox required v-model="checkbox" :rules="[value => !!value || 'É necessário confirmar.']" label="Confirmar banca?" class="ma-0" color="green"></v-checkbox>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>

        <!-- ACTIONS CHANGE IF IS REGISTER OR EDIT -->
        <v-card-actions v-if="register == true">
            <v-btn color="red darken-1" text @click="close">CANCELAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" :disabled="!valid" text @click="bankSave">SALVAR NOVA BANCA</v-btn>
        </v-card-actions>

        <v-card-actions v-else>
            <v-btn color="red darken-1" text @click="close">CANCELAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" :disabled="!valid" text @click="bankEdit">EDITAR BANCA</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
export default {
    name: "ModalBankNewEdit",

    props: {
        bank: Object,
        edit: Boolean,
        register: Boolean,
        dialog: Boolean,
    },

    data: () => ({
        // SNACKBAR
        snackbar: {},

        // DIALOG
        valid: false,
        checkbox: false,
        show: false,
    }),

    watch: {
        // WATCH THE VALUE TO OPEN THE MODAL
        dialog: function (val) {
            this.show = val;
        },

        // IF USER CLICKS OUTSIDE THE DIALOG 
        show: function (val) {
            val || this.close();
        }
    },

    methods: {
        // CLOSE MODAL   
        close() {
            this.$refs.formBank.reset();
            this.$emit('close');
        },

        // RELOAD PARENT
        reload() {
            this.$emit('reload');
        },

        // EDIT BANK
        bankEdit() {
            if (this.$refs.formBank.validate()) {
                this.$api
                    .patch(`/bank/${this.bank.id}`, this.bank, {
                        headers: {
                            Authorization: `Bearer ${this.accessToken()}`
                        }
                    })
                    .then(() => {
                        this.snackbar.text = "Banca atualizada com sucesso!";
                        this.snackbar.type = "green";
                        this.snackbarShow(this.snackbar);

                        this.close();
                        this.reload();
                    })
                    .catch(() => {
                        this.snackbar.text = "Erro na atualização da banca!";
                        this.snackbar.type = "error";
                        this.snackbarShow(this.snackbar);

                        this.table.loading = false;
                    });
            }
        },

        // NEW BANK
        bankSave() {
            if (this.$refs.formBank.validate()) {
                this.$api
                    .post("/bank", this.bank, {
                        headers: {
                            Authorization: `Bearer ${this.accessToken()}`
                        }
                    })
                    .then(() => {
                        this.snackbar.text = "Banca cadastrada com sucesso!";
                        this.snackbar.type = "green";
                        this.snackbarShow(this.snackbar);

                        this.close();
                        this.reload();
                    })
                    .catch(() => {
                        this.snackbar.text = "Erro no cadastro da banca!";
                        this.snackbar.type = "error";
                        this.snackbarShow(this.snackbar);

                    });
            }
        },
    }
}
</script>
