<template>
<v-dialog v-model="show" max-width="800px">
    <v-card>
        <!-- TEXT CHANGE IF IS NEW OR EDIT -->
        <v-card-text v-if="register == true" class="pa-5 border-bottom">
            <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Cadastro de novo ponto de venda.</h3>
            <h6 class="subtitle-2 font-weight-light">Preencha todas os campos abaixo e confirme o cadastro de um novo ponto de venda.</h6>
        </v-card-text>

        <v-card-text v-else class="pa-5 border-bottom">
            <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Cadastro do ponto de venda: <b v-text="point.name"></b>.</h3>
            <h6 class="subtitle-2 font-weight-light">Preencha todas os campos abaixo e confirme a edição do cadastro do ponto de venda existente no sistema.</h6>
        </v-card-text>

        <v-card-text class="pa-5 border-bottom">
            <v-form v-model="valid" lazy-validation ref="formPoint">
                <v-row>
                    <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="py-0 my-0">
                        <v-text-field dense outlined clearable :rules="[value => !!value || 'Informe o nome do ponto de venda.']" v-model="point.name" label="Nome do ponto de venda"></v-text-field>
                    </v-col>
                  
                    <v-col cols="12" lg="6" md="6" sm="12" xs="12" class="py-0 my-0">
                        <v-autocomplete dense clearable :rules="[value => !!value || 'Informe a seção.']" no-data-text="Sem dados disponível." @change="changeSession" :loading="loading.sessions" :disabled="loading.sessions" v-model="point.session_id" label="Seção" color="primary" item-text="name" item-value="id" outlined :items="sessions"/>
                    </v-col>

                    <v-col cols="12" lg="6" md="6" sm="12" xs="12" class="py-0 my-0">
                        <v-autocomplete dense clearable :rules="[value => !!value || 'Informe o operador.']" no-data-text="Sem dados disponível." :loading="loading.operators" :disabled="loading.operators" v-model="point.operator_id" label="Operador" color="primary" item-text="username" item-value="id" outlined :items="operators"/>
                    </v-col>

                    <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="py-0 my-0">
                        <v-autocomplete dense clearable :rules="[value => !!value || 'Informe o tipo.']" no-data-text="Sem dados disponível." v-model="point.type" label="Tipo de ponto" color="primary" outlined :items="types"/>
                    </v-col>

                    <v-col v-if="point.type == 'Descarga'" cols="12" lg="12" md="12" sm="12" xs="12" class="py-0 my-0">
                        <v-autocomplete dense clearable :rules="[value => !!value || 'Informe a região de envio.']" no-data-text="Sem dados disponível." v-model="point.sender_region" label="Região de envio" color="primary" item-text="name" item-value="id" outlined :items="sender_regions"/>
                    </v-col>

                    <v-col v-if="point.type == 'Descarga' && point.sender_region == 999999" cols="12" lg="12" md="12" sm="12" xs="12" class="py-0 my-0">
                        <v-text-field dense label="Comissão de descarga" v-model="point.discharge_comission" :rules="[value => !!value || 'Informe a comissão de descarga']" outlined clearable></v-text-field>
                    </v-col>

                    <v-col cols="12" xs="12" class="py-0 my-0">
                        <v-checkbox required v-model="checkbox" :rules="[value => !!value || 'É necessário confirmar.']" label="Confirmar ponto de venda?" class="ma-0" color="green"></v-checkbox>
                    </v-col>

                </v-row>
            </v-form>
        </v-card-text>

        <!-- ACTIONS CHANGE IF IS NEW OR EDIT -->
        <v-card-actions v-if="register == true">
            <v-btn color="red darken-1" text @click="close">CANCELAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" :disabled="!valid" text @click="pointSave">NOVO PONTO DE VENDA</v-btn>
        </v-card-actions>

        <v-card-actions v-else>
            <v-btn color="red darken-1" text @click="close">CANCELAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" :disabled="!valid" text @click="pointEdit">EDITAR PONTO DE VENDA</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
export default {
    name: "ModalPointNewEdit",

    props: {
        point: Object,
        edit: Boolean,
        register: Boolean,
        dialog: Boolean,
    },

    data: () => ({
        // SNACKBAR
        snackbar: {},
        
        // COMPONENT
        operators: [],
        sessions: [],
        types: ['Normal', 'Descarga'],
        sender_regions: [],
       
        loading: {
            operators: false,
            sessions: false,
        },

        // DIALOG 
        valid: false,
        checkbox: false,
        show: false,
    }),

     watch: {
        // WATCH THE VALUE TO OPEN THE MODAL
        dialog: function (val) {
            this.show = val;
        },

        edit: function () {
            if(this.edit == true) {
                this.loadOperators();
            }
        },

        // IF USER CLICKS OUTSIDE THE DIALOG 
        show: function (val) {
            val || this.close();
        }
    },

    computed: {
        // FORMAT DATE
        formatNewDate: {
            get() {
                return this.formatDate(this.ex_date);
            },

            set() {
                this.ex_date = null;
            }
        },
    },

    created() {
        this.loadSessions();
        this.loadSenderRegions();
        //this.loadOperators();
    },

    methods: {
        // CLOSE MODAL   
        close() {
            this.$emit('close');
            this.$refs.formPoint.reset();
        },

        // RELOAD PARENT
        reload() {
            this.close();
            this.$emit('reload');
        },

        changeSession (){
            this.loadOperators();
        },

        loadOperators() {
            this.loading.operators = true;

            this.$api
                .get("/users/operators", {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                    params: {
                        search_session: [this.point.session_id]
                    }
                })
                .then((response) => {
                    this.operators = response.data;
                    this.loading.operators = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar os operadores.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);

                    this.loading.operators = false;
                });
        },

        loadSessions() {
            this.loading.sessions = true;

            this.$api
                .get("/sessions", {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                })
                .then((response) => {
                    this.sessions = response.data;
                    this.loading.sessions = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar os operadores.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);

                    this.loading.sessions = false;
                });
        },

        loadSenderRegions() {
            this.loading.sender_regions = true;

            this.$api
                .get("/regions/sender_regions", {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                })
                .then((response) => {
                    this.sender_regions = response.data;
                    this.sender_regions.unshift({id: 999999, name: 'REGIÃO NÃO CADASTRADA'})
                    this.loading.sender_regions = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar as regiões.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);

                    this.loading.sender_regions = false;
                });
        },

        // EDIT SELLER POINT
        pointEdit() {
            if (this.$refs.formPoint.validate()) {
                this.$api
                    .put(`/points/${this.point.id}`, this.point, {
                        headers: {
                            Authorization: `Bearer ${this.accessToken()}`
                        }
                    })
                    .then(() => {
                        this.snackbar.text = "Ponto de venda atualizada com sucesso!";
                        this.snackbar.type = "green";
                        this.snackbarShow(this.snackbar);

                        this.reload();
                    })
                    .catch(() => {
                        this.snackbar.text = "Erro ao atualizar o ponto de venda!";
                        this.snackbar.type = "error";
                        this.snackbarShow(this.snackbar);
                    });
            }
        },

        // NEW SELLER POINT
        pointSave() {
            if (this.$refs.formPoint.validate()) {

                this.$api
                    .post("/points", this.point, {
                        headers: {
                            Authorization: `Bearer ${this.accessToken()}`
                        }
                    })
                    .then(() => {
                        this.snackbar.text = "Ponto de venda cadastrado com sucesso!";
                        this.snackbar.type = "green";
                        this.snackbarShow(this.snackbar);

                        this.reload();
                    })
                    .catch(() => {
                        this.snackbar.text = "Erro ao cadastrar novo ponto de venda!";
                        this.snackbar.type = "error";
                        this.snackbarShow(this.snackbar);
                    });
            }
        },
    }
}
</script>
