<template>
<v-dialog v-model="show" max-width="800px">
    <v-card>
        <!-- TEXT CHANGE IF IS NEW OR EDIT -->
        <v-card-text class="pa-5 border-bottom">
            <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Cadastrar nova descarga.</h3>
            <h6 class="subtitle-2 font-weight-light">Preencha todas os campos abaixo e confirme a criação de um novo bilhete de descarga.</h6>
        </v-card-text>

        <v-card-text class="pa-8 border-bottom">
            <v-form v-model="valid" lazy-validation ref="formDischarge">

                <div class="row">
                    <v-select dense no-data-text="Sem dados disponíveis." outlined :rules="[value => !!value || 'Selecione uma modalidade']" :loading="loading.modalities" :disabled="loading.modalities" item-text="name" return-object v-model="selectedModality" :items="modalities" label="Modalidade" @change="changeModality()"></v-select>
                </div> 

                <div class="row">
                    <v-select dense no-data-text="Sem dados disponíveis." outlined :rules="[value => !!value || 'Selecione um prêmio']" :loading="loading.awards" :disabled="loading.awards" item-text="name" return-object v-model="selectedAward" :items="awards" label="Prêmio"></v-select>
                </div>

                <div class="row">
                    <v-text-field @input="handleInputPalpite" v-model="number" :disabled="selectedModality ? false : true" dense  outlined :rules="rules" label="Palpite"></v-text-field>
                    <v-btn style="height: 40px; width: 100px; margin-left: 15px;" color="primary" @click="handleAddPalpite">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </div>

                <div class="row">
                    
                    <v-chip v-for="(number, index) in selectedNumbers" :key="index"
                        class="ma-2"
                        close
                        color="blue"
                        text-color="white"
                        @click:close="handleRemoveNumber(index)"
                        >
                        {{number}}
                    </v-chip>

                    <vuetify-money style="width: 100%; margin-top: 15px;" label="Valor" v-model="value" :outlined="true" dense  />

                </div>

                <div class="row">
                    <v-btn height="40px" block color="primary" @click="handleAddBet">
                        Adicionar palpite
                    </v-btn>
                </div>
                
            </v-form>

        </v-card-text>

        <v-card-text class="pa-8 border-bottom">
            
            <div class="row">
                <v-autocomplete dense clearable :rules="[value => !!value || 'Informe a região de descarga']" no-data-text="Sem dados disponível." :loading="loading.regions" :disabled="loading.regions" v-model="receiving_region" label="Região de descarga" color="primary" item-text="receiving_region_name" item-value="receiving_region" outlined :items="regions"/>
            </div>

            <div class="row">
                <DataTable style="width: 100% !important;" :value="palpites" editMode="cell" @cell-edit-complete="onCellEditComplete" class="editable-cells-table p-datatable-sm" responsiveLayout="scroll">
                    <Column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field" :footer="sumField(col.field)">
                        <template #editor="{ data, field }">
                            <InputNumber v-if="field == 'excess'" v-model="data[field]" mode="decimal" :minFractionDigits="2" />
                        </template>
                    </Column>
                </DataTable>
            </div>
            
        </v-card-text>


        <!-- ACTIONS CHANGE IF IS NEW OR EDIT -->
        <v-card-actions >
            <v-btn color="red darken-1" text @click="close">CANCELAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dischargeSave">NOVA DESCARGA</v-btn>
        </v-card-actions>

    </v-card>
</v-dialog>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputNumber from 'primevue/inputnumber';

export default {
    name: "ModalDischargeNew",

    components: {
        DataTable,
        Column, 
        InputNumber
    },

    props: {
        region_id: Number,
        extraction_id: Number,
        dialog: Boolean,
    },

    data: () => ({
        // SNACKBAR
        snackbar: {},
        
        modalities: [],
        awards: [],
        regions: [],
        
        selectedModality: null,
        selectedAward: null,
        receiving_region: null,
        number: '',
        value: '',

        selectedNumbers: [],
        palpites: [],

        rule: { maximoDigitos: 0, minimoDigitos: 0, numeroMaximo: 0 },
        mask: [],

        loading: {
            modalities: false,
            awards: false,
            regions: false,
        },

        // DIALOG 
        valid: false,
        checkbox: false,
        show: false,

        columns: [
            {field: 'modality_code', header: 'Modalidade'},
            {field: 'number', header: 'Palpite'},
            {field: 'excess', header: 'Valor'},
        ],

        rules: [
            value => !!value || "Informe o palpite",
        ],
    }),

    watch: {
        // WATCH THE VALUE TO OPEN THE MODAL
        dialog: function (val) {
            if(val) {
                this.loadModalities();
                this.loadDischargeRegions();
            }
            this.show = val;
        },

        // IF USER CLICKS OUTSIDE THE DIALOG 
        show: function (val) {
            val || this.close();
        },
    },

    computed: {
      
    },

    created() { 
    },
    
    methods: {

        // CLOSE MODAL   
        close() {
            this.resetFields();
            this.$emit('close');
            this.$refs.formDischarge.reset();
        },

        // RELOAD PARENT
        reload() {
            this.close();
            this.$emit('reload');
        },

        onCellEditComplete (event) {
            let { data, newValue, field } = event;

            switch (field) {
                case 'excess':
                    data[field] = newValue; 
                break;

            }
        },

        sumField(key) {
            // sum data in give key (property)
            if(key == 'excess') {
                let total = this.palpites.reduce((a, b) => parseFloat(a) + parseFloat((b[key] || 0)), 0)
                return total
            } else {
                return ''
            }
        },

        // LOAD MODALITIES
        loadModalities() {
            this.loading.modalities = true;
             
            this.$api
                .get(`/modalities`, {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                    params: {
                       region_id: this.region_id,
                       extraction_id: this.extraction_id
                    }
                })
                .then((response) => {
                    this.modalities = response.data;
                    this.loading.modalities = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar as modalidades.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);

                    this.loading.modalities = false;
                });

                
        },

        loadDischargeRegions() {
            this.loading.regions = true;

            this.$api
            .get("dischargeRegions", {
                headers: {
                    Authorization: `Bearer ${this.accessToken()}`
                },
                params: {
                    search_region: this.region_id,
                }
            })
            .then(response => {
                this.regions = response.data;
                this.loading.regions = false;
            })
            .catch(() => {
                this.loading.regions = false;
            });
 
        },

        changeModality() {
            this.loadAwards();
            this.getRule();
            this.selectedAward = null;
            this.selectedNumbers = [];
        },

        // LOAD AWARDS
        loadAwards() {
            
            this.$api
                .get(`/awards/awardsByModality`, {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                    params: {
                        modality_id: this.selectedModality.id
                    }
                })
                .then((response) => {
                    this.awards = response.data;
                    this.loading.awards = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar os prêmios.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);

                    this.loading.awards = false;
                });

        },

        resetFields() {
           this.selectedAward = null;
           this.selectedModality = null; 
           this.receiving_region = null;
           this.selectedNumbers = [];
           this.value = '';
           this.palpites = [];
        },

        getRule() {
            switch (this.selectedModality.initials) {
                case 'grupo':
                    this.rule = { maximoDigitos: 2, minimoDigitos: 2, numeroMaximo: 25}
                    break;

                case 'duque_grupo':
                    this.rule = { maximoDigitos: 99, minimoDigitos: 5, numeroMaximo: 25};
                    break;

               case 'terno_grupo':
                    this.rule = { maximoDigitos: 99, minimoDigitos: 7, numeroMaximo: 25};
                    break;

                case 'dezena':
                    this.rule = { maximoDigitos: 2, minimoDigitos: 2, numeroMaximo: 99};
                    break;

                case 'dezena_invertida':
                    this.rule = { maximoDigitos: 2, minimoDigitos: 2, numeroMaximo: 99};
                    break;

                case 'duque_dezena':
                    this.rule = { maximoDigitos: 99, minimoDigitos: 5, numeroMaximo: 99 };
                    break;

                case 'terno_dezena':
                    this.rule = { maximoDigitos: 99, minimoDigitos: 7, numeroMaximo: 99 };
                    break;

                case 'centena':
                    this.rule = { maximoDigitos: 3, minimoDigitos: 3, numeroMaximo: 999 };
                    break;

                case 'centena_invertida':
                    this.rule = { maximoDigitos: 3, minimoDigitos: 3, numeroMaximo: 999 };
                    break;

                case 'milhar':
                    this.rule = { maximoDigitos: 4, minimoDigitos: 4, numeroMaximo: 9999 };
                    break;

                case 'milhar_invertida':
                    this.rule = { maximoDigitos: 4, minimoDigitos: 4, numeroMaximo: 9999 };
                    break;
                
                case 'milhar_centena':
                    this.rule = { maximoDigitos: 4, minimoDigitos: 4, numeroMaximo: 9999 };
                    break;

                case 'milhar_centena_invertida':
                    this.rule = { maximoDigitos: 4, minimoDigitos: 4, numeroMaximo: 9999 };
                    break;

                case 'esquerda_meia_direita':
                    this.rule = { maximoDigitos: 99, minimoDigitos: 5, numeroMaximo: 99 };
                    break;

                case 'passe_ida':
                    this.rule = { maximoDigitos: 99, minimoDigitos: 5, numeroMaximo: 25 };
                    break;

                case 'passe_ida_volta':
                    this.rule = { maximoDigitos: 99, minimoDigitos: 5, numeroMaximo: 25 };
                    break;

                case 'milhar_vai_volta':
                    this.rule = { maximoDigitos: 4, minimoDigitos: 4, numeroMaximo: 9999 };
                    break;

                case 'milhar_aproximada':
                    this.rule = { maximoDigitos: 4, minimoDigitos: 4, numeroMaximo: 9999 };
                    break;

                case 'centena_vai_volta':
                    this.rule = { maximoDigitos: 3, minimoDigitos: 3, numeroMaximo: 999 };
                    break;

                case 'centena_aproximada':
                    this.rule = { maximoDigitos: 3, minimoDigitos: 3, numeroMaximo: 999 };
                    break;
            
                default:
                    this.rule = { maximoDigitos: 0, minimoDigitos: 0, numeroMaximo: 0 };
                    break;

           }
        },


        handleInputPalpite() { 
            if(!this.selectedModality) return 

			this.number = this.number.replace(/[^0-9]/g, ""); // remove os caracteres que não são 1-9

			switch (this.selectedModality.initials) {
				case 'grupo':
					this.number = this.number.replace(/([2][6-9])|([3-9][0-9])/g, "") // limpando grupos 25 +				
					break;

				case 'terno_grupo':
				case 'duque_grupo':
				case 'passe_ida':
				case 'passe_ida_volta':
				case 'passe_especial':
				case 'passe_especial_combinado':
					if(this.number.length % 2 === 0) {
						this.number = this.number.match(/([0-1][0-9])|([2][0-5])/g).join('-') // formata a string
					}
				break;

				case 'terno_dezena':
				case 'duque_dezena':
				case 'esquerda_meia_direita':
					if(this.number.length % 2 === 0) {
						this.number = this.number.match(/\d{2}/g).join('-') // formata a string
					}
				break;
			
				default:
					break;
			}


			if (this.number.length >= this.rule.minimoDigitos) {
				this.handleAddPalpite()
			}
    	}, 

        handleAddPalpite() {

            if (this.number.length >= this.rule.minimoDigitos) {

                switch (this.selectedModality.initials) {
                    case 'terno_grupo':
                    case 'duque_grupo':
                    case 'passe_ida':
                    case 'passe_ida_volta':
                    case 'passe_especial':
                    case 'passe_especial_combinado':
                        this.number = this.number.match(/([0-1][0-9])|([2][0-5])/g).join('-') // formata a string
                    break;

                    case 'terno_dezena':
                    case 'duque_dezena':
                    case 'esquerda_meia_direita':
                        this.number = this.number.match(/\d{2}/g).join('-') // formata a string
                    break;
                
                    default:
                        break;
                }

                
                this.selectedNumbers.push(this.number)
                this.number = ''
            }
        },

   
    
        handleRemoveNumber (index) {
            this.selectedNumbers = this.selectedNumbers.filter(number => number != this.selectedNumbers[index]);
        },

        handleAddBet() { // validação de formulário  

            if(this.selectedModality == null || this.selectedAward == null || this.selectedNumbers.length == 0 ) {
                this.snackbar.text = "Um ou mais campos não foram preenchidos.";
                this.snackbar.type = "warning";
                this.snackbarShow(this.snackbar);
            }            

            for (let i = 0; i < this.selectedNumbers.length; i++) {

                var ticket = {
                    sender_region: this.region_id,
                    receiving_region: this.receiving_region,
                    extraction_id: this.extraction_id,
                    modality_id: this.selectedModality.id,
                    modality_code: this.selectedModality.initials,
                    award: this.selectedAward.internal_name,
                    number: [this.selectedNumbers[i]],
                    accumulated: parseFloat(this.value),
                    excess: parseFloat(this.value),
                    expected_value: parseFloat(this.value) * parseFloat(this.selectedModality.default_cotation),
                    excess_award: parseFloat(this.value) * parseFloat(this.selectedModality.default_cotation),
                    position: ['Direita']
                }

                this.palpites.push(ticket)

            }
            
            this.selectedNumbers = [];
            this.value = '';

        },

        // NEW SELLER AWARD
        dischargeSave() {

            if(this.palpites.length == 0) {
                this.snackbar.text = "Nenhum palpite adicionado.";
                this.snackbar.type = "warning";
                this.snackbarShow(this.snackbar);
                return;
            } 
            
            if(this.receiving_region == null) {
                this.snackbar.text = "Selecione a região de descarga.";
                this.snackbar.type = "warning";
                this.snackbarShow(this.snackbar);
                return;
            } 

            this.$api
            .post('discharges/', { palpites: this.palpites, receiving_region: this.receiving_region }, {
                headers: {
                    Authorization: `Bearer ${this.accessToken()}`
                },
            })
            .then(() => {
                this.snackbar.text = "Descarga criada com sucesso!";
                this.snackbar.type = "green";
                this.snackbarShow(this.snackbar);
            })
            .catch(() => {
                this.snackbar.text = "Erro ao cadastrar nova descarga!";
                this.snackbar.type = "error";
                this.snackbarShow(this.snackbar);
            });
    
            this.reload();
             
        },
    }
}
</script>
