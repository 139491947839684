<template>
<v-dialog v-model="show" max-width="800px">
    <v-card>
        <v-card-text class="pa-5 border-bottom">
            <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Cadastro de nova aposta cotada.</h3>
            <h6 class="subtitle-2 font-weight-light">Preencha todos os campos abaixo e confirme o cadastro de uma nova aposta cotada.</h6>
        </v-card-text>

        <v-card-text class="pa-5 border-bottom">
            <v-form v-model="valid" lazy-validation ref="formQuotedBet">
                
                <v-row class="ma-0 pa-0 justify-space-between">
                    <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="py-0 my-0">
                        <v-autocomplete dense clearable :rules="[value => !!value || 'Informe loteria.']"  no-data-text="Sem dados disponível." :loading="loading.lotteries" :disabled="loading.lotteries" v-model="quotedBet.lottery_id" label="Loteria" color="primary" item-text="name" item-value="id" outlined :items="lotteries"/>
                    </v-col>

                    <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="py-0 my-0">
                        <v-autocomplete dense clearable :rules="[value => !!value || 'Informe a modalidade.']"  no-data-text="Sem dados disponível." v-model="quotedBet.modality" label="Modalidade"  item-text="name" color="primary" outlined :items="modalities"/>
                    </v-col>

                    <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="py-0 my-0">
                        <v-autocomplete multiple dense clearable :rules="[value => !!value || 'Informe a região.']"  no-data-text="Sem dados disponível." :loading="loading.regions" :disabled="loading.regions" v-model="quotedBet.regions" label="Região" color="primary" item-text="name" item-value="id" outlined :items="regions"/>
                    </v-col>

                  
                   <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="py-0 my-0">
                        <v-textarea
                            outlined
                            label="Numero(s)"
                            v-model="quotedBet.number" 
                            @input="formatNumber"
                        />
                    </v-col>

                    <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="py-0 my-0">
                        <v-text-field dense label="Cotação" v-model="quotedBet.cotation" :rules="[value => !!value || 'Informe o valor da cotação']" outlined clearable></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="6" md="6" sm="6" xs="12" class="py-0 my-0">
                        <v-menu :close-on-content-click="true" transition="scale-transition" min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field dense v-on="on" v-bind="attrs" readonly type="date" outlined :rules="[value => !!value || 'Informe a data de início.']" v-model="quotedBet.start_date" label="Data de início"></v-text-field>
                            </template>
                            <v-date-picker color="primary" v-model="quotedBet.start_date" locale="pt-br"></v-date-picker>
                        </v-menu>
                    </v-col>

                    <v-col cols="12" lg="6" md="6" sm="6" xs="12" class="py-0 my-0">
                        <v-menu :close-on-content-click="true" transition="scale-transition" min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field dense v-on="on" v-bind="attrs" readonly type="date" outlined :rules="[value => !!value || 'Informe a data de fim.']" v-model="quotedBet.end_date" label="Data de fim"></v-text-field>
                            </template>
                            <v-date-picker color="primary" v-model="quotedBet.end_date" locale="pt-br"></v-date-picker>
                        </v-menu>
                    </v-col> 

                </v-row>

            </v-form>
        </v-card-text>

        <v-card-actions v-if="register == true">
            <v-btn color="red darken-1" text @click="close">CANCELAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" :disabled="!valid" text @click="quotedBetSave">NOVO APOSTA COTADA</v-btn>
        </v-card-actions>

    </v-card>
</v-dialog>
</template>


<script>
export default {
    name: "ModalQuotedBetNewEdit",

    props: {
        quotedBet: Object,
        dialog: Boolean,
        register: Boolean,
    },

    data: () => ({
        // SNACKBAR
        snackbar: {},

        modalities: ['Grupo', 'Duque de Grupo', 'Terno de grupo', 'Dezena', 'Dezena Invertida', 'Duque de Dezena', 'Terno de Dezena', 'Centena', 'Centena Invertida', 'Milhar', 'Milhar Invertida', 'Milhar e centena', 'Milhar e centena Invertida', 'Esquerda Meia Direita'],
        regions: [],
        lotteries: [],

        loading: {
           lotteries: false,
           modalities: false,
           regions: false
        },

        number_rules: [
            value => !!value || "Informe o número.",
            value => !isNaN(value) || "Informe somente números.",
        ],
        
        // DIALOG
        valid: false,
        checkbox: false,
        show: false,

    }),

    watch: {
        // WATCH THE VALUE TO OPEN THE MODAL
        dialog: function (val) {
            this.show = val;
        },

        // IF USER CLICKS OUTSIDE THE DIALOG
        show: function (val) {
            val || this.close();
        },

    },

    created() {
        this.loadLotteries();
        this.loadRegions();
    },

    methods: {
        // CLOSE MODAL
        close() {
            this.$emit("close");
            this.$refs.formQuotedBet.reset();
        },

        formatNumber() {

            if(this.quotedBet.modality && this.quotedBet.number) {

                switch (this.quotedBet.modality) {
                    case 'Milhar e centena':
                        this.quotedBet.number = this.quotedBet.number.match(/\d{4}|\d+$/g).join('-')
                        break;
                    case 'Milhar':
                        this.quotedBet.number = this.quotedBet.number.match(/\d{4}|\d+$/g).join('-')
                        break;
                    case 'Centena':
                        this.quotedBet.number = this.quotedBet.number.match(/\d{3}|\d+$/g).join('-')
                        break;
                    case 'Dezena':
                        this.quotedBet.number = this.quotedBet.number.match(/\d{2}|\d+$/g).join('-')
                        break;
                
                    default:
                        break;
                } 

            }
            
        },

        // RELOAD PARENT
        reload() {
            this.close();
            this.$emit('reload');
        },
   
        loadRegions() {
            this.loading.regions = true;

            this.$api
                .get("/regions", {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                })
                .then(response => {
                    this.regions = response.data;
                    this.loading.regions = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar as regiões.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);

                    this.loading.regions = false;
                });

        },

        loadLotteries() {
            this.loading.lotteries = true;

            this.$api
                .get("/lotteries", {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                })
                .then(response => {
                    this.lotteries = response.data;
                    this.loading.lotteries = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar as loterias.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);
                    this.loading.lotteries = false;
                });

        },

       // NEW REGION
        quotedBetSave() {
          
            if (this.$refs.formQuotedBet.validate()) {

                this.$api
                .post("/quoted_bets", this.quotedBet, {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    }
                })
                .then(() => {
                    this.snackbar.text = "Aposta cotada cadastrada com sucesso!";
                    this.snackbar.type = "green";
                    this.snackbarShow(this.snackbar);
                    this.reload();
                })
                .catch(() => {
                    this.snackbar.text = "Erro ao cadastrar nova aposta cotada!";
                    this.snackbar.type = "error";
                    this.snackbarShow(this.snackbar);
                });


            }
        },
    }
};
</script>
