<template>
    <div>
        <v-dialog v-model="show" max-width="650px">
        	<div class="header-modal-message">
            	<div class="total-messages">
                    TOTAL DE MENSAGENS ENVIADAS: 
                    {{ messages.length }}
                </div>

                <div>
                    <div class="message-read">
                        MENSAGENS LIDAS: 
                        {{ messages.filter(x => x.read == true).length }}
                    </div>
    
                    <div class="message-not-read">
                        MENSAGENS NÃO LIDAS: 
                        {{ messages.filter(x => x.read == false).length }}
                    </div>

                    <div class="input">
                        <span>SEÇÕES:</span>
                        <MultiSelect v-if="!this.loading.sessions" v-model="selectedSessions" @change="sessionChange" :options="sessions" optionValue="id" optionLabel="name" placeholder="Selecione as Seções" />
                        <Skeleton v-else width="100%" height="48px" />
                    </div>
                </div>
            </div>

            <DataTable :value="messages" class="table-primevue" :lazy="true" :loading="loading.table">
                <template #loading>
                    Carregando usuários...
                </template>
                <Column field="name" header="NOME"></Column>
                <Column field="read" header="STATUS">
                    <template #body="message">
                        <div v-if="message.data.read">
                            LIDA
                        </div>
                        <div v-else>
                            NÃO LIDA
                        </div>
                    </template>
                </Column>
            </DataTable>
        </v-dialog>
    </div>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import MultiSelect from 'primevue/multiselect';
import Skeleton from 'primevue/skeleton';

export default {
    components: {
        DataTable,
        Column,
        MultiSelect,
        Skeleton
    },
    
    props: [
        'dialog_user',
        'message'
    ],

    data:() => ({
        show: false,
        messages: [],
        sessions: [],

        selectedSessions: null,

        loading: {
            table: false,
            sessions: false
        }
    }),

    created() {
    },

    methods: {
        loadMessagesEachUsers() {
            this.loading.table = true;

            this.$api.get('/messages/show', {
                headers: {
                    Authorization: `Bearer ${this.accessToken()}`
                },
                params: {
                   message_code: this.message,
                   sessions: this.selectedSessions
                }
            })
            .then((response) => {
                this.messages = response.data;
                this.loading.table = false;
            })
            .catch(() => {
                this.loading.table = false;
            })
        },

        loadSessions() {
            this.loading.sessions = true;

            this.$api.get("/sessions", {
                headers: {
                    Authorization: `Bearer ${this.accessToken()}`
                },
                params: {
                    search_region: this.selectedRegions
                }
            })
            .then((response) => {
                this.sessions = response.data;
                this.loading.sessions = false;
            })
            .catch(() => {
                this.snackbar.text = "Não foi possível carregar as sessões.";
                this.snackbar.type = "warning";
                this.snackbarShow(this.snackbar);
                this.loading.sessions = false;
            })
        },

        sessionChange() {
            this.loadMessagesEachUsers();
        },

        close() {
            this.$emit('close');
        },

        reload() {
            this.close();
            this.$emit('reload');
        },
    },

    watch: {
        // WATCH THE VALUE TO OPEN THE MODAL
        dialog_user: function (val) {
            if (val) {
            }
            this.show = val;
        },

        // IF USER CLICKS OUTSIDE THE DIALOG 
        show: function (val) {
            val || this.close();
        },

        message: function () {
            this.loadMessagesEachUsers();
            this.loadSessions();
        }
    },
}

</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
    background-color: #fff;
    padding: 30px;
    min-height: 70vh;
}

.header-modal-message {
    font-weight: bold;
    border-radius: 5px;
    padding: 10px 50px;
    margin-bottom: 10px;
    border: 5px solid #0089ff;

    .total-messages {
        background: #0089ff;
        text-align: center;
        margin-top: -28px;
        border-radius: 15px;
        padding: 4px;
        color: #fff;
    }

    .input {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .message-not-read,
    .message-read {
        color: #000;
        margin: 10px 0;
    }
}

</style>