<template>
<v-dialog v-model="show" max-width="800px">
    <v-card>
        <!-- TEXT CHANGE IF IS REGISTER OR EDIT -->
        <v-card-text v-if="register == true" class="pa-5 border-bottom">
            <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Cadastro de novo usuário.</h3>
            <h6 class="subtitle-2 font-weight-light">Preencha todas os campos abaixo e confirme o cadastro de um novo usuário no sistema.</h6>
        </v-card-text>

        <v-card-text v-else class="pa-5 border-bottom">
            <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Perfil do usuário: <b v-text="user.username"></b>.</h3>
            <h6 class="subtitle-2 font-weight-light">Preencha todas os campos abaixo e confirme a edição do cadastro do usuário no sistema.</h6>
        </v-card-text>

        <!-- FORM -->
        <v-card-text class="pa-5 border-bottom">
            <v-form v-model="valid" lazy-validation ref="formUser">
                <v-row>

                    <v-col cols="12" lg="6" md="6" sm="12" xs="12" class="py-0 my-0">
                        <v-text-field label="Nome de usuário" v-model="user.username" :rules="[value => !!value || 'Informe o nome de usuário.']" outlined clearable></v-text-field>

                        <div v-if="register == true">
                            <v-text-field label="Senha de usuário" v-model="user.password" :rules="[value => !!value || 'Informe a senha de usuário.']" outlined clearable></v-text-field>
                        </div>

                        <div v-else>
                            <v-text-field label="Senha de usuário" v-model="user.password" outlined clearable></v-text-field>
                        </div>

                    </v-col>

                    <v-col cols="12" lg="6" md="6" sm="12" xs="12" class="py-0 my-0">
                        <v-select label="Tipo de usuário" v-model="user.type" :rules="[value => !!value || 'Selecione o tipo de usuário.']" :items="types" item-text="name" item-value="value" no-data-text="Sem dados disponível." @change="userClear(user)" outlined></v-select>

                        <!-- se tipo for sub-admin mostra o select de admin -->
                        <v-select v-if="user.type == 'sub_admin'" label="Admin de região" v-model="user.admin_id" :rules="[value => !!value || 'Selecione o admin de região do usuário.']" :items="admins" :loading="loading.admins" item-text="username" item-value="id" no-data-text="Sem dados disponível." outlined></v-select>
                        
                        <!-- se tipo for gerente mostra o select de admin -->
                        <v-select v-if="user.type == 'gerente'" label="Região" v-model="user.region_id" :rules="[value => !!value || 'Selecione a região do usuário.']" :items="regions" :loading="loading.regions" item-text="name" item-value="id" no-data-text="Sem dados disponível." outlined></v-select>

                        <!-- se tipo for operador mostra o select de gerente -->
                        <v-select v-if="user.type == 'operador'" label="Gerente" v-model="user.manager_id" :rules="[value => !!value || 'Selecione o gerente do usuário.']" :items="managers" :loading="loading.managers" item-text="username" item-value="id" no-data-text="Sem dados disponível." outlined></v-select>

                        <div v-if="register == true">
                            <v-text-field label="Confirmar senha" v-model="user.password_confirmation" :rules="[value => !!value || 'Informe a confirmação da senha.']" outlined clearable></v-text-field>
                        </div>

                        <div v-else>
                            <v-text-field label="Confirmar senha" v-model="user.password_confirmation" outlined clearable></v-text-field>
                        </div>

                    </v-col>

                    <v-col cols="12" xs="12" class="py-0 my-0">
                        <v-checkbox label="Confirmar usuário?" v-model="checkbox" :rules="[value => !!value || 'É necessário confirmar.']" dense required class="ma-0" color="green"></v-checkbox>
                    </v-col>
                    
                </v-row>
            </v-form>
        </v-card-text>

        <!-- ACTIONS CHANGE IF IS REGISTER OR EDIT -->
        <v-card-actions v-if="register == true">
            <v-btn color="red darken-1" text @click="close">CANCELAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" :disabled="!valid" text @click="userSave">SALVAR NOVO USUÁRIO</v-btn>
        </v-card-actions>

        <v-card-actions v-else>
            <v-btn color="red darken-1" text @click="close">CANCELAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" :disabled="!valid" text @click="userEdit(user)">EDITAR USUÁRIO</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
export default {
    name: "ModalUserNewEdit",

    props: {
        user: Object,
        edit: Boolean,
        register: Boolean,
        dialog: Boolean,
        types: Array
    },


    data: () => ({
        // SNACKBAR
        snackbar: {},

        admins: [],
        managers: [],
        sessions: [],
        regions: [],
        operators: [],

        loading: {
            admins: true,
            operators: true,
            sessions: true,
            regions: true,
        },

        // DIALOG 
        valid: false,
        checkbox: false,
        show: false,
    }),

    created() {
        this.loadAdmins();
        this.loadRegions();
        this.loadManagers();
    },

    watch: {
        // WATCH IF THE USER CHOOSES EDIT TO LOAD POINTS
        edit: function (val) {
            console.log(val)
            //!val || this.loadPoints();
        },

        // WATCH THE VALUE TO OPEN THE MODAL
        dialog: function (val) {
            this.show = val;
        },

        // IF USER CLICKS OUTSIDE THE DIALOG 
        show: function (val) {
            val || this.close();
        }
    },

    methods: {
        // CLOSE MODAL   
        close() {
            this.$emit('close');
            this.$refs.formUser.reset();
        },

        // RELOAD PARENT
        reload() {
            this.close();
            //this.loadSubManagers();
            this.$emit('reload');
        },


        // LOAD MANAGERS
        loadAdmins() {
                this.loading.admins = true;

                this.$api
                    .get("/users?type=admin_regiao", {
                        headers: {
                            Authorization: `Bearer ${this.accessToken()}`
                        }
                    })
                    .then(response => {
                        this.admins = response.data;
                        this.loading.admins = false;
                    })
                    .catch(() => {
                        this.snackbar.text = "Não foi possível carregar os admins de região.";
                        this.snackbar.type = "warning";
                        this.snackbarShow(this.snackbar);

                        this.loading.admins = false;
                    });

        },

        loadManagers() {

            this.loading.managers = true;

            this.$api
                .get("/users/managers", {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                })
                .then((response) => {
                    this.managers = response.data;
                    this.loading.managers = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar as sessões.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);

                    this.loading.managers = false;
                });
        },

        loadRegions() {

            this.loading.regions = true;

            this.$api
                .get("/regions", {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                })
                .then((response) => {
                    this.regions = response.data;
                    this.loading.regions = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar as regiões.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);

                    this.loading.regions = false;
                });
        },


        // PUTS A MANAGER AND HIS SELLER POINT WHEN THE TYPE OF USER IS SUB-MANAGER
   /*      userManagerChange(id) {
            if (this.loggedUser().type == 'manager') {
                this.user.bank_id = this.loggedUser().bank_id;
                this.user.seller_points_id = this.loggedUser().seller_points_id;
            } else {
                let manager_id = id;
                let manager = this.results.managers.find(element => element.id == manager_id);
                this.user.bank_id = manager.bank_id;
                this.loadPoints();

                this.user.seller_points_id = manager.seller_points_id;
            }
        },
 */
        // CLEAR SELECTION OF MANAGER, 
        userClear(user) {
            if (user.type != 'master') {
                user.admin_id = "";
                user.region_id = "";
                user.session_id = "";
                user.operator_id = "";
            }
        },

        // EDIT USER
        userEdit() {
            if (this.$refs.formUser.validate() && this.user.password == this.user.password_confirmation) {

                let id = this.user.id

                delete this.user.id

                this.$api
                    .patch(`/users/${id}`, this.user, {
                        headers: {
                            Authorization: `Bearer ${this.accessToken()}`
                        },
                    })
                    .then(() => {
                        this.snackbar.text = "Usuário atualizado com sucesso!";
                        this.snackbar.type = "green";
                        this.snackbarShow(this.snackbar);
                        this.reload();
                    })
                    .catch(() => {
                        this.snackbar.text = "Erro na atualização do usuário!";
                        this.snackbar.type = "error";
                        this.snackbarShow(this.snackbar);
                    });
            }
        },

        // NEW USER
        userSave() {
            if (this.$refs.formUser.validate()) {

                this.$api
                .post(`/users/store`, this.user, {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                })
                .then(() => {
                    this.snackbar.text = "Usuário cadastrado com sucesso!";
                    this.snackbar.type = "green";
                    this.snackbarShow(this.snackbar);
                    this.reload()
                })
                .catch(() => {
                    this.snackbar.text = "Erro ao cadastrar novo usuário!";
                    this.snackbar.type = "error";
                    this.snackbarShow(this.snackbar);
                });
                
            } 

        },

    }
}
</script>
