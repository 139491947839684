<template>
<div class="print_table">
    <v-skeleton-loader v-if="loading" type="table"></v-skeleton-loader>

    <v-data-table v-else :loading="loading" no-data-text="Não há descarregos." no-results-text="Sem resultados." loading-text="Carregando..." class="border elevation-4" :headers="headers" :items="unloads" :page.sync="pagination.current" :search="search" :items-per-page="pagination.perpage" @page-count="pagination.total = $event" hide-default-footer>
        <template v-slot:top>
            <div class="ma-2">
                <v-row class="ma-0 pa-0 justify-space-between">
                    <v-col cols="12" lg="8" md="8" sm="8" xs="12" class="pa-1">
                        <v-text-field v-model="search" hide-details append-icon="mdi-magnify" label="Modalidade, Bilhete" color="primary" outlined dense clearable></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="4" md="4" sm="4" xs="12" class="pa-1">
                        <v-select :items="items_options" v-model="pagination.perpage" hide-details label="Total por página" color="primary" outlined dense></v-select>
                    </v-col>
                </v-row>
            </div>
            <v-divider></v-divider>
        </template>

        <!-- MODALITY NAME FORMAT -->
        <template v-slot:item.modality="{ item }">
            {{ modalities[modalities.findIndex(modality => modality.value == item.modality)].name }}
        </template>

        <!-- VALUE FORMAT -->
        <template v-slot:item.value="{ item }">
            {{ formatCurrency(item.value) }}
        </template>
        
        <template v-slot:item.expected_value="{ item }">
            {{ formatCurrency(item.expected_value) }}
        </template>

        <!-- DATE FORMAT -->
        <template v-slot:item.extraction_date="{ item }">
            {{ new Date(item.extraction_date).toLocaleString() }}
        </template>

        <template v-slot:footer>
            <v-divider></v-divider>
            <div class="pa-2">
                <v-row class="ma-0 pa-0 justify-center">
                    <v-col cols="6" class="pa-0">
                        <v-pagination total-visible="7" v-model="pagination.current" :length="pagination.total"></v-pagination>
                    </v-col>
                </v-row>
            </div>
        </template>
    </v-data-table>
</div>
</template>

<script>
export default {
    name: "ModalBankUnload",

    props: {
        id: Number,
        loader: Boolean
    },

    data: () => ({
        // SNACKBAR
        snackbar: {},

        // DATA TABLE
        unloads: [],
        headers: [{
                text: "BANCA ALVO",
                value: "bank_target_name",
            },
            {
                text: "DATA DA EXTRAÇÃO",
                value: "extraction_date",
            },
            {
                text: "MODALIDADE",
                value: "modality",
            },
            {
                text: "BILHETE",
                value: "pule",
            },
            {
                text: "VALOR",
                value: "value",
            },
            {
                text: "VALOR ESPERADO",
                value: "expected_value",
            }
        ],
        pagination: {
            current: 1,
            total: 0,
            perpage: 10
        },
        search: "",
        items_options: [
            5,
            10,
            15,
            20,
            25
        ],
        loading: false,
        modalities: [{
                name: "Grupo",
                value: 'grupo'
            },
            {
                name: "Grupo Invertido",
                value: 'grupo_invertido'
            },
            {
                name: "Duque de Grupo",
                value: 'duque_grupo'
            },
            {
                name: "Terno de Grupo",
                value: 'terno_grupo'
            },
            {
                name: "Dezena",
                value: 'dezena'
            },
            {
                name: "Dezena Invertida",
                value: 'dezena_invertida'
            },
            {
                name: "Duque de Dezena",
                value: 'duque_dezena'
            },
            {
                name: "Terno de Dezena",
                value: 'terno_dezena'
            },
            {
                name: "Centena",
                value: 'centena'
            },
            {
                name: "Centena Invertida",
                value: 'centena_invertida'
            },
            {
                name: "Milhar",
                value: 'milhar'
            },
            {
                name: "Milhar Invertida",
                value: 'milhar_invertida'
            },
            {
                name: "Milhar e Centena",
                value: 'milhar_centena'
            },
            {
                name: "Milhar e Centena Invertida",
                value: 'milhar_centena_invertida'
            },
            {
                name: "Final",
                value: 'final'
            },
        ],
    }),

    watch: {
        // WATCH THE OVERLAY TO DO THE REQUEST AND OPEN THE MODAL
        loader: function (val) {
            if (val == true)
                this.bankUnloads();
        },
    },

    methods: {
        // LIST BANK SETTINGS
        bankUnloads() {
            this.loading = true;

            this.$api
                .get(`bank/${this.id}/unloads`, {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    }
                })
                .then(response => {
                    this.unloads = response.data.data;
                    this.loading = false;
                    this.$emit('show');
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar os descarregos!";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);

                    this.loading = false;
                });
        },
    }
};
</script>

<style >
    @media print {
        .print_table {
            width: 100vh !important;
        }
    }
</style>
