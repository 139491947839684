<template>
<v-dialog v-model="show" max-width="800px">
    <v-card>
        <!-- TEXT CHANGE IF IS NEW OR EDIT -->
        <v-card-text v-if="register == true" class="pa-5 border-bottom">
            <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Cadastro de nova loteria.</h3>
            <h6 class="subtitle-2 font-weight-light">Preencha todas os campos abaixo e confirme o cadastro de uma nova loteria.</h6>
        </v-card-text>

        <v-card-text v-else class="pa-5 border-bottom">
            <h6 class="subtitle-2 font-weight-light">Preencha todas os campos abaixo e confirme a edição de uma loteria existente no sistema.</h6>
        </v-card-text>

        <v-card-text class="pa-5 border-bottom">
            <v-form v-model="valid" lazy-validation ref="formLottery">
                <v-row class="ma-0 pa-0 justify-space-between">

                    <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="py-0 my-0">
                        <v-text-field dense label="Nome da loteria. ex: Look Goiás" v-model="lottery.name" :rules="[value => !!value || 'Informe o nome.']" outlined clearable></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="py-0 my-0">
                        <v-text-field dense label="Abreviação. ex: LK" v-model="lottery.display_name" :rules="[value => !!value || 'Informe a abreviação.']" outlined clearable></v-text-field>
                    </v-col>
            
                </v-row>

            </v-form>
        </v-card-text>

        <!-- ACTIONS CHANGE IF IS NEW OR EDIT -->
        <v-card-actions v-if="register == true">
            <v-btn color="red darken-1" text @click="close">CANCELAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" :disabled="!valid" text @click="lotterySave">NOVA LOTERIA</v-btn>
        </v-card-actions>

        <v-card-actions v-else>
            <v-btn color="red darken-1" text @click="close">CANCELAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" :disabled="!valid" text @click="lotteryEdit">SALVAR ALTERAÇÃO</v-btn>
        </v-card-actions>
        

    </v-card>
</v-dialog>
</template>

<script>
export default {
    name: "ModalLotteryNewEdit",

    props: {
        lottery: Object,
        edit: Boolean,
        register: Boolean,
        dialog: Boolean,
    },

    data: () => ({
        // SNACKBAR
        snackbar: {},

        disabled: {
            lotteries: true,
        },
        
        loading: {
            lotteries: true,
        },

        // DIALOG 
        valid: false,
        checkbox: false,
        show: false,
    }),

    watch: {
        // WATCH THE VALUE TO OPEN THE MODAL
        dialog: function (val) {
            this.show = val;
        },

        edit: function () {
            if(this.edit == true) {
                //this.loadRegions();
            }
        },

        // IF USER CLICKS OUTSIDE THE DIALOG 
        show: function (val) {
            if(val) {
                //this.loadRegions();
            }
            val || this.close();
        },
    },

    computed: {
        // FORMAT DATE
        formatNewDate: {
            get() {
                return this.formatDate(this.ex_date);
            },

            set() {
                this.ex_date = null;
            }
        },
    },

    methods: {
        // CLOSE MODAL   
        close() {
            this.resetFields();
            this.disabled.lotteries = true;
            this.$emit('close');
            this.$refs.formLottery.reset();
        },

        // RELOAD PARENT
        reload() {
            this.close();
            this.$emit('reload');
        },


        resetFields() {
            this.lotteries =  []
        },
  
     
        // NEW LOTTERY
        lotterySave() {
            if (this.$refs.formLottery.validate()) {

                this.$api
                    .post("/lotteries", this.lottery, {
                        headers: {
                            Authorization: `Bearer ${this.accessToken()}`
                        }
                    })
                    .then(() => {
                        this.snackbar.text = "Loteria cadastrada com sucesso!";
                        this.snackbar.type = "green";
                        this.snackbarShow(this.snackbar);

                        this.reload();
                    })
                    .catch(() => {
                        this.snackbar.text = "Erro ao cadastrar nova loteria!";
                        this.snackbar.type = "error";
                        this.snackbarShow(this.snackbar);
                    });
            }
        },

        // UPDATE EXISTING EXPENSE
        lotteryEdit(){
            
            if (this.$refs.formLottery.validate()) {
                this.$api
                .put(`/lotteries/${this.lottery.id}`, this.lottery, {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    }
                })
                .then(() => {
                    this.snackbar.text = "Edição realizada com sucesso!";
                    this.snackbar.type = "green";
                    this.snackbarShow(this.snackbar);

                    this.reload();
                })
                .catch(() => {
                    this.snackbar.text = "Erro ao editar loteria!";
                    this.snackbar.type = "error";
                    this.snackbarShow(this.snackbar);
                });
                
            }
        }
        
    }
}
</script>
