<template>
<v-dialog v-model="display" max-width="800px ">
    <v-card>
        <v-card-text class="pa-5 border-bottom">
            <v-data-table  no-data-text="Não há Regiões." no-results-text="Sem resultados." hide-default-footer loading-text="Carregando..."
            disable-sort class="elevation-1" :headers="headers" :items="assistant_region"
            :loading="loading.assistant_region" :disabled="loading.assistant_region">              
            </v-data-table>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="close">FECHAR</v-btn>
        </v-card-actions>

    </v-card>
</v-dialog>
</template>

<script>
export default {
    name: "ModalAssistantRegion",

    props: {
        assistant: null,
        show: Boolean
    },

    data: () => ({

        snackbar: {},

        headers: [
            {
                text: "Nome",
                value: "name",
                class: "primary white--text"
            },
        ],

        loading: {
            assistant_region: true
        },

        valid: false,
        display: false,
        assistant_region: []
    }),

    watch: {
        show: function (val) {
            this.display = val;
        },

        display: function (val) {
            val || this.close();
            
        },

        assistant: function() {
            this.loadAssistantRegion();
        }
    },

    created() {
        console.log(this.assistant.assistant_id);
    },

    methods: {
        close() {
            this.display = false;
            this.$emit('close');
        },

        loadAssistantRegion() {
            this.loading.assistant_region = true;

            this.$api
                .get(`/regions/assistant_region`, { 
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                    params: { 
                        assistant_id: this.assistant.assistant_id 
                    }
                })
                .then((response) => {
                    this.assistant_region = response.data;
                    this.loading.assistant_region = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar as regiões.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);
                    this.loading.assistant_region = false;

                });
              
        },
    }
}
</script>