<template>
<v-dialog v-model="show" max-width="800px">
    <v-card>
        <!-- TEXT CHANGE IF IS NEW OR EDIT -->
        <v-card-text class="pa-5 border-bottom">
            <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Lançar descarga.</h3>
            <h6 class="subtitle-2 font-weight-light">Preencha todas os campos abaixo e confirme o lançamento da descarga manual.</h6>
        </v-card-text>

        <v-card-text class="border-bottom">
            <v-form v-model="valid" lazy-validation ref="formDischarge">
                <v-row>

                     <!-- DATE -->
                     <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="py-0 my-0">
                        <v-menu :close-on-content-click="true" transition="scale-transition" min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field dense v-on="on" v-bind="attrs" readonly type="date" outlined :rules="[value => !!value || 'Informe a data.']" v-model="date_search" label="Data"></v-text-field>
                            </template>
                            <v-date-picker :max="new Date().toJSON()" @change="loadExtractions" color="primary" v-model="date_search" locale="pt-br"></v-date-picker>
                        </v-menu>
                    </v-col>

                    <!-- LOTTERIES -->
                        <v-col cols="12" lg="6" md="6" sm="6" xs="12" class="py-0 my-0">
                        <v-select dense @change="loadExtractions" :disabled="loading.lotteries" :loading="loading.lotteries" no-data-text="Sem dados disponível." outlined :rules="[value => !!value || 'Selecione a loteria.']" item-text="name" item-value="id" v-model="lottery_id" :items="lotteries" label="Loteria"></v-select>
                    </v-col>

                    <!-- EXTRACTION -->
                    <v-col cols="12" lg="6" md="6" sm="6" xs="12" class="py-0 my-0">
                        <v-select dense :disabled="loading.extractions" :loading="loading.extractions" no-data-text="Sem dados disponível." outlined :rules="[value => !!value || 'Selecione a extração.']" item-text="name" item-value="name" v-model="extraction_name" :items="extractions" label="Extração"></v-select>
                    </v-col>

                    <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="py-0 my-0">
                        <v-autocomplete dense clearable :rules="[value => !!value || 'Informe a região de descarga']" no-data-text="Sem dados disponível." :loading="loading.discharge_regions" :disabled="loading.discharge_regions" v-model="receiving_region" label="Região de descarga" color="primary" item-text="name" item-value="id" outlined :items="discharge_regions"/>
                    </v-col>

                    <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="py-0 my-0">
                        <DataTable style="width: 100% !important;" :value="discharge_points" editMode="cell" @cell-edit-complete="onCellEditComplete" class="editable-cells-table p-datatable-sm" responsiveLayout="scroll">
                            <Column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field" :footer="sumField(col.field)">
                                <template #editor="{ data, field }">
                                    <InputNumber v-if="field == 'value'" v-model="data[field]" mode="decimal" :minFractionDigits="2" />
                                    <InputNumber v-if="field == 'award'" v-model="data[field]" mode="decimal" :minFractionDigits="2" />
                                </template>
                            </Column>
                        </DataTable>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>


        <!-- ACTIONS CHANGE IF IS NEW OR EDIT -->
        <v-card-actions >
            <v-btn color="red darken-1" text @click="close">CANCELAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dischargeSave">NOVA DESCARGA</v-btn>
        </v-card-actions>

    </v-card>
</v-dialog>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputNumber from 'primevue/inputnumber';

export default {
    name: "ModalDischargeNew",

    components: {
        DataTable,
        Column, 
        InputNumber
    },

    props: {
        dialog: Boolean,
    },

    data: () => ({
        // SNACKBAR
        snackbar: {},

        columns: [
            {field: 'name', header: 'Região'},
            {field: 'value', header: 'Valor'},
            {field: 'award', header: 'Prêmio'},
        ],
        
        extractions: [],
        lotteries: [],
        discharge_regions: [],
        discharge_points: [],

        receiving_region: null,
        extraction_name: null,
        lottery_id: null,
        date_search: null,

        loading: {
            lotteries: false,
            extractions: false,
            discharge_points: false,
            discharge_regions: false,
        },

        // DIALOG 
        valid: false,
        checkbox: false,
        show: false,

    }),

    watch: {
        // WATCH THE VALUE TO OPEN THE MODAL
        dialog: function (val) {
            if(val) {
                this.loadDischargePoints()
                this.loadLotteries()
                this.loadDischargeRegions()
            }
            this.show = val;
        },

        // IF USER CLICKS OUTSIDE THE DIALOG 
        show: function (val) {
            val || this.close();
        },
    },

    
    methods: {

        sumField(key) {
            if(!(key == 'value' || key == 'award')) return

            let total = this.discharge_points.reduce((a, b) => parseFloat(a) + parseFloat((b[key] || 0)), 0)
            return total
        },

        // CLOSE MODAL   
        close() {
            this.resetFields();
            this.$emit('close');
            this.$refs.formDischarge.reset();
        },

        // RELOAD PARENT
        reload() {
            this.close();
            this.$emit('reload');
        },

        onCellEditComplete (event) {
            let { data, newValue, field } = event;

            switch (field) {
                case 'value':
                    data[field] = newValue; 

                case 'award':
                    data[field] = newValue; 
                break;

            }
        },


        resetFields() {
        
        },


        loadDischargePoints() {
            this.loading.discharge_points = true;

            this.$api
                .get(`/discharges/dischargePoints`, { 
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                })
                .then((response) => {
                    this.discharge_points = response.data;
                    this.loading.discharge_points = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar os pontos de descarga.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);

                    this.loading.discharge_points = false;
                });
            
        },

        loadLotteries() {
            this.loading.lotteries = true;

            this.$api
                .get("/lotteries", {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                })
                .then((response) => {
                    this.lotteries = response.data;
                    this.loading.lotteries = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar as loterias.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);

                    this.loading.lotteries = false;
                });
        },

        loadExtractions() {
            if(this.date_search == null || this.lottery_id == null) return 

            this.loading.extractions = true

            this.$api
                .get("/extraction/list", {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                    params: {
                        lottery_search: this.lottery_id,
                        date: this.date_search,
                    }
                })
                .then(response => {
                    this.extractions = response.data;
                    this.loading.extractions = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar as extrações.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);

                    this.loading.extractions = false;
                });
        },

        loadDischargeRegions() {
            this.loading.discharge_regions = true;

            this.$api
                .get("/regions", {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                    params: {
                        search_type: 'descarga'
                    }
                })
                .then(response => {
                    this.discharge_regions = response.data;
                    this.loading.discharge_regions = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar as extrações.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);

                    this.loading.discharge_regions = false;
                });
        },
      
        // NEW SELLER AWARD
        dischargeSave() {


            for(let discharge_point of this.discharge_points) {
                discharge_point.date = this.date_search
                discharge_point.extraction = this.extraction_name
                discharge_point.receiving_region = this.receiving_region
            }

            this.$api
            .post('discharges/manualDischarge', {manual_discharges: this.discharge_points}, {
                headers: {
                    Authorization: `Bearer ${this.accessToken()}`
                },
            })
            .then(() => {
                this.snackbar.text = "Descarga criada com sucesso!";
                this.snackbar.type = "green";
                this.snackbarShow(this.snackbar);
            })
            .catch(() => {
                this.snackbar.text = "Erro ao cadastrar nova descarga!";
                this.snackbar.type = "error";
                this.snackbarShow(this.snackbar);
            });
    
            this.reload();
             
        },
    }
}
</script>
