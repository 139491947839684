<template>
    <div>
        <v-dialog v-model="show" max-width="650px">
            <v-card>
                <v-card-text class="pa-5 border-bottom">
                    <h6 class="subtitle-2 font-weight-light">Preencha todas os campos abaixo e confirme a edição desta mensagem.</h6>
                </v-card-text>

                <v-card-text class="pa-5 border-bottom">
                    <v-form lazy-validation ref="formExpense" class="edit-message-content">
                        <v-row class="ma-0 pa-0 justify-space-between title-content">
                            <span>TÍTULO</span>
                            <InputText type="text" v-model="message.title" />
                        </v-row>

                        <v-row class="ma-0 pa-0 justify-space-between message-content">
                            <span>MENSAGEM</span>
                            <Textarea v-model="message.message" :autoResize="true" rows="5" cols="30" />
                        </v-row>
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-btn color="red darken-1" text>CANCELAR</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="updateMessage()">SALVAR ALTERAÇÃO</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog> 
    </div>
</template>

<script>
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';

export default {
    components: {
        InputText,
        Textarea
    },

    props: [
        'dialog_update',
        'message'
    ],

    data:() => ({
        show: false,
        snackbar: {},
    }),

    methods: {
        updateMessage() {
            var message = {
                message_code: this.message.message_code,
                message: this.message.message,
                title: this.message.title 
            }

            this.$api.put('/messages/update', message, {
                headers: {
                    Authorization: `Bearer ${this.accessToken()}`
                }
            })
            .then(() => {
                this.snackbar.text = "Mensagem atualizada com sucesso!";
                this.snackbar.type = "green";
                this.snackbarShow(this.snackbar);
                this.reload();
            })
            .catch(() => {
                this.snackbar.text = "Não foi possível atualizar a mensagem!";
                this.snackbar.type = "red";
                this.snackbarShow(this.snackbar);
            })
        },

        close() {
            this.$emit('close');
        },

        reload() {
            this.close();
            this.$emit('reload');
        },
    },

    watch: {
        // WATCH THE VALUE TO OPEN THE MODAL
        dialog_update: function (val) {
            if (val) {
            }
            this.show = val;
        },

        // IF USER CLICKS OUTSIDE THE DIALOG 
        show: function (val) {
            val || this.close();
        },
    },
}
</script>

<style lang="scss" scoped>

::v-deep .v-dialog {
    background-color: #fff;
}

.edit-message-content {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .title-content,
    .message-content {
        display: flex;
        flex-direction: column;
    }
}

</style>