<template>
<v-dialog v-model="show" max-width="800px">
    <v-card>
        <v-card-text class="pa-5 border-bottom">
            <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Editar meu perfil.</h3>
            <h6 class="subtitle-2 font-weight-light">Preencha todas os campos abaixo e confirme as alterações do seu perfil no sistema.</h6>
        </v-card-text>

        <v-card-text class="pa-5 border-bottom">
            <v-form v-model="valid" lazy-validation ref="formEdit">
                <v-row>
                    <v-col cols="12" class="py-0 my-0">
                        <v-text-field label="Nome de usuário" v-model="user.username" :rules="[value => !!value || 'Informe o nome de usuário.']" outlined clearable></v-text-field>
                    </v-col>

                    <v-col cols="12" xs="12" class="py-0 my-0">
                        <v-checkbox v-model="checkbox" :rules="[value => !!value || 'É necessário confirmar.']" label="Confirmar edições?" class="ma-0" color="green" required></v-checkbox>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>

        <v-card-actions>
            <v-btn color="red darken-1" text @click="close">CANCELAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" :disabled="!valid" text @click="userEdit">EDITAR PERFIL</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
export default {
    name: "ModalPasswordChange",

    props: {
        dialog: Boolean,
        user: Object,
    },

    data: () => ({
        // SNACKBAR
        snackbar: {},
        
        // FORM 
        valid: false,
        checkbox: false,
        show: false,
    }),

    watch: {
        // WATCH THE VALUE TO OPEN THE MODAL
        dialog: function (val) {
            this.show = val;
        },

        // IF USER CLICKS OUTSIDE THE DIALOG 
        show: function (val) {
            val || this.close();
        }
    },

    methods: {
        // CLOSE MODAL  
        close() {
            this.$emit('close');
            this.$refs.formEdit.reset();
        },

        // CLOSE 
        reload() {
            this.close();
            this.$emit('reload');
        },

        // EDIT USER
        userEdit() {
            if (this.$refs.formEdit.validate()) {
                this.$api
                    .patch(`/users/${this.user.id}`, this.user, {
                        headers: {
                            Authorization: `Bearer ${this.accessToken()}`
                        }
                    })
                    .then(() => {
                        this.snackbar.text = "Perfil atualizado com sucesso!";
                        this.snackbar.type = "green";
                        this.snackbarShow(this.snackbar);

                        this.reload();
                    })
                    .catch(() => {
                        this.snackbar.text = "Erro ao atualizar perfil!";
                        this.snackbar.type = "error";
                        this.snackbarShow(this.snackbar);
                    });
            }
        },
    }
}
</script>
