<template>
<v-dialog v-model="display" max-width="800px">
    <v-card>
        <v-card-text class="pa-5 border-bottom">
            <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Resultado da extração : <b v-text="extraction.name"></b>.</h3>
        </v-card-text>

        <v-card-text class="pt-5 border-bottom">
            <v-simple-table class="elevation-2">
                <template v-slot:default>
                    <thead class="primary">
                        <tr>
                            <th>
                                Prêmio
                            </th>
                            <th>
                                Resultado
                            </th>
                            <th>
                                Animal
                            </th>
                        </tr>
                    </thead>
                    <tbody v-if="extraction.results.length > 0 ">
                        <tr>
                            <td>1º</td>
                            <td>{{ extraction.results[0].first }}</td>
                            <td>{{getAnimal(extraction.results[0].first)}}</td>
                        </tr>
                         <tr>
                            <td>2º</td>
                            <td>{{ extraction.results[0].second }}</td>
                            <td>{{getAnimal(extraction.results[0].second)}}</td>
                        </tr>
                         <tr>
                            <td>3º</td>
                            <td>{{ extraction.results[0].third }}</td>
                            <td>{{getAnimal(extraction.results[0].third)}}</td>
                        </tr>
                         <tr>
                            <td>4º</td>
                            <td>{{ extraction.results[0].fourth }}</td>
                            <td>{{getAnimal(extraction.results[0].fourth)}}</td>
                        </tr>
                         <tr>
                            <td>5º</td>
                            <td>{{ extraction.results[0].fifth }}</td>
                            <td>{{getAnimal(extraction.results[0].fifth)}}</td>
                        </tr>
                         <tr>
                            <td>6º</td>
                            <td>{{ extraction.results[0].sixth }}</td>
                            <td>{{getAnimal(extraction.results[0].sixth)}}</td>
                        </tr>
                         <tr>
                            <td>7º</td>
                            <td>{{ extraction.results[0].seventh }}</td>
                            <td>{{getAnimal(extraction.results[0].seventh)}}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="close">FECHAR</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
export default {
    name: "ModalResultLottery",

    props: {
        extraction: Object,
        show: Boolean
    },

    data: () => ({
        display: false,
        animals: [
            'Avestruz', 'Águia', 'Burro', 'Borboleta', 'Cachorro', 'Cabra', 'Carneiro', 'Camelo',
            'Cobra', 'Coelho', 'Cavalo', 'Elefante', 'Galo', 'Gato', 'Jacaré', 'Leão', 'Macaco',
            'Porco', 'Pavão', 'Peru', 'Touro', 'Tigre', 'Urso', 'Veado', 'Vaca'
        ]
    }),

    watch: {
        // WATCH THE VALUE TO OPEN THE MODAL
        show: function (val) {
            this.display = val;
        },

        // IF USER CLICKS OUTSIDE THE DIALOG 
        display: function (val) {
            val || this.close();
        }
    },

    methods: {
        // CLOSE MODAL 
        close() {
            this.$emit('close');
        },

        getAnimal (result) {
            const numeroAnimal = result.split('-', )[1]
            let animal = ''

            this.animals.map((item, index) => {
                if(parseInt(numeroAnimal) == index+1){
                    animal = item
                } 
            })

            return animal
        }
    }
}
</script>
