var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('div',{staticClass:"d-flex justify-center pa-5"},[_c('v-progress-circular',{attrs:{"size":70,"width":3,"color":"primary","indeterminate":""}})],1):_c('v-card',[_c('v-card-text',{staticClass:"pa-5 border-bottom d-flex align-center justify-center"},[_c('v-form',{ref:"formEditSetting",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"12","lg":"4","md":"4","sm":"12","xs":"12"}},[_c('vuetify-money',{attrs:{"label":'Grupo',"rules":_vm.limit_rules,"outlined":true,"dense":true,"options":_vm.options,"properties":{
                  loading: _vm.loading
              }},model:{value:(_vm.settings.limit_grupo),callback:function ($$v) {_vm.$set(_vm.settings, "limit_grupo", $$v)},expression:"settings.limit_grupo"}})],1),_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"12","lg":"4","md":"4","sm":"12","xs":"12"}},[_c('vuetify-money',{attrs:{"label":'Duque de Grupo',"rules":_vm.limit_rules,"outlined":true,"dense":true,"options":_vm.options,"properties":{
                  loading: _vm.loading
              }},model:{value:(_vm.settings.limit_duque_grupo),callback:function ($$v) {_vm.$set(_vm.settings, "limit_duque_grupo", $$v)},expression:"settings.limit_duque_grupo"}})],1),_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"12","lg":"4","md":"4","sm":"12","xs":"12"}},[_c('vuetify-money',{attrs:{"label":'Terno de Grupo',"rules":_vm.limit_rules,"outlined":true,"dense":true,"options":_vm.options,"properties":{
                  loading: _vm.loading
              }},model:{value:(_vm.settings.limit_terno_grupo),callback:function ($$v) {_vm.$set(_vm.settings, "limit_terno_grupo", $$v)},expression:"settings.limit_terno_grupo"}})],1),_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"12","lg":"4","md":"4","sm":"12","xs":"12"}},[_c('vuetify-money',{attrs:{"label":'Dezena',"rules":_vm.limit_rules,"outlined":true,"dense":true,"options":_vm.options,"properties":{
                  loading: _vm.loading
              }},model:{value:(_vm.settings.limit_dezena),callback:function ($$v) {_vm.$set(_vm.settings, "limit_dezena", $$v)},expression:"settings.limit_dezena"}})],1),_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"12","lg":"4","md":"4","sm":"12","xs":"12"}},[_c('vuetify-money',{attrs:{"label":'Dezena Invertida',"rules":_vm.limit_rules,"outlined":true,"dense":true,"options":_vm.options,"properties":{
                  loading: _vm.loading
              }},model:{value:(_vm.settings.limit_dezena_invertida),callback:function ($$v) {_vm.$set(_vm.settings, "limit_dezena_invertida", $$v)},expression:"settings.limit_dezena_invertida"}})],1),_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"12","lg":"4","md":"4","sm":"12","xs":"12"}},[_c('vuetify-money',{attrs:{"label":'Duque de Dezena',"rules":_vm.limit_rules,"outlined":true,"dense":true,"options":_vm.options,"properties":{
                  loading: _vm.loading
              }},model:{value:(_vm.settings.limit_duque_dezena),callback:function ($$v) {_vm.$set(_vm.settings, "limit_duque_dezena", $$v)},expression:"settings.limit_duque_dezena"}})],1),_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"12","lg":"4","md":"4","sm":"12","xs":"12"}},[_c('vuetify-money',{attrs:{"label":'Terno de Dezena',"rules":_vm.limit_rules,"outlined":true,"dense":true,"options":_vm.options,"properties":{
                  loading: _vm.loading
              }},model:{value:(_vm.settings.limit_terno_dezena),callback:function ($$v) {_vm.$set(_vm.settings, "limit_terno_dezena", $$v)},expression:"settings.limit_terno_dezena"}})],1),_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"12","lg":"4","md":"4","sm":"12","xs":"12"}},[_c('vuetify-money',{attrs:{"label":'Centena',"rules":_vm.limit_rules,"outlined":true,"dense":true,"options":_vm.options,"properties":{
                  loading: _vm.loading
              }},model:{value:(_vm.settings.limit_centena),callback:function ($$v) {_vm.$set(_vm.settings, "limit_centena", $$v)},expression:"settings.limit_centena"}})],1),_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"12","lg":"4","md":"4","sm":"12","xs":"12"}},[_c('vuetify-money',{attrs:{"label":'Centena Invertida',"rules":_vm.limit_rules,"outlined":true,"dense":true,"options":_vm.options,"properties":{
                  loading: _vm.loading
              }},model:{value:(_vm.settings.limit_centena_invertida),callback:function ($$v) {_vm.$set(_vm.settings, "limit_centena_invertida", $$v)},expression:"settings.limit_centena_invertida"}})],1),_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"12","lg":"4","md":"4","sm":"12","xs":"12"}},[_c('vuetify-money',{attrs:{"label":'Milhar',"rules":_vm.limit_rules,"outlined":true,"dense":true,"options":_vm.options,"properties":{
                  loading: _vm.loading
              }},model:{value:(_vm.settings.limit_milhar),callback:function ($$v) {_vm.$set(_vm.settings, "limit_milhar", $$v)},expression:"settings.limit_milhar"}})],1),_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"12","lg":"4","md":"4","sm":"12","xs":"12"}},[_c('vuetify-money',{attrs:{"label":'Milhar Invertida',"rules":_vm.limit_rules,"outlined":true,"dense":true,"options":_vm.options,"properties":{
                  loading: _vm.loading
              }},model:{value:(_vm.settings.limit_milhar_invertida),callback:function ($$v) {_vm.$set(_vm.settings, "limit_milhar_invertida", $$v)},expression:"settings.limit_milhar_invertida"}})],1),_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"12","lg":"4","md":"4","sm":"12","xs":"12"}},[_c('vuetify-money',{attrs:{"label":'Milhar e Centena',"rules":_vm.limit_rules,"outlined":true,"dense":true,"options":_vm.options,"properties":{
                  loading: _vm.loading
              }},model:{value:(_vm.settings.limit_milhar_centena),callback:function ($$v) {_vm.$set(_vm.settings, "limit_milhar_centena", $$v)},expression:"settings.limit_milhar_centena"}})],1),_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"12","lg":"4","md":"4","sm":"12","xs":"12"}},[_c('vuetify-money',{attrs:{"label":'Milhar e Centena Invertida',"rules":_vm.limit_rules,"outlined":true,"dense":true,"options":_vm.options,"properties":{
                  loading: _vm.loading
              }},model:{value:(_vm.settings.limit_milhar_centena_invertida),callback:function ($$v) {_vm.$set(_vm.settings, "limit_milhar_centena_invertida", $$v)},expression:"settings.limit_milhar_centena_invertida"}})],1),_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"12","xs":"12"}},[_c('v-checkbox',{staticClass:"ma-0",attrs:{"required":"","rules":[function (value) { return !!value || 'É necessário confirmar.'; }],"label":"Confirmar configurações da região?","color":"green"},model:{value:(_vm.checkbox),callback:function ($$v) {_vm.checkbox=$$v},expression:"checkbox"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","disabled":!_vm.valid,"text":""},on:{"click":_vm.regionSettingSave}},[_vm._v("EDITAR CONFIGURAÇÕES DA REGIÃO")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }