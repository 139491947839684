import Vue from "vue";
import Vuex from 'vuex';
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store/store";
import Vuebar from "vuebar";
import "./plugins/base";
import "./plugins/modal";
import "./plugins/form";
import "./plugins/table";
import "./plugins/dashboard";
import "./plugins/vuetify-money";
import VueSkycons from "vue-skycons";
import InstantSearch from "vue-instantsearch";
import VueGoogleCharts from 'vue-google-charts';
import PrimeVue from 'primevue/config';
import ModalSellersDashboard from './components/modal/ModalSellersDashboard.vue';
import VueMask from 'v-mask'

// USING MIXIN 
import Mixin from './mixins/mixin';
Vue.mixin(Mixin);
Vue.use(VueMask);

// IMPORT API GLOBALLY 
import api from "./services/service";
Vue.prototype.$api = api;

Vue.use(VueSkycons, {
	color: "#1e88e5",
});
Vue.component("ModalSellersDashboard", ModalSellersDashboard);
Vue.use(InstantSearch);
Vue.use(VueGoogleCharts);
Vue.config.productionTip = false;
Vue.use(Vuebar);
Vue.use(Vuex);
Vue.use(PrimeVue);

new Vue({
	vuetify,
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");