
import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from 'vuex-persistedstate';
import Cookies from 'js-cookie';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		Sidebar_drawer: true,
		Customizer_drawer: false,
		SidebarColor: "#2b2b2b", //Change Sidebar Color || 'white', | "#2b2b2b" | "rgb(44, 59, 164)" | "rgb(96, 44, 164)" | "rgb(151, 210, 219)" | "rgb(77, 86, 100)"
		SidebarBg: "",
		navbarColor: "#2b2b2b",
		setHorizontalLayout: false, // Horizontal layout,
		token: "",
		isLogged: false,
		user: {},
		expire: null,
		snackbar: '',
		navigation_item: 'Dashboard',
	},

	plugins: [createPersistedState()],
	
	mutations: {
		SET_SIDEBAR_DRAWER(state, payload) {
			state.Sidebar_drawer = payload;
		},

		SET_CUSTOMIZER_DRAWER(state, payload) {
			state.Customizer_drawer = payload;
		},

		SET_SIDEBAR_COLOR(state, payload) {
			state.SidebarColor = payload;
		},

		SET_NAVBAR_COLOR(state, payload) {
			state.navbarColor = payload;
		},

		SET_LAYOUT(state, payload) {
			state.setHorizontalLayout = payload;
		},
		
		// SETTING LOGIN
		SET_LOGGED(state, payload) {
			state.isLogged = payload.isLogged;
			state.user = payload.user;
			state.token = payload.token;
			state.expire = payload.expire;
		},
		
		// SETTING LOGOUT
		SET_LOGGOUT(state, payload) {
			state.token = payload.token;
			state.user = payload.user;
			state.isLogged = payload.isLogged;
			state.expire = payload.expire;
		},
		
		// SETTING SNACKBAR
		SET_SNACKBAR(state, payload) {
			state.snackbar = payload;
		},

		// SETTING NAVIGATION ITEMS
		SET_NAVIGATION(state, payload) {
			state.navigation_item = payload;
		}
	},

	actions: {
		// SETTING LOGIN
		setLogged(context, payload) {
			context.commit('SET_LOGGED', payload);
		},

		// SETTING LOGOUT
		setLoggout(context, payload) {
			context.commit('SET_LOGGOUT', payload);
		},

		// SETTING SNACKBAR
		setSnackbar(context, payload) {
			context.commit('SET_SNACKBAR', payload.snackbar)
		},

		// SETTING NAVIGATION ITEM TITLE
		setNavigation(context, payload) {
			context.commit('SET_NAVIGATION', payload.navigation_item)
		},
	},
	
	getters: {
		// GETTING TOKEN 
		getToken: state => {
			return state.token;
		},

		// GETTING USER 
		getUser: state => {
			return state.user;
		},

		// GETTING STATE OF USER
		getLogged: state => {
			return state.isLogged;
		},

		// GETTING NAVIGATION TITLE
		getNavigation: state => {
			return state.navigation_item;
		}
	},
});