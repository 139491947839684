<template>
    <div>
        <v-dialog v-model="show" max-width="650px" persistent>
            <v-card>
                <v-card-text class="pa-5 border-bottom">
                    <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Desaja realmente apagar esta mensagem?</h3>
                </v-card-text>

                <v-card-text class="pa-5 border-bottom">
                    <h5 class="subtitle-2 font-weight-light">Essa ação irá remover permanentemente a mensagem do sistema para todos os usuários.</h5>
                </v-card-text>

                <v-card-actions>
                    <v-btn color="red darken-1" text @click="reject">CANCELAR</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="confirm">CONFIRMAR</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: [
        'dialog_delete',
        'message'
    ],

    data:() => ({
        show: false,
    }),

    methods: {
        confirm() {
            this.$emit('confirmDelete');
        },

        reject() {
            this.$emit('rejectDelete');
        }
    },

    watch: {
        // WATCH THE VALUE TO OPEN THE MODAL
        dialog_delete: function (val) {
            if (val) {
            }
            this.show = val;
        },

        // IF USER CLICKS OUTSIDE THE DIALOG 
        show: function (val) {
            val || this.reject();
        },
    },
}
</script>

<style lang="scss" scoped>


</style>