<template>
    <v-dialog v-model="display" max-width="800px ">
        <v-card class="pa-5 border-bottom">
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="close" icon><v-icon>mdi-close</v-icon></v-btn>
            </v-card-actions>

            <div style="display: flex; justify-content: center;">
                <span class="text-h5 "> {{ticket.sender_region_name}} </span>
            </div>

            <v-card-text elevation-1>
                <div class="pa-3 elevation-1">

                    <div style="display: flex; justify-content: space-between;">
                        <span class="text-h7 black--text"> Bilhete:</span>
                        <span class="text-h7 black--text">{{ ticket.id }}</span>
                    </div>

                    <div style="display: flex; justify-content: space-between;">
                        <span class="text-h7 black--text"> Extração:</span>
                        <span class="text-h7 black--text">{{ticket.extraction_name}}</span>
                    </div>

                    <div style="display: flex; justify-content: space-between;">
                        <span class="text-h7 black--text"> Data:</span>
                        <span class="text-h7 black--text">{{ new Date(ticket.created_at).toLocaleString('pt-BR') }}</span>
                    </div>

                </div>
            </v-card-text>

            <v-card-text class="pa-3 border-bottom">
                <v-data-table no-data-text="Não há loterias." no-results-text="Sem resultados." :mobile-breakpoint="0" hide-default-footer loading-text="Carregando..." disable-sort class="elevation-1" :headers="table.headers" :items="ticket.games" :items-per-page="table.pagination.perpage" @page-count="table.pagination.total = $event">
                    
                     <!-- VALUE FORMAT -->
                    <template v-slot:[`item.selected_numbers`]="{ item }">
                        {{ item.selected_numbers.replace('[', '').replace(']', '').replace('"', '').replace('"', '') }}
                    </template>

                    <!-- VALUE FORMAT -->
                    <template v-slot:[`item.expected_value`]="{ item }">
                        {{ formatCurrency(item.expected_value) }}
                    </template>

                     <!-- VALUE FORMAT -->
                    <template v-slot:[`item.modality_code`]="{ item }">
                        {{ item.modality_code[0].toUpperCase() + item.modality_code.substr(1) }}
                    </template>

                    <!-- VALUE FORMAT -->
                    <template v-slot:[`item.value`]="{ item }">
                        {{ formatCurrency(item.value) }}
                    </template>

                    <template v-slot:[`item.status`]="{ item }">
                        <p v-if="item.status == 'win'">Ganhou</p>
                        <p v-if="item.status == 'lose'">Perdeu</p>
                        <p v-if="item.status == 'open'">Aberto</p>
                    </template>

                    <template v-slot:[`item.award`]="{ item }">
                        {{ processAwardName(item.award) }}
                    </template>

                    <template v-slot:[`item.selected_positions`]="{ item }">
                        {{ processPositionName(JSON.parse(item.selected_positions)[0]) }}
                    </template>

                    <template slot="body.append">
                        <tr>
                            <th>Total</th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th>{{ formatCurrency(sumField('value')) }}</th>  
                            <th>{{ formatCurrency(sumField('expected_value')) }}</th> 
                            <th></th>
                        </tr>
                    </template>

                </v-data-table>
            </v-card-text>           

        </v-card>
    </v-dialog>    
</template>

<script>
export default {
    name: "BaseDischargeTicket",

    props: {
        ticket: Object,
        show: Boolean
    },

    data: () => ({
        display: false,

        // DATA TABLE 
        table: {
               headers: [{
                text: "Modalidade",
                value: "modality_code",
                class: "primary white--text"
            },
            {
                text: "Prêmio",
                value: "award",
                class: "primary white--text"
            },
            {
                text: "Lado",
                value: "selected_positions",
                class: "primary white--text"
            },
            {
                text: "Palpite",
                value: "selected_numbers",
                class: "primary white--text"
            },
            {
                text: "Valor",
                value: "value",
                class: "primary white--text"
            },
            {
                text: "Valor esperado",
                value: "expected_value",
                class: "primary white--text"
            },
            {
                text: "Status",
                value: "status",
                class: "primary white--text"
            },
           
        ],

           loading: false,

           pagination: {
                current: 1,
                total: 0,
                perpage: 999999,
            },
            total_perpages: [
               999999,
            ],
        },

      
    }),

    watch: {
        // WATCH THE VALUE TO DO THE REQUEST AND OPEN THE MODAL
        show: function (val) {
            this.display = val;
        },

        // IF USER CLICKS OUTSIDE THE DIALOG 
        display: function (val) {
            val || this.close();
        }
    },

    methods: {

        sumField(key) {
            // sum data in give key (property)
            return this.ticket.games.reduce((a, b) => parseFloat(a) + parseFloat((b[key] || 0)), 0)
        },

        // CLOSE MODAL 
        close() {
            this.display = false;
            this.$emit('close');
        },
    }

}
</script>
