<template>
<v-dialog v-model="show" max-width="800px">
    <v-card>
        <!-- TEXT CHANGE IF IS NEW OR EDIT -->
        <v-card-text v-if="register == true" class="pa-5 border-bottom">
            <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Cadastro de nova seção.</h3>
            <h6 class="subtitle-2 font-weight-light">Preencha todas os campos abaixo e confirme o cadastro de uma nova seção.</h6>
        </v-card-text>

        <v-card-text v-else class="pa-5 border-bottom">
            <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Edição de seção: <b v-text="session.name"></b>.</h3>
            <h6 class="subtitle-2 font-weight-light">Preencha todas os campos abaixo e confirme a edição de uma seção existente no sistema.</h6>
        </v-card-text>

        <v-card-text class="pa-5 border-bottom">
            <v-form v-model="valid" lazy-validation ref="formSession">
                <v-row class="ma-0 pa-0 justify-space-between">
                   
                    <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="py-0 my-0">
                        <v-text-field dense label="Nome da seção" v-model="session.name" :rules="[value => !!value || 'Informe o nome da seção']" outlined clearable></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="6" md="6" sm="12" xs="12" class="py-0 my-0">
                        <v-select dense @change="changeRegion" label="Região" v-model="session.region_id" :rules="[value => !!value || 'Selecione a região a qual essa seção pertence.']" :items="regions" :loading="loading.regions" :disabled="loading.regions" item-text="name" item-value="id" no-data-text="Sem dados disponível." outlined></v-select>
                    </v-col>

                    <v-col cols="12" lg="6" md="6" sm="12" xs="12" class="py-0 my-0">
                        <v-select dense label="Gerente" v-model="session.manager_id" :rules="[value => !!value || 'Selecione o gerente.']" :items="managers" :loading="loading.managers" :disabled="loading.managers" item-text="name" item-value="id" no-data-text="Sem dados disponível." outlined></v-select>
                    </v-col>

                </v-row>
            </v-form>
        </v-card-text>

        <!-- ACTIONS CHANGE IF IS NEW OR EDIT -->
        <v-card-actions v-if="register == true">
            <v-btn color="red darken-1" text @click="close">CANCELAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" :disabled="!valid" text @click="sessionSave">NOVA SEÇÃO</v-btn>
        </v-card-actions>

        <v-card-actions v-else>
            <v-btn color="red darken-1" text @click="close">CANCELAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" :disabled="!valid" text @click="sessionUpdate">Salvar alteração</v-btn>
        </v-card-actions>

    </v-card>
</v-dialog>
</template>

<script>
export default {
    name: "ModalSessionNewEdit",

    props: {
        session: Object,
        edit: Boolean,
        register: Boolean,
        dialog: Boolean,
    },

    data: () => ({
        // SNACKBAR
        snackbar: {},
        
        // COMPONENT
        managers: [],
        regions: [],
        
        disabled: {
            managers: false,
            regions: false,
        },
        
        loading: {
            managers: false,
            regions: false,
        },

        // DIALOG 
        valid: false,
        checkbox: false,
        show: false,
    }),

    watch: {
        // WATCH THE VALUE TO OPEN THE MODAL
        dialog: function (val) {
            this.show = val;
        },

        edit: function () {
            if(this.edit == true) {
                this.loadManagers();
            }
        },

        // IF USER CLICKS OUTSIDE THE DIALOG 
        show: function (val) {
            val || this.close();
        },
    },

    computed: {
        // FORMAT DATE
        formatNewDate: {
            get() {
                return this.formatDate(this.ex_date);
            },

            set() {
                this.ex_date = null;
            }
        },
    },

    created() {
        this.loadRegions();
    },

    methods: {
        // CLOSE MODAL   
        close() {
            this.$emit('close');
            this.$refs.formSession.reset();
        },

        // RELOAD PARENT
        reload() {
            this.close();
            this.$emit('reload');
        },
        
        changeRegion() {
            this.loadManagers();
        },

        loadRegions() {

            this.loading.regions = true;

            this.$api
                .get("/regions", {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                })
                .then((response) => {
                    this.regions = response.data;
                    this.loading.regions = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar as regiões.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);

                    this.loading.regions = false;
                });
        },

        loadManagers() {

            this.loading.managers = true;

            this.$api
                .get("/users/managers", {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                    params: {
                       search_region: [this.session.region_id]
                    }
                })
                .then((response) => {
                    this.managers = response.data;
                    this.loading.managers = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar as sessões.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);

                    this.loading.managers = false;
                });
        },

        sessionUpdate(){

            if (this.$refs.formSession.validate()) {
                this.$api
                .patch(`/sessions`, this.session, { // faltando argumentos
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    }
                })
                .then(() => {
                    this.snackbar.text = "Edição realizada com sucesso!";
                    this.snackbar.type = "green";
                    this.snackbarShow(this.snackbar);

                     this.reload();
                })
                .catch(() => {
                    this.snackbar.text = "Erro ao editar seção!";
                    this.snackbar.type = "error";
                    this.snackbarShow(this.snackbar);
                });
                
            }
        },


    
    /* 
        // EDIT SELLER AWARD
        awardEdit() {
            if (this.$refs.formAward.validate()) {
                this.$api
                    .put(`/awards/${this.award.id}`, this.award, {
                        headers: {
                            Authorization: `Bearer ${this.accessToken()}`
                        }
                    })
                    .then(() => {
                        this.snackbar.text = "Ponto de venda atualizada com sucesso!";
                        this.snackbar.type = "green";
                        this.snackbarShow(this.snackbar);

                        this.reload();
                    })
                    .catch(() => {
                        this.snackbar.text = "Erro ao atualizar o ponto de venda!";
                        this.snackbar.type = "error";
                        this.snackbarShow(this.snackbar);
                    });
            }
        }, */

        // NEW SELLER AWARD
        sessionSave() {
          
            if (this.$refs.formSession.validate()) {

                this.$api
                .post("/sessions", this.session, {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    }
                })
                .then(() => {
                    this.snackbar.text = "Seção cadastrada com sucesso!";
                    this.snackbar.type = "green";
                    this.snackbarShow(this.snackbar);
                        this.reload();
                })
                .catch(() => {
                    this.snackbar.text = "Erro ao cadastrar nova seção!";
                    this.snackbar.type = "error";
                    this.snackbarShow(this.snackbar);
                    });
                
            }
        },
    }
}
</script>
