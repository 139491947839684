<template>
<v-dialog v-model="display" max-width="800px ">
    <v-card>
        <v-card-text class="pa-5 border-bottom">
            <!-- DATA-TABLE LOTTERIES -->
            <v-data-table :loading="loading.region_lotteries" no-data-text="Não há loterias." no-results-text="Sem resultados." hide-default-footer loading-text="Carregando..." disable-sort class="elevation-1" :headers="headers" :items="region_lotteries">
                <!-- DATA-TABLE HEADER ONLY FOR MASTER USER -->
                <template v-slot:top>
                    <div class="pa-1">
                        <v-form v-model="valid" lazy-validation ref="formRegionLotteries">
                            <v-row class="ma-0 pa-0 justify-space-between">
                                
                                <!-- SELECT NEW LOTTERY -->
                                <v-col cols="12" lg="10" md="10" sm="10" class="pa-1">
                                    <v-select hide-details :items="lotteries" v-model="new_lott_id" item-text="name" no-data-text="Sem dados disponível." item-value="id" label="Nova Loteria" :loading="loading.lotteries" :rules="[value => !!value || 'Selecione o administrador.']" outlined dense clearable></v-select>
                                </v-col> 

                                <!-- ADD NEW LOTTERY -->
                                <v-col cols="12" lg="2" md="2" sm="2" class="pa-1 text-right">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn height="40px" :disabled="!valid" block v-bind="attrs" v-on="on" color="primary" @click="regionNewLott">
                                                <v-icon>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>
                                            <div>NOVA LOTERIA</div>
                                        </span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </v-form>
                    </div>
                    <v-divider></v-divider>
                </template>

                <!-- ICON REMOVE -->
                <template v-slot:[`item.remove`]="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn @click="regionLotteriesRemove(item)" v-bind="attrs" v-on="on" color="red" icon>
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>
                            <div>REMOVER LOTERIA</div>
                        </span>
                    </v-tooltip>
                </template>

                <!-- DATE FORMAT -->
                <template v-slot:[`item.created_at`]="{ item }">
                    <span>{{ new Date(item.created_at).toLocaleDateString() }}</span>
                </template>

            </v-data-table>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="close">FECHAR</v-btn>
        </v-card-actions>

    </v-card>
</v-dialog>
</template>

<script>
export default {
    name: "ModalRegionlotteries",

    props: {
        region: Array,
        show: Boolean
    },

    data: () => ({

        snackbar: {},

        headers: [{
                text: "Data de atribuição",
                value: "created_at",
                class: "primary white--text"
            },
            {
                text: "Nome",
                value: "display_name",
                class: "primary white--text"
            },
            {
                text: "Remover",
                value: "remove",
                sortable: false,
                align: "center",
                class: "primary white--text"
            }
        ],

        loading: {
            region_lotteries: false,
            lotteries: false
        },

        valid: false,
        display: false,
        region_lotteries: [],
        lotteries: [],
        new_lott_id: "",
    }),

    watch: {
        // WATCH THE VALUE TO DO THE REQUEST AND OPEN THE MODAL
        show: function (val) {
            this.display = val;
            if(val){
                this.loadRegionLotteries()
                this.loadLotteries()
            }
        },

        // IF USER CLICKS OUTSIDE THE DIALOG 
        display: function (val) {
            val || this.close();
        }
    },

    methods: {
        // CLOSE MODAL 
        close() {
            this.display = false;
            this.region_lotteries = []
            this.$emit('close');
        },

        loadRegionLotteries() {

            this.$api
                .get(`lotteries/region_lotteries/${this.region.id}`, {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    }
                })
                .then(response => {
                    this.region_lotteries = response.data;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar as loterias para essa região.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);
                });

        },

        loadLotteries() {
            this.loading.lotteries = true;

            this.$api
                .get(`lotteries`, {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    }
                })
                .then(response => {
                    this.lotteries = response.data;
                    this.loading.lotteries = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar as loterias para essa região.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);
                    this.loading.lotteries = false;
                });

        },

         // NEW BANK LOTTERY
        regionNewLott() {
            if (this.$refs.formRegionLotteries.validate()) {

                this.loading.lotteries = true;

                let region_lottery = {
                    region_id: this.region.id,
                    lottery_id: this.new_lott_id
                }

                this.$api
                    .post(`lotteries/region_lotteries`, {...region_lottery}, {
                        headers: {
                            Authorization: `Bearer ${this.accessToken()}`
                        }, 
                    })
                    .then(() => {
                        this.snackbar.text = "Loteria adicionada com sucesso!";
                        this.snackbar.type = "green";
                        this.snackbarShow(this.snackbar);
                        this.loading.lotteries = false;
                    })
                    .catch(() => {
                        this.snackbar.text = "Erro ao adicionar a loteria!";
                        this.snackbar.type = "error";
                        this.snackbarShow(this.snackbar);
                        this.loading.lotteries = false;
                    });

                this.close()

            }
        },

        // EDITAR COMISSÃO
        regionLotteriesRemove (item) {

            let region_lottery = {
                lottery_id: item.lottery_id,
                region_id: item.region_id,
            }

            this.$api
                .patch(`/lotteries/region_lotteries`, {...region_lottery}, {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                })
                .then(() => {
                    this.snackbar.text = "Região atualizada com sucesso!";
                    this.snackbar.type = "green";
                    this.snackbarShow(this.snackbar);
                    this.close();
                })
                .catch(() => {
                    this.snackbar.text = "Erro na atualização da região!";
                    this.snackbar.type = "error";
                    this.snackbarShow(this.snackbar);
                });


                this.close()
            
        },
    }
}
</script>
     