<template>
<v-snackbar v-model="show" :color="snackbar.type" :timeout="2000" right top>
    <div class="text-center">
        {{ snackbar.text }}
    </div>
</v-snackbar>
</template>

<script>
export default {
    name: "BaseNotification",

    data: () => ({
        snackbar: {
            text: '',
            type: '',
        },

        show: false
    }),

    // WATCH VALUE IN VUEX STORE 
    watch: {
        '$store.state.snackbar': function () {
            this.snackbar = this.$store.state.snackbar;
            this.show = true;
        },
    },
    
    // WATCH THE MUTATION TYPE THAT IS BEING CALLED
    created() {
        this.$store.subscribe((mutation) => {
            if (mutation.type == "SET_SNACKBAR") {
                this.snackbar = this.$store.state.snackbar;
                this.show = true;
            }
        });
    }
};
</script>
