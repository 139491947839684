<template>
<div>
    <div class="d-flex justify-center pa-5" v-if="loading">
        <v-progress-circular :size="70" :width="3" color="primary" indeterminate></v-progress-circular>
    </div>

    <v-card v-else>
        <v-card-text class="pa-5 border-bottom d-flex align-center justify-center">
            <v-form v-model="valid" lazy-validation ref="formEditSetting">
                <v-row>

                    <v-col cols="12" lg="4" md="4" sm="12" xs="12" class="py-0 my-0">
                        <vuetify-money :label="'Grupo'" v-model="settings.limit_grupo" :rules="limit_rules" :outlined="true" :dense="true" :options="options" :properties="{
                  loading: loading
              }" />
                    </v-col>

                    <v-col cols="12" lg="4" md="4" sm="12" xs="12" class="py-0 my-0">
                        <vuetify-money :label="'Duque de Grupo'" v-model="settings.limit_duque_grupo" :rules="limit_rules" :outlined="true" :dense="true" :options="options" :properties="{
                  loading: loading
              }" />
                    </v-col>

                    <v-col cols="12" lg="4" md="4" sm="12" xs="12" class="py-0 my-0">
                        <vuetify-money :label="'Terno de Grupo'" v-model="settings.limit_terno_grupo" :rules="limit_rules" :outlined="true" :dense="true" :options="options" :properties="{
                  loading: loading
              }" />
                    </v-col>

                    <v-col cols="12" lg="4" md="4" sm="12" xs="12" class="py-0 my-0">
                        <vuetify-money :label="'Dezena'" v-model="settings.limit_dezena" :rules="limit_rules" :outlined="true" :dense="true" :options="options" :properties="{
                  loading: loading
              }" />
                    </v-col>

                    <v-col cols="12" lg="4" md="4" sm="12" xs="12" class="py-0 my-0">
                        <vuetify-money :label="'Dezena Invertida'" v-model="settings.limit_dezena_invertida" :rules="limit_rules" :outlined="true" :dense="true" :options="options" :properties="{
                  loading: loading
              }" />
                    </v-col>

                    <v-col cols="12" lg="4" md="4" sm="12" xs="12" class="py-0 my-0">
                        <vuetify-money :label="'Duque de Dezena'" v-model="settings.limit_duque_dezena" :rules="limit_rules" :outlined="true" :dense="true" :options="options" :properties="{
                  loading: loading
              }" />
                    </v-col>

                    <v-col cols="12" lg="4" md="4" sm="12" xs="12" class="py-0 my-0">
                        <vuetify-money :label="'Terno de Dezena'" v-model="settings.limit_terno_dezena" :rules="limit_rules" :outlined="true" :dense="true" :options="options" :properties="{
                  loading: loading
              }" />
                    </v-col>

                    <v-col cols="12" lg="4" md="4" sm="12" xs="12" class="py-0 my-0">
                        <vuetify-money :label="'Centena'" v-model="settings.limit_centena" :rules="limit_rules" :outlined="true" :dense="true" :options="options" :properties="{
                  loading: loading
              }" />
                    </v-col>

                    <v-col cols="12" lg="4" md="4" sm="12" xs="12" class="py-0 my-0">
                        <vuetify-money :label="'Centena Invertida'" v-model="settings.limit_centena_invertida" :rules="limit_rules" :outlined="true" :dense="true" :options="options" :properties="{
                  loading: loading
              }" />
                    </v-col>

                    <v-col cols="12" lg="4" md="4" sm="12" xs="12" class="py-0 my-0">
                        <vuetify-money :label="'Milhar'" v-model="settings.limit_milhar" :rules="limit_rules" :outlined="true" :dense="true" :options="options" :properties="{
                  loading: loading
              }" />
                    </v-col>

                    <v-col cols="12" lg="4" md="4" sm="12" xs="12" class="py-0 my-0">
                        <vuetify-money :label="'Milhar Invertida'" v-model="settings.limit_milhar_invertida" :rules="limit_rules" :outlined="true" :dense="true" :options="options" :properties="{
                  loading: loading
              }" />
                    </v-col>

                    <v-col cols="12" lg="4" md="4" sm="12" xs="12" class="py-0 my-0">
                        <vuetify-money :label="'Milhar e Centena'" v-model="settings.limit_milhar_centena" :rules="limit_rules" :outlined="true" :dense="true" :options="options" :properties="{
                  loading: loading
              }" />
                    </v-col>

                    <v-col cols="12" lg="4" md="4" sm="12" xs="12" class="py-0 my-0">
                        <vuetify-money :label="'Milhar e Centena Invertida'" v-model="settings.limit_milhar_centena_invertida" :rules="limit_rules" :outlined="true" :dense="true" :options="options" :properties="{
                  loading: loading
              }" />
                    </v-col>

                    <v-col cols="12" xs="12" class="py-0 my-0">
                        <v-checkbox required v-model="checkbox" :rules="[value => !!value || 'É necessário confirmar.']" label="Confirmar configurações da região?" class="ma-0" color="green"></v-checkbox>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" :disabled="!valid" text @click="regionSettingSave">EDITAR CONFIGURAÇÕES DA REGIÃO</v-btn>
        </v-card-actions>
    </v-card>
</div>
</template>

<script>
export default {
    name: "ModalRegionSetting",

    props: {
        id: Number,
        extraction_time: Number,
        loader: Boolean,
    },

    data: () => ({
        // SNACKBAR
        snackbar: {},

        // DIALOG
        settings: {},
        valid: false,
        checkbox: false,
        loading: false,
        limit_rules: [value => !!value || "Informe o limite."],

        // OPTIONS FOR VUETIFY MONEY 
        options: {
            locale: "pt-BR",
            prefix: "R$",
            suffix: "",
            length: 11,
            precision: 2
        },
    }),

    watch: {
        // WATCH THE OVERLAY TO DO THE REQUEST AND OPEN THE MODAL
       /*  id: function () {
            this.regionListSettings();
        } */
    },

    methods: {

        regionListSettings() {
            this.loading = true;

            this.$api
                .get("dischargeSettings", {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                    params: {
                        region_id: this.id,
                        extraction_time: this.extraction_time
                    }
                })
                .then(response => {
                    this.settings = response.data;
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;

                    this.snackbar.text = "Não foi possível abrir configurações!";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);
                });
        },

        // SAVE NEW CHANGES 
        regionSettingSave() {

            if (this.$refs.formEditSetting.validate()) {

                this.loading = true;
                
                this.settings.extraction_time = this.extraction_time;
                this.settings.region_id = this.id

                this.$api
                    .put(`dischargeSettings`, this.settings, {
                        headers: {
                            Authorization: `Bearer ${this.accessToken()}`
                        }
                    })
                    .then(() => {
                        this.snackbar.text = "Configuração atualizada com sucesso!";
                        this.snackbar.type = "green";
                        this.snackbarShow(this.snackbar);

                        this.regionListSettings();
                    })
                    .catch(() => {
                        this.snackbar.text = "Erro ao atualizar configurações!";
                        this.snackbar.type = "error";
                        this.snackbarShow(this.snackbar);

                        this.loading = false;
                    });
            }
        }
    }
};
</script>
