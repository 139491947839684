<template>
<v-dialog v-model="show" max-width="800px">
    <v-card>
        <!-- TEXT CHANGE IF IS REGISTER OR EDIT -->
        <v-card-text v-if="register == true" class="pa-5 border-bottom">
            <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Cadastro de novo revendedor.</h3>
            <h6 class="subtitle-2 font-weight-light">Preencha todas os campos abaixo e confirme o cadastro de um novo revendedor no sistema.</h6>
        </v-card-text>

        <v-card-text  v-if="register == false && edit_comission == false" class="pa-5 border-bottom">
            <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Perfil do revendedor: <b v-text="seller.username"></b>.</h3>
            <h6 class="subtitle-2 font-weight-light">Preencha todas os campos abaixo e confirme a edição do cadastro do revendedor no sistema.</h6>
        </v-card-text>

        <v-card-text v-if="edit_comission == true" class="pa-5 border-bottom">
            <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Comissões do usuário <b v-text="seller.username"></b>.</h3>
            <h6 class="subtitle-2 font-weight-light">Selecione uma loteria e preencha as comissões do revendedor.</h6>
        </v-card-text>

        <!-- FORM -->
        <v-card-text class="pa-5 border-bottom">
            <v-form v-model="valid" lazy-validation ref="formSeller">
                <v-row>

                    <v-col v-if="edit_comission == false" cols="12" lg="12" md="12" sm="12" xs="12" class="py-0 my-0">
                        <v-text-field dense label="Nome de usuário" v-model="seller.name" :rules="[value => !!value || 'Informe o nome de usuário.']" outlined clearable></v-text-field>
                    </v-col>

                    <v-col v-if="edit_comission == false" cols="12" lg="6" md="6" sm="12" xs="12" class="py-0 my-0">
                        <v-autocomplete dense label="Ponto de venda" v-model="seller.point_id" :rules="[value => !!value || 'Selecione o ponto de venda do revendedor.']" :items="points" :loading="loading.points" :disabled="loading.points" item-text="name" item-value="id" no-data-text="Sem dados disponível." outlined></v-autocomplete>
                    </v-col>

                    <v-col v-if="edit_comission == false" cols="12" lg="6" md="6" sm="12" xs="12" class="py-0 my-0">
                        <v-select dense clearable :rules="[value => !!value || 'Insira o tipo de autenticação']" no-data-text="Sem dados disponível." v-model="seller.authentication_type" label="Tipo de autenticação" color="primary" outlined :items="authentication_types"/>
                    </v-col>

                    <v-col v-if="edit_comission == false" cols="12" lg="6" md="6" sm="12" xs="12" class="py-0 my-0">
                        <v-text-field dense label="Saldo devedor" v-model="seller.debit_limit" :rules="debit_limit" outlined></v-text-field>
                    </v-col>

                    <v-col v-if="edit_comission == false" cols="12" lg="6" md="6" sm="12" xs="12" class="py-0 my-0">
                        <v-text-field dense label="Comissão de usuário indicado" v-model="seller.recommended_user_comission" :rules="debit_limit" outlined></v-text-field>
                    </v-col>

                     <v-col v-if="register == true && edit_comission == false" cols="12" lg="6" md="6" sm="12" xs="12" class="py-0 my-0">
                        <v-text-field dense label="Senha de usuário" v-model="seller.password" :rules="[value => !!value || 'Informe a senha de usuário.']" outlined clearable></v-text-field>
                    </v-col>

                    <v-col v-if="register == true && edit_comission == false" cols="12" lg="6" md="6" sm="12" xs="12" class="py-0 my-0">
                        <v-text-field dense label="Confirmar senha" v-model="seller.password_confirmation" outlined clearable></v-text-field>
                    </v-col>
                 
                </v-row>

                <v-row v-if="edit_comission == true">
                    <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="py-0 my-0">
                        <v-select dense @change="changeLottery(comission.lottery_id, seller.user_id)" label="Loteria" v-model="comission.lottery_id" :rules="[value => !!value || 'Selecione a loteria da comissão.']" :items="lotteries" :loading="loading.lotteries" item-text="name" item-value="id" no-data-text="Sem dados disponível." outlined></v-select> 
                    </v-col>
                </v-row>

                <v-row v-if="edit_comission == true && comission.lottery_id == 8">
                    <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="py-0 my-0">
                        <v-text-field dense label="Comissão ligeirinha" v-model="comission.comission_ligeirinha" :rules="[value => !!value || 'Informe o valor da comissão.']" outlined clearable></v-text-field> 
                    </v-col>
                </v-row>

                <v-row v-if="edit_comission == true && comission.lottery_id != 8" cols="12" lg="12" md="12" sm="12" xs="12" class="py-0 my-0">

                    <v-col cols="12" lg="6" md="6" sm="12" xs="12" class="py-0 my-0">
                        <v-text-field dense label="Comissão 11:00" v-model="comission.comission_11" :rules="[value => !!value || 'Informe o valor da comissão.']" outlined clearable></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="6" md="6" sm="12" xs="12" class="py-0 my-0">
                        <v-text-field dense label="Comissão 14:00" v-model="comission.comission_14" :rules="[value => !!value || 'Informe o valor da comissão.']" outlined clearable></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="6" md="6" sm="12" xs="12" class="py-0 my-0">
                        <v-text-field dense label="Comissão 16:00" v-model="comission.comission_16" :rules="[value => !!value || 'Informe o valor da comissão.']" outlined clearable></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="6" md="6" sm="12" xs="12" class="py-0 my-0">
                        <v-text-field dense label="Comissão 18:00" v-model="comission.comission_18" :rules="[value => !!value || 'Informe o valor da comissão.']" outlined clearable></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="6" md="6" sm="12" xs="12" class="py-0 my-0">
                        <v-text-field dense label="Comissão 21:00" v-model="comission.comission_21" :rules="[value => !!value || 'Informe o valor da comissão.']" outlined clearable></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="6" md="6" sm="12" xs="12" class="py-0 my-0">
                        <v-text-field dense label="Comissão 19:00" v-model="comission.comission_19" :rules="[value => !!value || 'Informe o valor da comissão.']" outlined clearable></v-text-field> 
                    </v-col>

                    <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="py-0 my-0">
                        <v-text-field dense label="Comissão Instantâneo" v-model="comission.comission_instant" :rules="[value => !!value || 'Informe o valor da comissão.']" outlined clearable></v-text-field> 
                    </v-col>

                </v-row>
                
            </v-form>
        </v-card-text>

        <!-- ACTIONS CHANGE IF IS REGISTER OR EDIT -->
        <v-card-actions v-if="register == true">
            <v-btn color="red darken-1" text @click="close">CANCELAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" :disabled="!valid" text @click="sellerSave">SALVAR NOVO REVENDEDOR</v-btn>
        </v-card-actions>

        <v-card-actions v-if="register == false && edit_comission == false">
            <v-btn color="red darken-1" text @click="close">CANCELAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" :disabled="!valid" text @click="sellerEdit(seller)">EDITAR REVENDEDOR</v-btn>
        </v-card-actions>

        <v-card-actions v-if="edit_comission == true">
            <v-btn color="red darken-1" text @click="close">CANCELAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" :disabled="!valid" text @click="comissionEdit()">SALVAR COMISSÕES</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
export default {
    name: "ModalSellerNewEdit",

    props: {
        checkedSellers: Array,
        seller: Object,
        edit_comission: Boolean,
        edit: Boolean,
        register: Boolean,
        dialog: Boolean,
    },


    data: () => ({
        // SNACKBAR
        snackbar: {},

        lotteries: [],

        comission: {
            lottery_id: 0,
            comission_11: 0,
            comission_14: 0,
            comission_16: 0,
            comission_18: 0,
            comission_21: 0,
            comission_19: 0,
            comission_instant: 0,
            comission_ligeirinha: 0,
        },

        points: [],
        authentication_types: [
            'automatica', 'supervisionada'
        ],

        loading: {
            lotteries: true,
            points: true,
        },

        // DIALOG 
        valid: false,
        checkbox: false,
        show: false,

        debit_limit: [
            value => !isNaN(value) || "Informe somente números.",
        ],
    }),

    created() {
        this.loadLotteries();
        this.loadPoints();
    },

    watch: {
        // WATCH IF THE SELLER CHOOSES EDIT TO LOAD POINTS
        edit: function (val) {
            !val || this.loadPoints();
        },

        // WATCH THE VALUE TO OPEN THE MODAL
        dialog: function (val) {
            this.show = val;
        },

        // IF SELLER CLICKS OUTSIDE THE DIALOG 
        show: function (val) {
            val || this.close();
        }
    },

    methods: {
        // CLOSE MODAL   
        close() {
            this.$emit('close');
            this.$refs.formSeller.reset();
        },

        // RELOAD PARENT
        reload() {
            this.close();
            this.$emit('reload');
        },

        // LOAD SELLER POINTS
        loadPoints() {
            this.loading.points = true;

            this.$api
                .get("/points", {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                })
                .then(response => {
                    this.points = response.data;
                    this.loading.points = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar os pontos de venda.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);

                    this.loading.points = false;
                });
        },

        loadLotteries() {
            this.loading.lotteries = true;

            this.$api
                .get("/lotteries", {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                })
                .then(response => {
                    this.lotteries = response.data;
                    this.loading.lotteries = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar as loterias.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);

                    this.loading.lotteries = false;
                });
        },

        changeLottery (lottery_id, user_id) {

            this.$api
            .get(`/comissions/show`, {
                headers: {
                    Authorization: `Bearer ${this.accessToken()}`
                },
                params: {
                    user_id,
                    lottery_id: lottery_id
                }
            })
            .then(response => {
                this.comission = response.data
            })
            .catch(() => {
                this.snackbar.text = "O revendedor não possui comissão para a loteria selecionada.";
                this.snackbar.type = "warning";
                this.snackbarShow(this.snackbar);
                this.comission = {}
                this.comission.lottery_id = lottery_id
            }); 
        
        },

        comissionMultipleEdit(){

            if (this.$refs.formSeller.validate()) {

            this.comission.user_id = this.seller.user_id

            this.$api
                .patch(`/comissions/`, {...this.comission}, {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                })
                .then(() => {
                    this.snackbar.text = "Comissão atualizada com sucesso!";
                    this.snackbar.type = "green";
                    this.snackbarShow(this.snackbar);
                    this.reload();
                })
                .catch(() => {
                    this.snackbar.text = "Erro na atualização do comissão!";
                    this.snackbar.type = "error";
                    this.snackbarShow(this.snackbar);
                });
            }
        },

        // EDITAR COMISSÃO
        comissionEdit () {
            if (this.$refs.formSeller.validate()) {

                this.comission.user_id = this.seller.user_id
                
                this.$api
                    .patch(`/comissions/`, {...this.comission}, {
                        headers: {
                            Authorization: `Bearer ${this.accessToken()}`
                        },
                    })
                    .then(() => {
                        this.snackbar.text = "Comissão atualizada com sucesso!";
                        this.snackbar.type = "green";
                        this.snackbarShow(this.snackbar);
                        this.reload();
                    })
                    .catch(() => {
                        this.snackbar.text = "Erro na atualização do comissão!";
                        this.snackbar.type = "error";
                        this.snackbarShow(this.snackbar);
                    });
            }
        },

        sellerEdit()  {
            if (this.$refs.formSeller.validate()) {

                this.$api
                    .patch(`/users/${this.seller.user_id}`, {...this.seller}, {
                        headers: {
                            Authorization: `Bearer ${this.accessToken()}`
                        },
                    })
                    .then(() => {
                        this.snackbar.text = "Revendedor atualizado com sucesso!";
                        this.snackbar.type = "green";
                        this.snackbarShow(this.snackbar);
                        this.reload();
                    })
                    .catch(() => {
                        this.snackbar.text = "Erro na atualização do revendedor!";
                        this.snackbar.type = "error";
                        this.snackbarShow(this.snackbar);
                    });
            }
        },

        // NEW SELLER
        sellerSave() {
            if (this.$refs.formSeller.validate()) {
 
                this.seller.username = this.seller.name
                
                this.$api
                .post(`/users/store`, this.seller, {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                })
                .then(() => {
                    this.snackbar.text = "Revendedor cadastrado com sucesso!";
                    this.snackbar.type = "green";
                    this.snackbarShow(this.snackbar);
                    this.reload()
                })
                .catch(() => {
                    this.snackbar.text = "Erro ao cadastrar novo revendedor!";
                    this.snackbar.type = "error";
                    this.snackbarShow(this.snackbar);
                });
                 
            } 

        },

    }
}
</script>
