<template>
<v-dialog v-model="show" max-width="800px">
    <v-card>
        <!-- TEXT CHANGE IF IS NEW OR EDIT -->
        <v-card-text v-if="register == true" class="pa-5 border-bottom">
            <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Cadastro de nova despesa.</h3>
            <h6 class="subtitle-2 font-weight-light">Preencha todas os campos abaixo e confirme o cadastro de uma nova despesa.</h6>
        </v-card-text>

        <v-card-text v-else class="pa-5 border-bottom">
            <h6 class="subtitle-2 font-weight-light">Preencha todas os campos abaixo e confirme a edição de uma despesa existente no sistema.</h6>
        </v-card-text>

        <v-card-text class="pa-5 border-bottom">
            <v-form v-model="valid" lazy-validation ref="formExpense">
                <v-row v-if="register == true" class="ma-0 pa-0 justify-space-between">

                    <!-- SEARCH BY DATE PERIOD -->
                    <v-col cols="12" lg="6" md="6" sm="12" xs="12" class="py-0 my-0">
                        <v-menu :close-on-content-click="true" transition="scale-transition" min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-on="on" v-bind="attrs" append-icon="mdi-calendar" hide-details outlined dense clearable v-model="formatSearchDate" label="Data" readonly></v-text-field>
                            </template>
                            <v-date-picker locale="pt-br" no-title color="primary" v-model="date_search"></v-date-picker>
                        </v-menu>
                    </v-col>

                    <!-- SEARCH BY REGION -->
                    <v-col cols="12" lg="6" md="6" sm="12" xs="12" class="py-0 my-0">
                        <v-autocomplete clearable no-data-text="Sem dados disponível." @change="regionChange" :loading="loading.regions" :disabled="loading.regions" v-model="expense.region_id" label="Região" color="primary" item-text="name" item-value="id" outlined dense :items="regions"/>
                    </v-col>

                    <v-col cols="12" lg="6" md="6" sm="12" xs="12" class="py-0 my-0">
                        <v-select dense label="Seção" @change="changeSession" v-model="expense.session_id" :rules="[value => !!value || 'Selecione a seção.']" :items="sessions" :loading="loading.sessions" :disabled="loading.sessions" item-text="name" item-value="id" no-data-text="Sem dados disponível." outlined></v-select>
                    </v-col>

                    <v-col cols="12" lg="6" md="6" sm="12" xs="12" class="py-0 my-0">
                        <v-select dense label="Ponto" @change="changePoint" v-model="expense.point_id" :rules="[value => !!value || 'Selecione o ponto.']" :items="points" :loading="loading.points" :disabled="loading.points" item-text="name" item-value="id" no-data-text="Sem dados disponível." outlined></v-select>
                    </v-col>

                    <v-col cols="12" lg="6" md="6" sm="12" xs="12" class="py-0 my-0">
                        <v-select dense label="Categoria" @change="changeCategories(category)" v-model="category" :rules="[value => !!value || 'Selecione a categoria.']" :items="categories" :loading="loading.categories" :disabled="loading.categories" item-text="name" return-object no-data-text="Sem dados disponível." outlined></v-select>
                    </v-col>

                    <v-col cols="12" lg="6" md="6" sm="12" xs="12" class="py-0 my-0">
                        <v-select dense label="SubCategoria" v-model="subcategory" :rules="[value => !!value || 'Selecione a subcategoria.']" :items="subcategories" :loading="loading.subcategories" :disabled="loading.subcategories" item-text="name" item-value="id" no-data-text="Sem dados disponível." outlined></v-select>
                    </v-col>

                    <v-col cols="12" lg="6" md="6" sm="12" xs="12" class="py-0 my-0">
                        <v-select dense label="Vendedor" v-model="expense.seller_id" :rules="[value => !!value || 'Selecione o vendedor.']" :items="sellers" :loading="loading.sellers" :disabled="loading.sellers" item-text="username" item-value="id" no-data-text="Sem dados disponível." outlined></v-select>
                    </v-col>

                    <v-col cols="12" lg="6" md="6" sm="12" xs="12" class="py-0 my-0">
                        <v-text-field dense label="Valor" v-model="expense.value" :rules="[value => !!value || 'Informe o valor da entrada.']" outlined clearable></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="py-0 my-0">
                        <v-textarea outlined clearable  label="Descrição" v-model="expense.description" :rules="[value => !!value || 'Informe a descrição.']"></v-textarea>
                    </v-col>
            
                </v-row>

                <v-row v-else class="ma-0 pa-0 justify-space-between">
                    <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="py-0 my-0">
                        <v-text-field dense label="Valor" v-model="expense.value" :rules="[value => !!value || 'Informe o valor da entrada']" outlined clearable></v-text-field>
                    </v-col>
                </v-row>

            </v-form>
        </v-card-text>

        <!-- ACTIONS CHANGE IF IS NEW OR EDIT -->
        <v-card-actions v-if="register == true">
            <v-btn color="red darken-1" text @click="close">CANCELAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" :disabled="!valid" text @click="expenseSave">NOVA DESPESA</v-btn>
        </v-card-actions>

        <v-card-actions v-else>
            <v-btn color="red darken-1" text @click="close">CANCELAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" :disabled="!valid" text @click="expenseUpdate">SALVAR ALTERAÇÃO</v-btn>
        </v-card-actions>
        

    </v-card>
</v-dialog>
</template>

<script>
export default {
    name: "ModalExpenseNewEdit",

    props: {
        expense: Object,
        edit: Boolean,
        register: Boolean,
        dialog: Boolean,
    },

    data: () => ({
        // SNACKBAR
        snackbar: {},
        category: {},
        subcategory: {},
        
        // COMPONENT
        points: [],
        sessions: [],
        regions: [],
        sellers: [],
        categories: [],
        subcategories: [],

        disabled: {
            expenses: false,
            points: false,
            sessions: false,
            sellers: false,
            regions: false,
            categories: false
        },
        
        loading: {
            expense: false,
            points: false,
            sessions: false,
            regions: false,
            sellers: false,
            categories: false
        },

        date_search: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        // DIALOG 
        valid: false,
        checkbox: false,
        show: false,
    }),

    watch: {
        // WATCH THE VALUE TO OPEN THE MODAL
        dialog: function (val) {
            this.show = val;
        },

        edit: function () {
            if(this.edit == true) {
                this.loadPoints();
                this.loadSellers();
            }
        },

        // IF USER CLICKS OUTSIDE THE DIALOG 
        show: function (val) {
            val || this.close();
        },
    },

    computed: {
        // FORMAT DATE
        formatSearchDate: {
            get() {
                return this.formatDate(this.date_search);
            },

            set() {
                this.date_search = null;
            }
        },
    },

    created() {
        this.loadRegions();
        this.loadSessions();
    },

    methods: {
        changeCategories(category) {
            this.subcategories = null;
            this.subcategory = null;
            this.subcategories = category.subcategories;
        },

        // CLOSE MODAL   
        close() {
            this.resetFields();
            this.disabled.expenses = true;
            this.$emit('close');
            this.$refs.formExpense.reset();
        },

        // RELOAD PARENT
        reload() {
            this.close();
            this.$emit('reload');
        },

        resetFields() {
            this.expenses =  []
        },

        changeSession() {
            this.loadPoints();
        },

        changePoint() {
            this.loadSellers();
        },

        regionChange(){
            this.loadSessions();
            this.loadCategories();
        },
  
        loadRegions() {
            this.loading.regions = true;

            this.$api
                .get("/regions", {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                })
                .then((response) => {
                    this.regions = response.data;
                    this.loading.regions = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar as regiões";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);

                    this.loading.regions = false;
                });
        },

        loadSessions() {
            this.loading.sessions = true;

            this.$api
                .get("/sessions", {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                    params: {
                        search_region: [this.expense.region_id]
                    }
                })
                .then((response) => {
                    this.sessions = response.data;
                    this.loading.sessions = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar as sessões.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);

                    this.loading.sessions = false;
                });
        },

        // LOAD SELLER POINTS
        loadPoints() {
            this.loading.points = true;

            this.$api
                .get("/points", {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                    params: {
                        search_session: [this.expense.session_id]
                    }
                })
                .then(response => {
                    this.points = response.data;
                    this.loading.points = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar os pontos de venda.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);

                    this.loading.points = false;
                });
        },

        // LOAD SELLERS
        loadSellers() {
            this.loading.sellers = true;

            this.$api
                .get(`/users/sellers`, {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                    params: {
                        search_point: [this.expense.point_id]
                    }
                })
                .then(response => {
                    this.sellers = response.data;
                    this.loading.sellers = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar os revendedores.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);

                    this.loading.sellers = false;
                });
        },

        loadCategories() {
            const payload = {
                region_id: this.expense.region_id
            }

            this.$api.get('expense-category',{
                headers: {
                    Authorization: `Bearer ${this.accessToken()}`
                }, 
                params: payload 
                }).then((response)=>{
                    this.categories = response.data
                }).catch(()=>{
                this.snackbarShow({
                    text: 'Não foi possível carregar as categorias',
                    type: 'warning'
                });
            }).finally(()=>this.loading.table = false)
        },

        // nova despesa
        expenseSave() {

            if (this.$refs.formExpense.validate()) {

                this.expense.type = 'Normal'
                this.expense.created_at = this.date_search
                this.expense.expense_category_id = this.category.id
                this.expense.expense_subcategory_id = this.subcategory

                this.$api
                .post("/expenses", this.expense, {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    }
                })
                .then(() => {
                    this.snackbar.text = "Despesa cadastrada com sucesso!";
                    this.snackbar.type = "green";
                    this.snackbarShow(this.snackbar);
                    this.reload();
                })
                .catch(() => {
                    this.snackbar.text = "Erro ao cadastrar nova despesa!";
                    this.snackbar.type = "error";
                    this.snackbarShow(this.snackbar);
                    });
                
            }
        },

        // UPDATE EXISTING EXPENSE
        expenseUpdate(){
               if (this.$refs.formExpense.validate()) {
                this.$api
                .put(`/expenses/update`, this.expense, { // faltando argumentos
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    }
                })
                .then(() => {
                    this.snackbar.text = "Edição realizada com sucesso!";
                    this.snackbar.type = "green";
                    this.snackbarShow(this.snackbar);

                    this.reload();
                })
                .catch(() => {
                    this.snackbar.text = "Erro ao editar cobrança!";
                    this.snackbar.type = "error";
                    this.snackbarShow(this.snackbar);
                });
                
            }
        }
    }
}
</script>
