<template>
<div>
    <!-- CARD LOADER -->
    <v-skeleton-loader v-show="loading" type="image, divider, article, actions"></v-skeleton-loader>

    <!-- CARD USER -->
    <v-card v-show="!loading" elevation="2" outlined>
        <v-img height="166" :src="img">
            <v-card-title class="pa-0">
                <v-row class="mx-5 my-10">
                    <v-list-item>
                        <v-list-item-avatar size="70">
                            <v-icon dark size="70">mdi-account-circle</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title class="title white--text" v-text="user.username"></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-row>
            </v-card-title>
        </v-img>

        <!-- USER INFO -->
        <v-card-text class="py-0">
            <v-row class="mt-3 py-0 ma-0">
                <v-col cols="12" lg="6" md="6" sm="12" xs="12" class="pa-0 ma-0" v-for="(item, index) in info" :key="index">
                    <v-list-item two-line class="ma-0">
                        <v-list-item-avatar>
                            <v-icon color="black" v-text="item.icon" large></v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-subtitle class="overline" v-text="item.title">
                            </v-list-item-subtitle>
                            <v-list-item-title class="text-h6" v-if="item.value == 'banks'">
                                <v-col cols="12" v-for="(bank, index) in user.banks" :key="index" class="ma-0 pa-0">
                                    {{ bank.name }}
                                </v-col>
                            </v-list-item-title>

                            <v-list-item-title class="text-h6" v-else>
                                {{ editUserInfo(item.value) }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
            </v-row>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="password" text color="primary">ALTERAR SENHA</v-btn>
            <v-btn @click="edit" text color="primary">EDITAR</v-btn>
        </v-card-actions>
    </v-card>
</div>
</template>

<script>
import buimg from "../../assets/images/bu-image-2x.jpg";

export default {
    name: 'BaseCardUser',

    props: {
        user: Object,
        loading: Boolean,
        info: Array
    },

    data: () => ({
        // IMAGE 
        img: buimg,

        type: [
            {
                name: "Master",
                value: "master"
            },
            {
                name: "Admin de região",
                value: "admin_regiao"
            },
            {
                name: "Subadmin",
                value: "sub_admin"
            },
            {
                name: "Auxiliar",
                value: "auxiliar"
            },
            {
                name: "Gerente",
                value: "gerente"
            },
            {
                name: "Operador",
                value: "operador"
            },
            {
                name: "Revendedor",
                value: "cambista"
            },
        ],
    }),

    methods: {
        // OPEN MODAL PASSWORD CHANGE 
        password() {
            this.$emit('password');
        },

        // OPEN MODAL EDIT USER 
        edit() {
            this.$emit('edit');
        },

        // RETURNING USER INFO 
        editUserInfo(index) {
            for (var property in this.user) {
                if (property == index) {

                    if (property == 'created_at') {
                        return new Date(this.user[property]).toLocaleDateString();
                    }

                    if (property == 'type') {
                        return this.type[this.type.findIndex(user => user.value == this.user[property])].name;
                    }

                    return this.user[property];
                }
            }
        }
    }
}
</script>
