<template>
<v-dialog v-model="show" max-width="800px">
    <v-card>
        <!-- TEXT CHANGE IF IS NEW OR EDIT -->
        <v-card-text v-if="register == true" class="pa-5 border-bottom">
            <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Cadastro de novo prêmio.</h3>
            <h6 class="subtitle-2 font-weight-light">Preencha todas os campos abaixo e confirme o cadastro de um novo prêmio.</h6>
        </v-card-text>

        <v-card-text v-else class="pa-5 border-bottom">
            <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Edição de prêmio: <b v-text="award.name"></b>.</h3>
            <h6 class="subtitle-2 font-weight-light">Preencha todas os campos abaixo e confirme a edição do cadastro de um prêmio existente no sistema.</h6>
        </v-card-text>

        <v-card-text class="pa-5 border-bottom">
            <v-form v-model="valid" lazy-validation ref="formAward">
                <v-row class="ma-0 pa-0 justify-space-between">
                   
                    <v-col cols="12" lg="6" md="6" sm="12" xs="12" class="py-0 my-0">
                        <v-text-field dense label="Nome do prêmio" v-model="award.name" :rules="[value => !!value || 'Informe o nome do prêmio']" outlined clearable></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="6" md="6" sm="12" xs="12" class="py-0 my-0">
                        <v-text-field dense label="Nome interno no prêmio" v-model="award.internal_name" :rules="[value => !!value || 'Informe o nome interno']" outlined clearable></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="py-0 my-0">
                        <v-text-field dense label="Peso" v-model="award.weight" :rules="[value => !!value || 'Informe o peso']" outlined clearable></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="py-0 my-0">
                        <v-textarea dense outlined clearable hide-details auto-grow v-model="award.description" label="Descrição"></v-textarea>
                    </v-col>

                </v-row>
            </v-form>
        </v-card-text>

        <!-- ACTIONS CHANGE IF IS NEW OR EDIT -->
        <v-card-actions v-if="register == true">
            <v-btn color="red darken-1" text @click="close">CANCELAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" :disabled="!valid" text @click="awardSave">NOVO PRÊMIO</v-btn>
        </v-card-actions>

        <v-card-actions v-else>
            <v-btn color="red darken-1" text @click="close">CANCELAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" :disabled="!valid" text @click="awardUpdate">Salvar alteração</v-btn>
        </v-card-actions>
        

    </v-card>
</v-dialog>
</template>

<script>
export default {
    name: "ModalAwardNewEdit",

    props: {
        award: Object,
        edit: Boolean,
        register: Boolean,
        dialog: Boolean,
    },

    data: () => ({
        // SNACKBAR
        snackbar: {},
        
        // COMPONENT

        modalities: [],
        awards: [],
        sessions: true,

        disabled: {
            awards: true,
        },
        
        loading: {
            awards: true,
        },

        // DIALOG 
        valid: false,
        checkbox: false,
        show: false,
    }),

    watch: {
        // WATCH THE VALUE TO OPEN THE MODAL
        dialog: function (val) {
            this.show = val;
        },

        // IF USER CLICKS OUTSIDE THE DIALOG 
        show: function (val) {
            val || this.close();
        },
    },

    computed: {
        // FORMAT DATE
        formatNewDate: {
            get() {
                return this.formatDate(this.ex_date);
            },

            set() {
                this.ex_date = null;
            }
        },
    },

    created() {
        this.loadAwards()
    },

    methods: {
        // CLOSE MODAL   
        close() {
            this.resetFields();
            this.disabled.modalities = true;
            this.disabled.awards = true;
            this.$emit('close');
            this.$refs.formAward.reset();
        },

        // RELOAD PARENT
        reload() {
            this.close();
            this.$emit('reload');
        },


        // LOAD AWARDS
        loadAwards() {

            this.disabled.awards = false;
            this.loading.awards = true;

            this.$api
                .get(`/awards`, {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                })
                .then((response) => {
                    this.awards = response.data;
                    this.loading.awards = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar os prêmios.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);

                    this.loading.awards = false;
                });

        },

        resetFields() {
            this.awards =  []
        },

        
       /*  setAward(award_id) {
        }, */
    
    /* 
        // EDIT SELLER AWARD
        awardEdit() {
            if (this.$refs.formAward.validate()) {
                this.$api
                    .put(`/awards/${this.award.id}`, this.award, {
                        headers: {
                            Authorization: `Bearer ${this.accessToken()}`
                        }
                    })
                    .then(() => {
                        this.snackbar.text = "Ponto de venda atualizada com sucesso!";
                        this.snackbar.type = "green";
                        this.snackbarShow(this.snackbar);

                        this.reload();
                    })
                    .catch(() => {
                        this.snackbar.text = "Erro ao atualizar o ponto de venda!";
                        this.snackbar.type = "error";
                        this.snackbarShow(this.snackbar);
                    });
            }
        }, */

        // NEW AWARD
        awardSave() {
          
            if (this.$refs.formAward.validate()) {
                this.$api
                .post("/awards", this.award, {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    }
                })
                .then(() => {
                    this.snackbar.text = "Prêmio cadastrado com sucesso!";
                    this.snackbar.type = "green";
                    this.snackbarShow(this.snackbar);

                     this.reload();
                })
                .catch(() => {
                    this.snackbar.text = "Erro ao cadastrar novo prêmio!";
                    this.snackbar.type = "error";
                    this.snackbarShow(this.snackbar);
                });
                
            }
        },


        // UPDATE EXISTING AWARD
        awardUpdate(){
               if (this.$refs.formAward.validate()) {
                this.$api
                .post(`/awards/update`, this.award, { // faltando argumentos
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    }
                })
                .then(() => {
                    this.snackbar.text = "Edição realizada com sucesso!";
                    this.snackbar.type = "green";
                    this.snackbarShow(this.snackbar);

                     this.reload();
                })
                .catch(() => {
                    this.snackbar.text = "Erro ao editar prêmio!";
                    this.snackbar.type = "error";
                    this.snackbarShow(this.snackbar);
                });
                
            }
        }
    }
}
</script>
