<template>
<v-dialog v-model="display" max-width="800px ">
    <v-card>
        <v-card-text class="pa-5 border-bottom">
            <v-data-table  no-data-text="Não há loterias." no-results-text="Sem resultados." hide-default-footer loading-text="Carregando..." disable-sort class="elevation-1" :headers="headers" :items="region.admin_regions">              

            </v-data-table>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="close">FECHAR</v-btn>
        </v-card-actions>

    </v-card>
</v-dialog>
</template>

<script>
export default {
    name: "ModalRegionSubAdmins",

    props: {
        region: Object,
        show: Boolean
    },

    data: () => ({

        snackbar: {},

        headers: [
            {
                text: "Nome",
                value: "name",
                class: "primary white--text"
            },
        ],

        valid: false,
        display: false,
    }),

    watch: {
        // WATCH THE VALUE TO DO THE REQUEST AND OPEN THE MODAL
        show: function (val) {
            this.display = val;
        },

        // IF USER CLICKS OUTSIDE THE DIALOG 
        display: function (val) {
            val || this.close();
        }
    },

    methods: {
        // CLOSE MODAL 
        close() {
            this.display = false;
            this.$emit('close');
        },

     
    }
}
</script>
     