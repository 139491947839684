<template>
<v-dialog v-model="show" max-width="800px">
    <v-card>
        <v-card-text class="pa-5 border-bottom">
            <h3 class="title blue-grey--text text--darken-2 font-weight-regular">
                Adicionar saldo ao vendedor:
                <b v-text="seller.username"></b>.
            </h3>
            <h6 class="subtitle-2 font-weight-light">Preencha o campo abaixo com o novo valor a ser adicionado ao saldo do vendedor.</h6>
        </v-card-text>

        <v-card-text class="pa-5 border-bottom">
            <v-form v-model="valid" lazy-validation ref="formSellerBalance">
                <v-row>
                    <v-col cols="12" class="py-0 my-0">
                        <vuetify-money :label="'Saldo do Vendedor'" v-model="new_value" :rules="[value => !!value || 'Informe o valor.']" :outlined="true" :dense="true" :options="options" />
                    </v-col>
                    <v-col cols="12" class="py-0 my-0">
                        <v-textarea outlined clearable rows="3" row-height="25" hide-details auto-grow v-model="observation" label="Observação"></v-textarea>
                    </v-col>
                    <v-col cols="12" xs="12" class="py-0 my-0">
                        <v-checkbox required v-model="checkbox" :rules="[value => !!value || 'É necessário confirmar.']" label="Confirmar saldo?" color="green"></v-checkbox>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>

        <v-card-actions>
            <v-btn color="red darken-1" text @click="close">CANCELAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" :disabled="!valid" text @click="sellerEditBalance">EDITAR SALDO</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
export default {
    name: "ModalSellerAddBalance",

    props: {
        seller: Object,
        dialog: Boolean,
    },

    data: () => ({
        // SNACKBAR
        snackbar: {},

        // DIALOG
        valid: false,
        checkbox: false,
        show: false,
        new_value: 0,
        observation: "",

        // OPTIONS FOR VUETIFY MONEY 
        options: {
            locale: "pt-BR",
            prefix: "R$",
            suffix: "",
            length: 11,
            precision: 2
        },
    }),

    watch: {
        // WATCH THE VALUE TO OPEN THE MODAL
        dialog: function (val) {
            this.show = val;
        },

        // IF USER CLICKS OUTSIDE THE DIALOG
        show: function (val) {
            val || this.close();
        }
    },

    methods: {
        // CLOSE MODAL
        close() {
            this.$emit("close");
            this.$refs.formSellerBalance.reset();
            this.new_value = 0;
        },

        // RELOAD PARENT
        reload() {
            this.close();
            this.$emit("reload");
        },

        // EDIT SELLER'S BALANCE
        sellerEditBalance() {
            if (this.$refs.formSellerBalance.validate()) {

                let new_balance = {
                    user_id: this.seller.id,
                    value: this.new_value,
                    note: this.observation
                }

                this.$api
                    .post("/users/add-balance", new_balance, {
                        headers: {
                            Authorization: `Bearer ${this.accessToken()}`
                        },
                    })
                    .then(() => {
                        this.snackbar.text = "Novo saldo adicionado com sucesso!";
                        this.snackbar.type = "green";
                        this.snackbarShow(this.snackbar);

                        this.reload();
                    })
                    .catch(() => {
                        this.snackbar.text = "Erro ao adicionar novo saldo!";
                        this.snackbar.type = "error";
                        this.snackbarShow(this.snackbar);
                    });
            }
        }
    }
};
</script>
