<template>
<v-dialog v-model="show" max-width="800px">
    <v-card>
        <!-- TEXT CHANGE IF IS REGISTER OR EDIT -->
        <v-card-text class="pa-5 border-bottom">
            <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Alterar senha.</h3>
            <h6 class="subtitle-2 font-weight-light">Preencha todas os campos abaixo e confirme para alterar a senha do usuário.</h6>
        </v-card-text>

        <!-- FORM -->
        <v-card-text class="pa-5 border-bottom">
            <v-form v-model="valid" lazy-validation ref="formUser">
                <v-row>
                  
                    <v-col cols="12" lg="6" md="6" sm="12" xs="12" class="py-0 my-0">
                        <v-text-field dense label="Senha de usuário" v-model="user.password" :rules="[value => !!value || 'Informe a senha de usuário.']" outlined clearable></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="6" md="6" sm="12" xs="12" class="py-0 my-0">
                        <v-text-field dense label="Confirmar senha" v-model="user.confirmPassword" outlined clearable></v-text-field>
                    </v-col>

                </v-row>
            </v-form>
        </v-card-text>

        <!-- ACTIONS CHANGE IF IS REGISTER OR EDIT -->
        <v-card-actions>
            <v-btn color="red darken-1" text @click="close">CANCELAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" :disabled="!valid" text @click="changePassword">SALVAR NOVA SENHA</v-btn>
        </v-card-actions>

       
    </v-card>
</v-dialog>
</template>

<script>
export default {
    name: "ModalChangePassword",

    props: {
        user: Object,
        dialog: Boolean,
    },


    data: () => ({
        // SNACKBAR
        snackbar: {},


        // DIALOG 
        valid: false,
        checkbox: false,
        show: false,
       
    }),

    created() {
    },

    watch: {
    
        // WATCH THE VALUE TO OPEN THE MODAL
        dialog: function (val) {
            console.log(this.user)
            this.show = val;
        },

        // IF user CLICKS OUTSIDE THE DIALOG 
        show: function (val) {
            val || this.close();
        }
    },

    methods: {
        // CLOSE MODAL   
        close() {
            this.$emit('close');
            this.$refs.formUser.reset();
        }, 

        // NEW user
        changePassword() {
            if (this.$refs.formUser.validate()) {

                this.user.user_id ? '' : this.user.user_id = this.user.id

                this.$api
                .get(`/changepassword/${this.user.user_id}`, {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                    params: {
                        password: this.user.password,
                        confirmPassword: this.user.confirmPassword
                    }
                })
                .then(() => {
                    this.snackbar.text = "Senha alterada com sucesso!";
                    this.snackbar.type = "green";
                    this.snackbarShow(this.snackbar);
                    this.close()
                })
                .catch(() => {
                    this.snackbar.text = "Erro ao mudar senha";
                    this.snackbar.type = "error";
                    this.snackbarShow(this.snackbar);
                });
                 
            } 

        },

    }
}
</script>
