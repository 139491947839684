
<template>
<v-dialog v-model="show" max-width="800px">
    <v-card>
        <!-- TEXT CHANGE IF IS NEW OR EDIT -->
        <v-card-text v-if="register == true" class="pa-5 border-bottom">
            <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Cadastro de prêmios para cada loteria.</h3>
            <h6 class="subtitle-2 font-weight-light">Preencha todas os campos abaixo e confirme o cadastro de uma nova aposta.</h6>
        </v-card-text>

        <v-card-text v-if="register == true" class="pa-5 border-bottom">
            <v-form v-model="valid" lazy-validation ref="formAward">
                <v-row class="ma-0 pa-0 justify-space-between">

                    <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="py-0 my-0">
                        <v-select dense no-data-text="Sem dados disponíveis." outlined :rules="[value => !!value || 'Selecione a região']" :loading="loading.regions" :disabled="loading.regions" item-text="name" item-value="id" v-model="avaliableAward.region_id" :items="regions" label="Região" @change="changeRegion()"></v-select>
                    </v-col> 
                   
                    <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="py-0 my-0">
                        <v-select dense no-data-text="Sem dados disponíveis." outlined :rules="[value => !!value || 'Selecione a loteria']" :loading="loading.lotteries" :disabled="loading.lotteries" item-text="lottery_name" item-value="lottery_id" v-model="avaliableAward.lottery_id" :items="lotteries" label="Loteria" @change="changeLottery()"></v-select>
                    </v-col> 

                    <v-col cols="12" lg="6" md="6" sm="12" xs="12" class="py-0 my-0">
                        <v-select dense multiple no-data-text="Sem dados disponíveis." outlined :rules="[value => !!value || 'Selecione uma modalidade.']" :loading="loading.modalities" :disabled="loading.modalities" item-text="name" item-value="id" v-model="avaliableAward.modalities" :items="modalities" label="Modalidade"></v-select>
                    </v-col>

                    <v-col cols="12" lg="6" md="6" sm="12" xs="12" class="py-0 my-0">
                        <v-select dense multiple no-data-text="Sem dados disponíveis." outlined :rules="[value => !!value || 'Selecione um prêmio.']" :loading="loading.awards" :disabled="loading.awards" item-text="name" item-value="id" v-model="avaliableAward.awards" :items="awards" label="Prêmio"></v-select>
                    </v-col>

                </v-row>
            </v-form>
        </v-card-text>

        <!-- ACTIONS CHANGE IF IS NEW OR EDIT -->
        <v-card-actions >
            <v-btn color="red darken-1" text @click="close">CANCELAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" :disabled="!valid" text @click="awardSave">NOVO PRÊMIO</v-btn>
        </v-card-actions>

    </v-card>
</v-dialog>
</template>

<script>

/* ARRUMAR SELECT DE MODALIDADES */

export default {
    name: "ModalAwardNewEdit",

    props: {
        register: Boolean,
        dialog: Boolean,
    },

    data: () => ({
        // SNACKBAR
        snackbar: {},
        item:{},
        
        // COMPONENT
        selectedAwards: [],
        selectedModalities: [],
        
        avaliableAward: {},
        
        modalities: [],
        awards: [],
        lotteries: [],
        
        loading: {
            modalities: false,
            lotteries: false,
            awards: false,
            regions: false
        },

        // DIALOG 
        valid: false,
        checkbox: false,
        show: false,
    }),

    watch: {
        // WATCH THE VALUE TO OPEN THE MODAL
        dialog: function (val) {
            this.show = val;
        },

        // IF USER CLICKS OUTSIDE THE DIALOG 
        show: function (val) {
            val || this.close();
        },
    },

    computed: {
        // FORMAT DATE
        formatNewDate: {
            get() {
                return this.formatDate(this.ex_date);
            },

            set() {
                this.ex_date = null;
            }
        },
    },

    created() { 
        this.loadRegions() 
        this.loadAwards()      
    },
    
    methods: {
        // CLOSE MODAL   
        close() {
            this.resetFields();
            this.$emit('close');
            this.$refs.formAward.reset();
        },

        // RELOAD PARENT
        reload() {
            this.close();
            this.$emit('reload');
        },

        // LOAD AWARDS
        loadAwards() {
            
            this.$api
                .get(`/awards`, {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                })
                .then((response) => {
                    this.awards = response.data;
                    this.loading.awards = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar os prêmios.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);

                    this.loading.awards = false;
                });

        },

        // LOAD AWARDS
        loadRegions() {
            this.loading.regions = true;

            this.$api
                .get(`/regions`, { 
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                })
                .then((response) => {
                    this.regions = response.data;
                    this.loading.regions = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar as regiões.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);

                    this.table.loading = false;
                });

              
        },

        resetFields() {
            this.selectedAwards = []
            this.selectedModalities = []
            this.modalities = []
        },

        // LOAD MODALITIES
        loadModalities() {

            this.loading.modalities = true;
             
            this.$api
                .get(`/modalities`, {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                    params: {
                        lottery_id: this.avaliableAward.lottery_id,
                        region_id: this.avaliableAward.region_id
                    }
                })
                .then((response) => {
                    this.modalities = response.data;
                    this.loading.modalities = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar as modalidades.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);

                    this.loading.modalities = false;
                });

                
        },

        // LOAD LOTTERIES
        loadLotteries() {
            this.loading.lotteries = true;

            this.$api
                .get(`/lotteries/region_lotteries/${this.avaliableAward.region_id}`, { 
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                })
                .then((response) => {
                    this.lotteries = response.data;
                    this.loading.lotteries = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar as loterias.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);

                    this.loading.lotteries = false;
                });
        },

        changeRegion() {
            this.loadLotteries();
        },
        changeLottery() {
            this.loadModalities();
        },

        // NEW SELLER AWARD
        awardSave() {
          
            if (this.$refs.formAward.validate()) {

                this.$api
                .post("/awards/avaliables", this.avaliableAward, {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    }
                })
                .then(() => {
                    this.snackbar.text = "Relacionamento cadastrado com sucesso!";
                    this.snackbar.type = "green";
                    this.snackbarShow(this.snackbar);
                })
                .catch(() => {
                    this.snackbar.text = "Erro ao cadastrar nova relacionamento!";
                    this.snackbar.type = "error";
                    this.snackbarShow(this.snackbar);
                });
        
        
                this.reload();

            }
             
        },
    }
}
</script>
