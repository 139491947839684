<template>
<v-dialog v-model="show" max-width="800px">
    <v-card>
        <!-- TEXT CHANGE IF IS NEW OR EDIT -->
        <v-card-text v-if="register == true" class="pa-5 border-bottom">
            <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Cadastro de nova modalidade.</h3>
            <h6 class="subtitle-2 font-weight-light">Preencha todas os campos abaixo e confirme o cadastro de uma nova modalidade.</h6>
        </v-card-text>

        <v-card-text v-else class="pa-5 border-bottom">
            <h6 class="subtitle-2 font-weight-light">Preencha todas os campos abaixo e confirme a edição de uma modalidade existente no sistema.</h6>
        </v-card-text>

        <v-card-text class="pa-5 border-bottom">
            <v-form v-model="valid" lazy-validation ref="formModality">
                <v-row>

                    <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="py-0 my-0">
                        <v-autocomplete dense clearable :rules="[value => !!value || 'Informe a região.']" no-data-text="Sem dados disponível." :loading="loading.regions" :disabled="loading.regions" v-model="modality.region_id" label="Região" color="primary" item-text="name" item-value="id" outlined :items="regions"/>
                    </v-col>

                    <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="py-0 my-0">
                        <v-autocomplete dense clearable :rules="[value => !!value || 'Informe a loteria.']" no-data-text="Sem dados disponível." :loading="loading.lotteries" :disabled="loading.lotteries" v-model="modality.lottery_id" label="Loteria" color="primary" item-text="name" item-value="id" outlined :items="lotteries"/>
                    </v-col>

                    <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="py-0 my-0">
                        <v-autocomplete dense clearable :rules="[value => !!value || 'Informe a modalidade.']" no-data-text="Sem dados disponível." v-model="modality.name" label="Modalidade" color="primary" item-text="name" item-value="name" outlined :items="modalities"/>
                    </v-col>

                    <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="py-0 my-0">
                        <v-text-field dense outlined clearable :rules="[value => !!value || 'Informe a cotação.']" v-model="modality.default_cotation" label="Cotação"></v-text-field>
                    </v-col>

                </v-row>
            </v-form>
        </v-card-text>


        <!-- ACTIONS CHANGE IF IS NEW OR EDIT -->
        <v-card-actions v-if="register == true">
            <v-btn color="red darken-1" text @click="close">CANCELAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" :disabled="!valid" text @click="modalitySave">NOVA MODALIDADE</v-btn>
        </v-card-actions>

        <v-card-actions v-else>
            <v-btn color="red darken-1" text @click="close">CANCELAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" :disabled="!valid" text @click="modalityUpdate">Salvar alteração</v-btn>
        </v-card-actions>
        

    </v-card>
</v-dialog>
</template>

<script>
export default {
    name: "ModalModalityNewEdit",

    props: {
        modality: Object,
        edit: Boolean,
        register: Boolean,
        dialog: Boolean,
    },

    data: () => ({
        // SNACKBAR
        snackbar: {},
        lotteries: [],
        regions: [],

        loading: {
            regions: false,
            lotteries: false
        },

        modalities: [
            {
                name: 'Grupo',
                initials: 'grupo',
                short_name: 'G'
            },
            {
                name: 'Duque de Grupo',
                initials: 'duque_grupo',
                short_name: 'DG'
            },
            {
                name: 'Duque de Grupo c/ repetição',
                initials: 'duque_grupo_repeticao',
                short_name: 'DGCR'
            },
            {
                name: 'Terno de Grupo',
                initials: 'terno_grupo',
                short_name: 'TG'
            },
            {
                name: 'Terno de Grupo c/ repetição',
                initials: 'terno_grupo_repeticao',
                short_name: 'TGCR'
            },
            {
                name: 'Terno de Grupo chaveado',
                initials: 'terno_grupo_chaveado',
                short_name: 'TGC'
            },
            {
                name: 'Dezena',
                initials: 'dezena',
                short_name: 'D'
            },
            {
                name: 'Dezena Invertida',
                initials: 'dezena_invertida',
                short_name: 'DI'
            },
            {
                name: 'Duque de Dezena',
                initials: 'duque_dezena',
                short_name: 'DD'
            },
            {
                name: 'Duque de Dezena c/ repetição',
                initials: 'duque_dezena_repeticao',
                short_name: 'DDCR'
            },
            {
                name: 'Terno de Dezena',
                initials: 'terno_dezena',
                short_name: 'TD'
            },
            {
                name: 'Terno de Dezena c/ repetição',
                initials: 'terno_dezena_repeticao',
                short_name: 'TDCR'
            },
            {
                name: 'Centena',
                initials: 'centena',
                short_name: 'C'
            },
            {
                name: 'Centena Invertida',
                initials: 'centena_invertida',
                short_name: 'C'
            },
            {
                name: 'Milhar',
                initials: 'milhar',
                short_name: 'M'
            },
            {
                name: 'Milhar Brinde',
                initials: 'milhar_brinde',
                short_name: 'MB'
            },
            {
                name: 'Milhar Invertida',
                initials: 'milhar_invertida',
                short_name: 'MI'
            },
            {
                name: 'Milhar e Centena',
                initials: 'milhar_centena',
                short_name: 'MC'
            },
            {
                name: 'Milhar e centena Invertida',
                initials: 'milhar_centena_invertida',
                short_name: 'MCI'
            },
            {
                name: 'Passe',
                initials: 'passe',
                short_name: 'P'
            },
            {
                name: 'Passe de ida',
                initials: 'passe_ida',
                short_name: 'PI'
            },
            {
                name: 'Passe de ida e volta',
                initials: 'passe_ida_volta',
                short_name: 'PID'
            },
            {
                name: 'Milhar vai e volta',
                initials: 'milhar_vai_volta',
                short_name: 'MVV'
            },
            {
                name: 'Milhar aproximada',
                initials: 'milhar_aproximada',
                short_name: 'MA'
            },
            {
                name: 'Milhar de ouro',
                initials: 'milhar_ouro',
                short_name: 'MO'
            },
            {
                name: 'Centena vai e volta',
                initials: 'centena_vai_volta',
                short_name: 'CVV'
            },
            {
                name: 'Centena aproximada',
                initials: 'centena_aproximada',
                short_name: 'CA'
            },
            {
                name: 'Grupo par',
                initials: 'grupo_par',
                short_name: 'GP'
            },
            {
                name: 'Grupo impar',
                initials: 'grupo_impar',
                short_name: 'GI'
            },
            {
                name: 'Quina',
                initials: 'quina'
            },
            {
                name: 'Quadra',
                initials: 'quadra'
            },
            {
                name: 'Terno',
                initials: 'Terno'
            },
            {
                name: 'Passe especial',
                initials: 'passe_especial',
                short_name: 'PE'
            },
            {
                name: 'Passe especial combinado',
                initials: 'passe_especial_combinado',
                short_name: 'PEC'
            },
            {
                name: 'MCITDC',
                initials: 'mcitdc',
                short_name: 'mcitdc'
            },
            {
                name: 'MCIDDC',
                initials: 'mciddc',
                short_name: 'mciddc'
            },
            {
                name: 'CIDDC',
                initials: 'ciddc',
                short_name: 'ciddc'
            },
            {
                name: 'CITDC',
                initials: 'citdc',
                short_name: 'citdc'
            }
        ],

        // DIALOG 
        valid: false,
        checkbox: false,
        show: false,
    }),

    watch: {
        // WATCH THE VALUE TO OPEN THE MODAL
        dialog: function (val) {
            this.show = val;
        },

        // IF USER CLICKS OUTSIDE THE DIALOG 
        show: function (val) {
            val || this.close();
        },
    },

    computed: {
        // FORMAT DATE
        formatNewDate: {
            get() {
                return this.formatDate(this.ex_date);
            },

            set() {
                this.ex_date = null;
            }
        },
    },

    created() {
        this.loadRegions();
        this.loadLotteries();
    },

    methods: {
        // CLOSE MODAL   
        close() {
            this.resetFields();
            this.$emit('close');
            this.$refs.formModality.reset();
        },

        // RELOAD PARENT
        reload() {
            this.close();
            this.$emit('reload');
        },

        resetFields() {
            this.modality = {}
        },

        loadLotteries() {
            this.loading.lotteries = true;

            this.$api
                .get("/lotteries", {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                    params: {
                        region_search: this.modality.region_id
                    }
                })
                .then((response) => {
                    this.lotteries = response.data;
                    this.loading.lotteries = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar as loterias.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);

                    this.loading.lotteries = false;
                });
        },

        loadRegions() {
            this.loading.regions = true;

            this.$api
                .get("/regions", {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                })
                .then((response) => {
                    this.regions = response.data;
                    this.loading.regions = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar as regiões.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);

                    this.loading.regions = false;
                });
        },

        // NEW SELLER POINT
        modalitySave() {
            if (this.$refs.formModality.validate()) {

                const found = this.modalities.find(modality => modality.name == this.modality.name);
                this.modality.initials = found.initials
                this.modality.short_name = found.short_name

                this.$api
                    .post("/modalities", this.modality, {
                        headers: {
                            Authorization: `Bearer ${this.accessToken()}`
                        }
                    })
                    .then(() => {
                        this.snackbar.text = "Modalidade cadastrada com sucesso!";
                        this.snackbar.type = "green";
                        this.snackbarShow(this.snackbar);

                        this.reload();
                    })
                    .catch(() => {
                        this.snackbar.text = "Erro ao cadastrar nova modalidade!";
                        this.snackbar.type = "error";
                        this.snackbarShow(this.snackbar);
                    });
            }
        },

        // UPDATE EXISTING MODALITY
        modalityUpdate(){
               if (this.$refs.formModality.validate()) {
                this.$api
                .put(`/modalities/${this.modality.id}`, this.modality, { 
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    }
                })
                .then(() => {
                    this.snackbar.text = "Edição realizada com sucesso!";
                    this.snackbar.type = "green";
                    this.snackbarShow(this.snackbar);

                    this.reload();
                })
                .catch(() => {
                    this.snackbar.text = "Erro ao editar modalidade!";
                    this.snackbar.type = "error";
                    this.snackbarShow(this.snackbar);
                });
                
            }
        }
    }
}
</script>
