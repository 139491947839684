<template>
    <div :class="loggedUser().type === 'master' ? 'ismaster' : ''">
        <ModalSellersDashboard :date_search="date_search" :point_id="point_id" @close="dialog = false; point_id = null"
            :show="dialog"></ModalSellersDashboard>

        <header class="header-search">
            <!-- SEARCH BY DATES -->
            <v-row>
                <v-col cols="12" lg="4" md="4" sm="4" xs="12" class="pa-1">
                    <v-menu v-model="datepicker" :close-on-content-click="false" transition="scale-transition"
                        min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-on="on" v-bind="attrs" append-icon="mdi-calendar" hide-details outlined
                                dense clearable v-model="dateRangeText" label="Período de tempo"
                                readonly></v-text-field>
                        </template>
                        <v-date-picker locale="pt-br" no-title :max="new Date().toJSON()" color="primary"
                            v-model="date_search" range></v-date-picker>
                    </v-menu>
                </v-col>

                <!-- SEARCH BY REGION -->
                <v-col cols="12" lg="3" md="3" sm="3" xs="12" class="pa-1">
                    <v-autocomplete multiple class="input-color" clearable no-data-text="Sem dados disponível."
                        @change="regionChange" :loading="loading.regions" :disabled="loading.regions"
                        v-model="region_search" label="Região" color="primary" item-text="name" item-value="id" outlined
                        dense :items="regions" />
                </v-col>

                <!-- SEARCH BY SESSION -->
                <v-col cols="12" lg="3" md="3" sm="3" xs="12" class="pa-1">
                    <v-autocomplete class="input-color" multiple clearable no-data-text="Sem dados disponível."
                        :loading="loading.sessions" :disabled="loading.sessions" v-model="session_search" label="Seção"
                        color="primary" item-text="name" item-value="id" outlined dense :items="sessions" />
                </v-col>

                <v-col cols="12" lg="2" md="2" sm="2" xs="12" class="pa-1">
                    <v-btn color="btn-search" @click="searchAnalytics" height="35px">
                        <i class="fas fa-search"></i>
                    </v-btn>
                </v-col>
            </v-row>
        </header>

        <main>
            <div class="container-dashboard financial-summary">
                <div class="header-summary">
                    <h4 class="title-container">RESUMO</h4>

                    <div class="container-checkbox-awards">
                        <label class="switch" for="checkbox">
                            <input type="checkbox" id="checkbox" @click="searchAward" />
                            <div class="slider round">
                                <span class="text-checkbox-sorteado" v-if="this.search_awards === 'criado'">
                                    SORTEADO
                                </span>

                                <span class="text-checkbox-autenticado" v-else>
                                    AUTENTICADO
                                </span>
                            </div>
                        </label>
                    </div>
                </div>

                <div class="skeleton-loader">
                    <v-skeleton-loader class="mx-auto" max-width="300" type="text@12"
                        v-if="this.loading.financial_summary">
                    </v-skeleton-loader>
                </div>

                <div class="box-info-container" v-if="!this.loading.financial_summary">
                    <div class="info-financial">
                        <div class="info-financial-summary">
                            <span>VENDAS</span>
                            <span class="font-mono">{{ formatCurrency(financial_summary.vendas) }}</span>
                        </div>

                        <ProgressBar class="sales-progressbar" :value="100" :showValue="false" />

                        <span class="value-progressbar">
                            100 %
                        </span>
                    </div>

                    <div class="info-financial">
                        <div class="info-financial-summary">
                            <span>COMISSÃO</span>
                            <span class="font-mono">{{ formatCurrency(financial_summary.comissao) }}</span>
                        </div>

                        <ProgressBar class="commissions-progressbar"
                            :value="financial_summary.comissao | formatProgressBarValue(financial_summary.vendas)"
                            :showValue="false" />

                        <span class="value-progressbar">
                            {{ financial_summary.comissao | formatProgressBarValue(financial_summary.vendas) }} %
                        </span>
                    </div>

                    <div class="info-financial" v-if="this.search_awards == 'criado'">
                        <div class="info-financial-summary">
                            <span>PRÊMIOS SORTEADOS</span>
                            <span class="font-mono">{{ formatCurrency(bilhetes_premiados.sorteados) }}</span>
                        </div>

                        <ProgressBar class="awards-progressbar"
                            :value="bilhetes_premiados.sorteados | formatProgressBarValue(financial_summary.vendas)"
                            :showValue="false" />

                        <span class="value-progressbar">
                            {{ bilhetes_premiados.sorteados | formatProgressBarValue(financial_summary.vendas) }} %
                        </span>
                    </div>

                    <div class="info-financial" v-else>
                        <div class="info-financial-summary">
                            <span>PRÊMIOS AUTENTICADOS</span>
                            <span class="font-mono">{{ formatCurrency(bilhetes_premiados.autenticados) }}</span>
                        </div>

                        <ProgressBar class="awards-progressbar"
                            :value="bilhetes_premiados.autenticados | formatProgressBarValue(financial_summary.vendas)"
                            :showValue="false" />

                        <span class="value-progressbar">
                            {{ bilhetes_premiados.autenticados | formatProgressBarValue(financial_summary.vendas) }} %
                        </span>
                    </div>

                    <div class="info-financial">
                        <div class="info-financial-summary">
                            <span>SALDO DESCARGA</span>
                            <span class="font-mono"
                                :class="financial_summary.saldo_descarga < 0 ? 'value-negativo' : ''">
                                <span v-if="financial_summary.saldo_descarga < 0">-</span>
                                {{ formatCurrency(financial_summary.saldo_descarga) }}
                            </span>
                        </div>

                        <ProgressBar class="discharges-progressbar"
                            :value="financial_summary.saldo_descarga | formatProgressBarValue(financial_summary.vendas)"
                            :showValue="false" />

                        <span class="value-progressbar">
                            {{ financial_summary.saldo_descarga | formatProgressBarValue(financial_summary.vendas) }} %
                        </span>
                    </div>

                    <div class="info-financial">
                        <div class="info-financial-summary">
                            <span v-if="financial_summary.lucro_bruto < 0">PREJUÍZO</span>
                            <span v-else>LUCRO BRUTO</span>
                            <span class="font-mono" :class="financial_summary.lucro_bruto < 0 ? 'value-negativo' : ''">
                                <span v-if="financial_summary.lucro_bruto < 0">-</span>
                                {{ formatCurrency(financial_summary.lucro_bruto) }}
                            </span>
                        </div>

                        <ProgressBar class="gain-progressbar"
                            :class="financial_summary.lucro_bruto < 0 ? 'loss-progressbar' : ''"
                            :value="financial_summary.lucro_bruto | formatProgressBarValue(financial_summary.vendas)"
                            :showValue="false" />

                        <span class="value-progressbar">
                            {{ financial_summary.lucro_bruto | formatProgressBarValue(financial_summary.vendas) }} %
                        </span>
                    </div>

                    <div class="info-financial-summary" v-if="financial_summary.cobranca > 0">
                        <span class="incomes">COBRANÇA</span>
                        <span class="font-mono">{{ formatCurrency(financial_summary.cobranca) }}</span>
                    </div>

                    <div class="info-financial-summary" v-else>
                        <span class="incomes">COBRANÇA</span>
                        <span class="value-negativo font-mono">-{{ formatCurrency(financial_summary.cobranca) }}</span>
                    </div>
                </div>
            </div>

            <div class="card" v-if="!this.loading.sellers">
                <div class="item item--1" @click="openModalSellers('all-sellers')">
                    <i class="fad fa-users text--1"></i>
                    <span class="quantity text--1"> {{ sellerSummary.revendedores }} </span>
                    <span class="text text--1"> REVENDEDORES </span>
                </div>

                <div class="item item--2" @click="openModalSellers('sellers-with-sales')">
                    <i class="fad fa-user-check text--2"></i>
                    <span class="quantity text--2"> {{ sellerSummary.revendedores_com_vendas }} </span>
                    <span class="text text--2"> REVENDEDORES <br> C/VENDAS </span>
                </div>

                <div class="item item--3" @click="openModalSellers('sellers-without-sales')">
                    <i class="fad fa-user-times text--3"></i>
                    <span class="quantity text--3"> {{ sellerSummary.revendedores_sem_vendas }} </span>
                    <span class="text text--3"> REVENDEDORES <br> S/VENDAS </span>
                </div>

                <div class="item item--4" @click="openModalSellers('sellers-without-sales-last-hour')">
                    <i class="fad fa-user-clock text--4"></i>
                    <span class="quantity text--4"> {{ sellerSummary.revendedores_sem_vendas_ultima_hora }} </span>
                    <span class="text text--4"> S/VENDAS NA <br> ÚLTIMA HORA </span>
                </div>
            </div>

            <div class="card" v-else>
                <Skeleton width="100%" height="100%"></Skeleton>
                <Skeleton width="100%" height="100%"></Skeleton>
                <Skeleton width="100%" height="100%"></Skeleton>
                <Skeleton width="100%" height="100%"></Skeleton>
            </div>

            <div class="container-dashboard top-points">
                <h4 class="title-container header-summary">TOP 10 PONTOS</h4>
                <div class="header-top-points">
                    <span>NOME</span>
                    <span>VENDA</span>
                    <span>PRÊMIOS</span>
                </div>

                <div class="skeleton-loader">
                    <v-skeleton-loader class="mx-auto" max-width="300" type="text@10" v-if="this.loading.top_points">
                    </v-skeleton-loader>
                </div>

                <div v-if="!this.loading.top_points">
                    <div class="info-top-points" v-for="ponto in topPoints" :key="ponto.id"
                        @click="openModalTopSellers(ponto.point_id)">
                        <div class="name-points">
                            <i class="fad fa-medal"></i> {{ ponto.nome }}
                        </div>
                        <div class="font-mono"> {{ formatCurrency(ponto.total_vendas) }}</div>
                        <div class="font-mono"> {{ formatCurrency(ponto.premio) }}</div>
                    </div>
                </div>
            </div>
        </main>

        <div class="boxs-balances">
            <div class="container-dashboard balance-entries">

                <h4 class="title-container header-summary">FINANCEIRO</h4>

                <div class="skeleton-loader">
                    <v-skeleton-loader class="mx-auto" max-width="300" type="text@5"
                        v-if="this.loading.financial_summary">
                    </v-skeleton-loader>
                </div>

                <div v-if="!this.loading.financial_summary">
                    <div class="box-info-container">
                        <div class="info-container">
                            <span>ENTRADA</span>
                            <span class="font-mono">{{ formatCurrency(financial_summary.recebido) }}</span>
                        </div>

                        <div class="info-container">
                            <span>SAÍDA</span>
                            <span class="font-mono">{{ formatCurrency(financial_summary.enviado) }}</span>
                        </div><br><br>

                        <div class="info-container" v-if="financial_summary.saldo_lancamentos < 0">
                            <span>TOTAL</span>
                            <span class="value-negativo font-mono">-{{
                                formatCurrency(financial_summary.saldo_lancamentos)
                            }}</span>
                        </div>

                        <div class="info-container" v-else>
                            <span>TOTAL</span>
                            <span class="font-mono">{{ formatCurrency(financial_summary.saldo_lancamentos) }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-dashboard closure">

                <h4 class="title-container header-summary">BILHETES PREMIADOS</h4>

                <div class="skeleton-loader">
                    <v-skeleton-loader class="mx-auto" max-width="300" type="text@5"
                        v-if="this.loading.bilhetes_premiados">
                    </v-skeleton-loader>
                </div>

                <div v-if="!this.loading.bilhetes_premiados">
                    <div class="box-info-container">
                        <div class="info-container">
                            <span>AUTENTICADOS</span>
                            <span class="font-mono">{{ formatCurrency(bilhetes_premiados.autenticados) }}</span>
                        </div>

                        <div class="info-container">
                            <span>PENDENTES</span>
                            <span class="font-mono">{{ formatCurrency(bilhetes_premiados.pendentes) }}</span>
                        </div>

                        <div class="info-container">
                            <span>VENCIDOS</span>
                            <span class="font-mono">{{ formatCurrency(bilhetes_premiados.vencidos) }}</span>
                        </div><br>

                        <div class="info-container" v-if="bilhetes_premiados.total < 0">
                            <span>TOTAL</span>
                            <span class="value-negativo font-mono">-{{
                                formatCurrency(bilhetes_premiados.total)
                            }}</span>
                        </div>

                        <div class="info-container" v-else>
                            <span>TOTAL</span>
                            <span class="font-mono">{{ formatCurrency(bilhetes_premiados.total) }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-dashboard discharge-summary">

                <h4 class="title-container header-summary">DESCARGA</h4>

                <div class="skeleton-loader">
                    <v-skeleton-loader class="mx-auto" max-width="300" type="text@5"
                        v-if="this.loading.financial_summary">
                    </v-skeleton-loader>
                </div>

                <div v-if="!this.loading.financial_summary">
                    <div class="box-info-container">
                        <div class="info-container">
                            <span>VENDA</span>
                            <span class="font-mono">{{ formatCurrency(financial_summary.vendas_descarga) }}</span>
                        </div>

                        <div class="info-container">
                            <span>COMISSÃO</span>
                            <span class="font-mono">{{ formatCurrency(financial_summary.comissao_descarga) }}</span>
                        </div>

                        <div class="info-container">
                            <span>PRÊMIO</span>
                            <span class="font-mono">{{ formatCurrency(financial_summary.premios_descarga) }}</span>
                        </div><br>

                        <div class="info-container" v-if="financial_summary.saldo_descarga < 0">
                            <span>SALDO DESCARGA</span>
                            <span class="value-negativo font-mono">-{{
                                formatCurrency(financial_summary.saldo_descarga)
                            }}</span>
                        </div>

                        <div class="info-container" v-else>
                            <span>SALDO DESCARGA</span>
                            <span class="font-mono">{{ formatCurrency(financial_summary.saldo_descarga) }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-dashboard closure">

                <h4 class="title-container header-summary">FECHAMENTO</h4>

                <div class="skeleton-loader">
                    <v-skeleton-loader class="mx-auto" max-width="300" type="text@5"
                        v-if="this.loading.financial_summary">
                    </v-skeleton-loader>
                </div>

                <div v-if="!this.loading.financial_summary">
                    <div class="box-info-container">
                        <div class="info-container" v-if="financial_summary.lucro_bruto < 0">
                            <span>PREJUÍZO</span>
                            <span class="value-negativo font-mono">-{{
                                formatCurrency(financial_summary.lucro_bruto)
                            }}</span>
                        </div>

                        <div class="info-container" v-else>
                            <span>LUCRO BRUTO</span>
                            <span class="font-mono">{{ formatCurrency(financial_summary.lucro_bruto) }}</span>
                        </div>

                        <div class="info-container">
                            <span>DESPESAS</span>
                            <span class="font-mono">{{ formatCurrency(financial_summary.despesas) }}</span>
                        </div>

                        <div class="info-container">
                            <span>RECLAMAÇÕES</span>
                            <span class="font-mono">{{ formatCurrency(financial_summary.reclamacoes) }}</span>
                        </div>

                        <div class="info-container">
                            <span>CANA</span>
                            <span class="font-mono">{{ formatCurrency(this.caneValues) }}</span>
                        </div>

                        <div class="info-container" v-if="(financial_summary.lucro_liquido + this.caneValues) < 0">
                            <span>PREJUÍZO</span>
                            <span class="value-negativo font-mono">-{{
                                formatCurrency(financial_summary.lucro_liquido + this.caneValues)
                            }}</span>
                        </div>

                        <div class="info-container" v-else>
                            <span>LUCRO LÍQUIDO</span>
                            <span class="font-mono">{{
                                formatCurrency(financial_summary.lucro_liquido + this.caneValues)
                            }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-dashboard" style="align-itemn: center;">
            <div class="header-summary">
                <h4 class="title-container">RESUMO MENSAL</h4>

                <div class="container-checkbox-awards">
                    <label class="switch-line" for="checkbox-line">
                        <input type="checkbox" id="checkbox-line" @click="typeChart()" />
                        <div class="slider-line round-line">
                            <span class="text-checkbox-bar" v-if="this.type_chart">
                                BARRAS
                            </span>

                            <span class="text-checkbox-line" v-if="!this.type_chart">
                                LINHAS
                            </span>
                        </div>
                    </label>
                </div>
            </div>

            <GChart v-if="!this.loading.chart_months && !this.type_chart" type="LineChart" :data="barChartData"
                :options="barChartOptions" />

            <GChart v-if="!this.loading.chart_months && this.type_chart" type="ColumnChart" :data="barChartData"
                :options="barChartOptions" />

            <div class="chart-months" v-if="this.loading.chart_months">
                <v-progress-circular indeterminate color="primary">
                </v-progress-circular>
            </div>
        </div>

        <Dialog :showHeader="false" :visible.sync="modal_sellers" :containerStyle="{ width: '50vw' }" :modal="true"
            dismissableMask>
            <header class="header-modal-seller">
                <h1 class="title-modal">{{ this.title_modal }}</h1>
                <button class="btn-open-filters" @click="openFilters()">
                    <i class="fas fa-sliders-h"></i>
                </button>
            </header>

            <div class="filters" v-if="this.filters_modal">
                <MultiSelect @change="regionChange()" v-model="region_search" :options="regions" optionLabel="name"
                    optionValue="id" placeholder="Regiões" />
                <MultiSelect @change="sessionChange()" v-model="session_search" :options="sessions" optionLabel="name"
                    optionValue="id" placeholder="Seções" />
                <InputText v-model="searchSellers" placeholder="Revendedor" />
            </div>

            <DataTable dataKey="id" :value="selected_sellers" :loading="loading_table" class="table-primevue"
                responsiveLayout="scroll">
                <Column field="region" header="REGIÃO" :sortable="true"></Column>
                <Column field="session" header="SEÇÃO" :sortable="true"></Column>
                <Column field="name" header="REVENDEDOR" :sortable="true"></Column>
                <Column field="last_sale" header="TEMPO SEM JOGAR" :sortable="true">
                    <template #body="slotProps">
                        <div v-if="slotProps.data.last_sale == 0">
                            NENHUM BILHETE FEITO!
                        </div>

                        <div v-else>
                            <!-- <div v-if="slotProps.data.different_in_days > 1">
                                há {{ slotProps.data.different_in_days }} dias
                            </div> -->

                            <div v-if="slotProps.data.different_in_days > 1">
                                {{ slotProps.data.different_str_time }} ({{ slotProps.data.different_in_days }})
                            </div>

                            <div v-else>
                                {{ slotProps.data.different_str_time }}
                            </div>
                        </div>
                    </template>
                </Column>
            </DataTable>
        </Dialog>
    </div>
</template>

<script>
import moment from 'moment';
import Skeleton from 'primevue/skeleton';
import Dialog from 'primevue/dialog';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import MultiSelect from 'primevue/multiselect';
import { FilterMatchMode } from 'primevue/api';
import InputText from 'primevue/inputtext';
import ProgressBar from 'primevue/progressbar';

export default {
    name: "SearchDashboardCard",

    components: {
        Skeleton,
        Dialog,
        DataTable,
        Column,
        MultiSelect,
        InputText,
        ProgressBar,
    },

    created() {
        //this.loadUsers();
        this.loadSellerSummary();
        this.loadRegions();
        this.loadSessions();
        this.loadFinancialSummary();
        this.loadTopTenPoints();
        this.loadBilhetesPremiados();
        this.loadDashboardMeses();
        this.loadCaneCalculation();
    },

    data: () => ({
        ticket: {},
        barChartData: [],
        barChartOptions: {
            height: 400,
            chartArea: {
                left: 150,
                top: 50,
                width: '80%',
                height: '80%'
            },
            vAxis: {
                format: 'decimal',
                textPosition: 'out'
            },
            legend: {
                position: 'top',
                alignment: 'center'
            },
            colors: [
                'blue',
                '#e5aa1e',
                '#d70000',
                '#000',
                '#00deff',
                '#00b50d'
            ],
            focusTarget: 'category',
            tooltip: {
                isHtml: true
            }
        },
        point_id: null,
        financial_summary: [],

        // SNACKBAR
        snackbar: {},

        // LOADING
        loading: {
            regions: false,
            sessions: false,
            sellers: false,
            financial_summary: false,
            top_points: false,
            bilhetes_premiados: false,
            chart_months: false,
        },
        loading_table: false,

        searchSellers: '',
        datepicker: false,
        date_search: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
        users: [],
        regions: [],
        sessions: [],
        caneValues: [],
        sellerSummary: {},
        topPoints: [],

        region_search: [],
        session_search: [],
        dialog: false,
        bilhetes_premiados: [],
        searchAwards: false,
        search_awards: '',
        lucro_autenticado: 0,
        dialog_sellers: false,
        modal_sellers: false,
        modal_of_sellers: '',
        selected_sellers: [],
        title_modal: '',
        filters_modal: false,
        selected_modal: false,
        type_chart: false
    }),

    methods: {
        loadCaneCalculation() {
            this.caneValues = 0;

            this.$api.get('reports/cane-calculation', {
                headers: {
                    Authorization: `Bearer ${this.accessToken()}`
                },
                params: {
                    region_search: this.region_search,
                    session_search: this.session_search,
                    from: this.date_search[0],
                    to: this.date_search[1],
                }
            })
                .then((res) => {
                    this.caneValues = Number(res.data.cane);
                })
                .catch((err) => {
                    console.log(err);
                })
        },

        typeChart() {
            this.type_chart = !this.type_chart
        },

        openFilters() {
            this.filters_modal = !this.filters_modal;
        },

        openModalSellers(value) {
            this.modal_sellers = !this.modal_sellers;
            this.selected_modal = value;
            this.checkSelectedSellers();
        },

        checkSelectedSellers() {
            switch (this.selected_modal) {
                case 'all-sellers':
                    this.title_modal = 'TODOS REVENDEDORES';
                    return this.selected_sellers = this.users;

                case 'sellers-with-sales':
                    this.title_modal = 'REVENDEDORES COM VENDAS';
                    return this.selected_sellers = this.users.filter((seller) => { return seller.sales > 0 });

                case 'sellers-without-sales':
                    this.title_modal = 'REVENDEDORES SEM VENDAS';
                    return this.selected_sellers = this.users.filter((seller) => { return seller.sales == 0 });

                case 'sellers-without-sales-last-hour':
                    this.title_modal = 'REVENDEDORES S/ JOGAR NA ÚLTIMA HORA';
                    return this.selected_sellers = this.users.filter((seller) => { return seller.sales_last_hour == 0 });

                default:
                    break;
            }
        },

        searchAnalytics() {
            this.loadTopTenPoints();
            this.loadBilhetesPremiados();
            this.loadDashboardMeses();
            this.loadSellerSummary();
            this.loadCaneCalculation(); 
            this.loadFinancialSummary();
        },

        formatDateFull(time) {
            return moment(time).format('DD/MM/YYYY HH:mm');
        },

        searchAward: function () {
            this.searchAwards = !this.searchAwards;

            if (this.searchAwards == true) {
                this.search_awards = 'criado'
                this.loadFinancialSummary();
            } else {
                this.search_awards = 'autenticado'
                this.loadFinancialSummary();
            }
        },

        openModalTopSellers(point) {
            this.point_id = point;
            this.dialog = true;
        },

        openModalSalesLastHour() {
            this.dialog_sellers = true;
        },

        close() {
            this.dialog_sellers = false;
            this.dialog = false;
        },

        //DASHBOARD MESES
        loadDashboardMeses() {
            this.loading.chart_months = true;

            this.$api
                .get("reports/dashboard-meses", {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                    params: {
                        region_search: this.region_search,
                        session_search: this.session_search,
                        from: this.date_search[0],
                        to: this.date_search[1],
                    }
                })
                .then((response) => {
                    var monthNames = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
                        "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
                    ];

                    var date = new Date();

                    function monthsAgo(n) {
                        if ((date.getMonth() - n) < 0) {
                            return 12 + date.getMonth() - n
                        } else {
                            return date.getMonth() - n
                        }
                    }

                    this.barChartData = [
                        ['Dashboard', 'Vendas', 'Comissão', 'Prêmios', 'Despesas', 'Descarga', 'Lucro/Prej'],
                        [monthNames[monthsAgo(5)], parseFloat(response.data[5][0]), parseFloat(response.data[5][1]), parseFloat(response.data[5][2]), parseFloat(response.data[5][3]), parseFloat(response.data[5][4]), parseFloat(response.data[5][5])],
                        [monthNames[monthsAgo(4)], parseFloat(response.data[4][0]), parseFloat(response.data[4][1]), parseFloat(response.data[4][2]), parseFloat(response.data[4][3]), parseFloat(response.data[4][4]), parseFloat(response.data[4][5])],
                        [monthNames[monthsAgo(3)], parseFloat(response.data[3][0]), parseFloat(response.data[3][1]), parseFloat(response.data[3][2]), parseFloat(response.data[3][3]), parseFloat(response.data[3][4]), parseFloat(response.data[3][5])],
                        [monthNames[monthsAgo(2)], parseFloat(response.data[2][0]), parseFloat(response.data[2][1]), parseFloat(response.data[2][2]), parseFloat(response.data[2][3]), parseFloat(response.data[2][4]), parseFloat(response.data[2][5])],
                        [monthNames[monthsAgo(1)], parseFloat(response.data[1][0]), parseFloat(response.data[1][1]), parseFloat(response.data[1][2]), parseFloat(response.data[1][3]), parseFloat(response.data[1][4]), parseFloat(response.data[1][5])],
                        [monthNames[monthsAgo(0)], parseFloat(response.data[0][0]), parseFloat(response.data[0][1]), parseFloat(response.data[0][2]), parseFloat(response.data[0][3]), parseFloat(response.data[0][4]), parseFloat(response.data[0][5])],
                    ];
                    this.loading.chart_months = false;
                })
                .catch(() => {

                })
        },

        //BILHETES PREMIADOS
        loadBilhetesPremiados() {
            this.loading.bilhetes_premiados = true;

            this.$api
                .get("reports/premiosDashboard", {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                    params: {
                        region_search: this.region_search,
                        session_search: this.session_search,
                        from: this.date_search[0],
                        to: this.date_search[1],
                    }
                })
                .then((response) => {
                    this.bilhetes_premiados = response.data;
                    this.loading.bilhetes_premiados = false;
                })
                .catch(() => {

                })

        },

        //TOP TEN POINTS
        loadTopTenPoints() {
            this.loading.top_points = true;

            this.$api
                .get('reports/topTenPoints', {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                    params: {
                        region_search: this.region_search,
                        session_search: this.session_search,
                        from: this.date_search[0],
                        to: this.date_search[1]
                    }
                })
                .then((response) => {
                    this.topPoints = response.data;
                    this.loading.top_points = false;
                })
                .catch(() => {

                })

        },

        //FINANCIAL SUMMARY
        loadFinancialSummary() {
            this.loading.financial_summary = true;
            this.datepicker = false;

            this.$api
                .get("reports/dashboard", {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                    params: {
                        region_search: this.region_search,
                        session_search: this.session_search,
                        from: this.date_search[0],
                        to: this.date_search[1],
                        search_awards: this.search_awards
                    }
                })
                .then((response) => {
                    this.financial_summary = response.data;
                    this.lucro_autenticado = response.data.lucro_bruto;
                    this.loading.financial_summary = false;

                    if (this.search_awards === 'criado') {
                        this.financial_summary.lucro_bruto = response.data.lucro_sorteado - this.bilhetes_premiados.sorteados;
                        this.financial_summary.lucro_liquido = this.financial_summary.lucro_bruto - this.financial_summary.despesas - this.financial_summary.reclamacoes;
                    } else {
                        this.financial_summary.lucro_bruto = response.data.lucro_bruto;
                        this.financial_summary.lucro_liquido = response.data.lucro_liquido;
                    }


                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar o resumo financeiro.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);

                    this.loading.financial_summary = false;
                })
        },

        // USERS 
        loadUsers() {
            this.loading.sellers = true;
            this.loading_table = true;

            this.$api.get("/reports/detailsSellerDashboard", {
                headers: {
                    Authorization: `Bearer ${this.accessToken()}`
                },
                params: {
                    search_region: this.region_search,
                    session_search: this.session_search,
                    from: this.date_search[0],
                    to: this.date_search[1],
                }
            })
                .then((response) => {
                    this.users = response.data;
                    this.checkSelectedSellers();
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar os usuários.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);
                })
                .finally(() => {
                    this.loading.sellers = false;
                    this.loading_table = false;
                });
        },

        loadSellerSummary() {
            this.loading.sellers = true;
            this.loading_table = true;

            this.$api.get("/reports/sellerSummary", {
                headers: {
                    Authorization: `Bearer ${this.accessToken()}`
                },
                params: {
                    search_region: this.region_search,
                    session_search: this.session_search,
                    from: this.date_search[0],
                    to: this.date_search[1],
                }
            })
                .then((response) => {
                    this.sellerSummary = response.data;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar o resumo de usuários.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);
                })
                .finally(() => {
                    this.loading.sellers = false;
                    this.loading_table = false;
                });
        },


        //REGION
        loadRegions() {
            this.loading.regions = true;

            this.$api
                .get("/regions", {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    }
                })
                .then((response) => {
                    this.regions = response.data;
                    this.loading.regions = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar as regiões.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);

                    this.loading.regions = false;
                })

        },

        //SESSIONS
        loadSessions() {
            this.loading.sessions = true;

            this.$api
                .get("/sessions", {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                    params: {
                        search_region: this.region_search
                    }
                })
                .then((response) => {
                    this.sessions = response.data;
                    this.loading.sessions = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar as sessões.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);

                    this.loading.sessions = false;
                })
        },

        regionChange() {
            this.session_search = [];
            this.loadSessions();
            //this.loadUsers();
        },

        sessionChange() {
            //this.loadUsers();
        }
    },

    watch: {
        // AUTO CLOSE DATEPICKER OR RELOAD ALL DATA
        date_search: function (val) {
            if (val[0] > val[1]) {

                this.snackbar.text = "Data não compatível!";
                this.snackbar.type = "error";
                this.snackbarShow(this.snackbar);

                return;
            }

            if (val[1]) {
                this.datepicker = false;
            }

            if (!val[1] && !val[0]) {
                this.datepicker = false;
            }
        },

        searchSellers: function () {
            return this.selected_sellers = this.users.filter(user => {
                return user.name.toLowerCase().includes(this.searchSellers.toLowerCase())
            })
        }
    },

    computed: {
        // DATE FORMAT 
        dateRangeText: {
            get() {
                return (this.date_search[0] != null && this.date_search[1] != null) ? this.formatDate(this.date_search[0]) + ' até ' + this.formatDate(this.date_search[1]) : this.date_search.join('');
            },

            set() {
                this.date_search = [null, null];
            }
        },

        // totalCane() {
        //     let cane = 0;

        //     for (let item of this.caneValues) {
        //         cane += Number(item.cane);
        //     }

        //     return cane;
        // },
    },

    filters: {
        formatProgressBarValue(value, total) {
            if (value == null || value == 0) {
                return 0
            }
            return parseInt(((value / total) * 100).toFixed(2));
        },
    }
};
</script>

<style lang="scss" scoped>
.header-modal-seller {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn-open-filters {
        background-color: #1e88e5;
        border-radius: 5px;
        width: 30px;
        height: 30px;
        color: #fff;
    }
}

.filters {
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

::v-deep .p-multiselect {
    width: 100%;
    max-width: 200px;
}

::v-deep .p-progressbar {
    height: 15px;
    background: #aeaeae;

    .p-progressbar-value {
        max-width: 100%
    }
}

.sales-progressbar ::v-deep .p-progressbar-value {
    background-color: #001dff;
}

.commissions-progressbar ::v-deep .p-progressbar-value {
    background-color: #e5aa1e;
}

.awards-progressbar ::v-deep .p-progressbar-value {
    background-color: #d70000;
}

.discharges-progressbar ::v-deep .p-progressbar-value {
    background-color: #00deff;
}

.gain-progressbar ::v-deep .p-progressbar-value {
    background-color: #008000;
}

.loss-progressbar ::v-deep .p-progressbar-value {
    background-color: #b80000 !important;
}

.card {
    color: white;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 15px;
    overflow: visible;
}

.card .item {
    border-radius: 5px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 1000ms;
    text-transform: uppercase;
    position: relative;
    overflow: hidden;
    text-align: center;
    box-shadow: 0 0 6px 0px #aaa;
    background-color: #fff;

    i {
        font-size: 30px;
    }
}

.item:hover {
    transition: all 0.3s;
    transform: scale(0.95);
    box-shadow: 4px 5px 17px -4px #268391;
    cursor: pointer;

    .text,
    .quantity,
    i {
        color: #fff !important;
        transition: all 700ms;
    }
}

.item::before {
    content: "";
    position: absolute;
    left: -50px;
    width: 0;
    height: 100%;
    background-color: #2c9caf;
    z-index: -1;
    transition: width 700ms;
    color: #ffffff !important;

    .text,
    .quantity,
    i {
        color: #fff !important;
        transition: all 700ms;
    }
}

.item:hover::before {
    width: 250%;
}

.item svg {
    width: 40px;
    height: 40px;
    margin-bottom: 7px;
}

.quantity {
    font-size: 25px;
    font-weight: 600;
}

.text {
    font-size: 12px;
    font-family: inherit;
    font-weight: 600;
}

.text--1 {
    color: rgba(149, 149, 255, 1);
}

.text--2 {
    color: rgba(66, 193, 110, 1);
}

.text--3 {
    color: rgba(252, 161, 71, 1);
}

.text--4 {
    color: rgba(220, 91, 183, 1);
}

.header-search {
    display: flex;
    background-color: #fff;
    border-radius: 5px;
    padding: 15px;
    margin: -15px 0 15px 0;
    box-shadow: 0 0 6px 0px #aaa;
}

main {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;

    .info-financial {
        margin-bottom: 10px;

        .value-progressbar {
            display: flex;
            justify-content: center;
            z-index: 1;
            position: relative;
            filter: drop-shadow(2px 4px 6px black);
            color: #fff;
            margin-top: -19px;
        }
    }

    .info-financial-summary {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .incomes {
            margin: 10px 0;
        }

        span {
            font-size: 14px;
        }
    }


    .top-points {

        .header-top-points {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 10px;
            text-align: center;
            font-size: 14px;
            font-weight: bold;
            margin-top: 10px;
        }

        .title-container {
            font-size: 15px;
            font-weight: bold;
        }

        .info-top-points {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            text-align: center;
            font-size: 14px;
            margin: 3px 0;

            &:hover {
                background-color: #1e88e5;
                border-radius: 3px;
                color: #fff;
                cursor: pointer;
            }

            .name-points {
                text-align: start !important;
                padding-left: 30px;

                i {
                    display: none;
                }
            }
        }
    }
}

.boxs-balances {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
    margin: 15px 0;
}

.chart-months {
    min-height: 300px;
    justify-content: center;
    text-align: center;
    align-items: center;
    display: flex;
}


.container-dashboard {
    background-color: #fff;
    border-radius: 5px;
    width: 100%;
    box-shadow: 0 0 6px 0px #aaa;

    .sales-last-hour {
        &:hover {
            background-color: #1e88e5;
            padding: 5px;
            cursor: pointer;
            color: #fff;
        }
    }

    .skeleton-loader {
        margin: 15px;
    }

    .header-summary {
        display: flex;
        justify-content: space-between;
        background-color: #f0f0f0;
        padding: 10px 15px;

        .text-checkbox-sorteado,
        .text-checkbox-bar {
            font-size: 15px;
            font-weight: bold;
            padding-left: 15px;
            color: #fff;
        }

        .text-checkbox-autenticado {
            font-size: 12px;
            font-weight: bold;
            color: #000;
            padding-left: 31px;
        }

        .text-checkbox-line {
            font-size: 15px;
            font-weight: bold;
            color: #000;
            padding-left: 40px;
        }
    }

    .box-info-container {
        padding: 0 15px;
    }

    .title-container {
        font-size: 15px;
        font-weight: bold;
        width: 100%;
    }

    .info-container-closure {
        display: flex;
        justify-content: space-between;

        span {
            font-size: 14px;
        }
    }

    .info-container {
        display: flex;
        justify-content: space-between;
        margin: 5px 0;

        &:last-child {
            margin-top: 5px;
            padding-top: 5px;
            font-weight: bold;
            border-top: 1px solid #000;
        }

        span {
            font-size: 14px;
        }
    }
}

.value-negativo {
    color: red;
    font-weight: bold;
}

.pie-chart {
    .title-container {
        margin-bottom: 35px;
    }

    .loading-pie-chart {
        display: grid;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 600px) {

    main,
    .boxs-balances {
        display: block;
    }

    .boxs-balances {

        .balance-entries,
        .closure,
        .discharge-summary {
            margin-top: 15px;
        }
    }

    main {

        .pie-chart {
            margin: 15px 0;
        }
    }
}

.info-top-points:first-child {
    .name-points {
        padding-left: 10px !important;

        i {
            color: #ff8e00;
            display: contents !important;
        }
    }
}

.info-top-points:nth-child(2) {
    .name-points {
        padding-left: 10px !important;

        i {
            color: rgb(65, 65, 65);
            display: contents !important;
        }
    }
}

.info-top-points:nth-child(3) {
    .name-points {
        padding-left: 10px !important;

        i {
            color: #795548;
            display: contents !important;
        }
    }
}

.switch,
.switch-line {
    display: inline-block;
    height: 24px;
    position: relative;
    width: 130px;
}

.switch input,
.switch-line input {
    display: none;
}

.slider,
.slider-line {
    background-color: #ccc;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: .4s;
}

.slider:before,
.slider-line:before {
    background-color: #fff;
    bottom: 4px;
    content: "";
    height: 16px;
    left: 10px;
    position: absolute;
    transition: .4s;
    width: 16px;
}

input:checked+.slider,
input:checked+.slider-line {
    background-color: #1e88e5;
}

input:checked+.slider:before,
input:checked+.slider-line:before {
    transform: translateX(95px);
}

.slider.round,
.slider-line.round-line {
    border-radius: 34px;
}

.slider.round:before,
.slider-line.round-line:before {
    border-radius: 50%;
}

.container-checkbox-awards {
    align-items: center;
    display: flex;
    justify-content: space-between;
}
</style>