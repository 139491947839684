<template>
    <div class="modal-message-new">
        <v-dialog v-model="show" max-width="650px">
            <header class="header-content">
                <img src="../../assets/images/icon-message.svg" alt="">
                <h1 class="header-title">ENVIA UMA NOVA MENSAGEM PARA SEUS USUÁRIOS.</h1>
            </header>

            <section class="section-content">
                <span class="title-content">
                    <span>Título</span>
                    <InputText type="text" v-model="message.title" />
                </span>
                
                <div class="message-content">
                    <span>Mensagem</span>
                    <Textarea v-model="message.description" :autoResize="true" rows="5" cols="30" />
                </div>

                <div class="inputs-content">
                    <div class="input">
                        <span>Gravidade</span>
                        <Dropdown v-model="selectedSeverity" :options="severitys" optionLabel="severity" optionValue="severity" placeholder="Select a gravidade" />
                    </div>
    
                    <div class="input">
                        <span>Regiões</span>
                        <MultiSelect v-if="!this.loading.regions" v-model="selectedRegions" @change="regionChange" :options="regions" optionValue="id" optionLabel="name" placeholder="Selecione as Regiões" />
                        <Skeleton v-else width="100%" height="48px" />
                    </div>
                </div>

                <div class="inputs-content">
                    <div class="input">
                        <span>Seções</span>
                        <MultiSelect v-if="!this.loading.sessions" v-model="selectedSessions" @change="sessionChange" :options="sessions" optionValue="id" optionLabel="name" placeholder="Selecione as Seções" />
                        <Skeleton v-else width="100%" height="48px" />
                    </div>
    
                    <div class="input">
                        <span>Revendedores</span>
                        <MultiSelect v-if="!this.loading.sellers" v-model="selectedSellers" :options="sellers" optionValue="id" optionLabel="name" placeholder="Selecione os Revendedores" />
                        <Skeleton v-else width="100%" height="48px" />
                    </div>
                </div>

                <div>
                    <span>Não selecionando nenhuma das opções acima, irá enviar a mensagem para todos os usuários.</span>
                    <Button class="send-message-btn" type="button" label="ENVIAR MENSAGEM" :icon="icon" iconPos="right" @click="sendMessage()"  />
                </div>
            </section>
        </v-dialog>
    </div>
</template>

<script>
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
import Button from 'primevue/button';
import Skeleton from 'primevue/skeleton';

export default {
    props: {
        dialog: Boolean,
        region: Array
    },

    components: {
        InputText,
        Textarea,
        Dropdown,
        MultiSelect,
        Button,
        Skeleton
    },

    data:() => ({
        snackbar: {},
        show: false,

        selectedSeverity: 'Normal',
        selectedRegions: null,
        selectedSessions: null,
        selectedSellers: null,
        
        severitys: [
            { severity: 'Normal' },
            { severity: 'Importante' },
            { severity: 'Urgente' }
        ],

        message: {
            title: '',
            description: ''
        },

        loading: {
            regions: false,
            sessions: false,
            sellers: false
        },

        icon: 'far fa-paper-plane',

        regions: [],
        sessions: [],
        sellers: []
    }),

    created() {
        this.loadRegions();
    },

    methods: {
        regionChange() {
            this.loadSessions();
        },

        sessionChange() {
            this.loadSellers();
        },

        sendMessage() {
            this.message = {
                regions: this.selectedRegions,
                sessions: this.selectedSessions,
                sellers: this.selectedSellers,
                severity: this.selectedSeverity,
                title: this.message.title,
                description: this.message.description,
                type: 'normal',
            }

            if(this.message.title == '') {
                this.snackbar.text = "Coloque um título para sua mensagem!";
                this.snackbar.type = "red";
                this.snackbarShow(this.snackbar);
            } else if (this.message.description == '') {
                this.snackbar.text = "Escreva a mensagem que deseja enviar!";
                this.snackbar.type = "red";
                this.snackbarShow(this.snackbar);
            } else {
                this.icon = 'pi pi-spin pi-spinner';

                this.$api.post('/messages/store', this.message, {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    }
                })
                .then(() => {
                    this.snackbar.text = "Mensagem criada com sucesso!";
                    this.snackbar.type = "green";
                    this.snackbarShow(this.snackbar);
                    this.icon = 'far fa-paper-plane';
                    this.reload();
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível enviar a mensagem!";
                    this.snackbar.type = "red";
                    this.snackbarShow(this.snackbar);
                    this.icon = 'far fa-paper-plane';
                })
            }
        },
        
        close() {
            this.$emit('close');
        },

        reload() {
            this.close();
            this.$emit('reload');
        },

        loadRegions() {
            if (this.loggedUser().type !== 'master') {
                this.loading.regions = true;

                this.$api.get(`/regions/sender_regions`, { 
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                })
                .then((response) => {
                    this.regions = response.data;
                    this.loading.regions = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar as regiões.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);
                    this.loading.regions = false;
                });
            } else {
                this.loading.regions = true;

                this.$api.get(`/regions`, { 
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                })
                .then((response) => {
                    this.regions = response.data;
                    this.loading.regions = false;
                })
                .catch(() => {
                    this.snackbar.text = "Não foi possível carregar as regiões.";
                    this.snackbar.type = "warning";
                    this.snackbarShow(this.snackbar);
                    this.loading.regions = false;
                });
            }
        },

        loadSessions() {
            this.loading.sessions = true;

            this.$api.get("/sessions", {
                headers: {
                    Authorization: `Bearer ${this.accessToken()}`
                },
                params: {
                    search_region: this.selectedRegions
                }
            })
            .then((response) => {
                this.sessions = response.data;
                this.loading.sessions = false;
            })
            .catch(() => {
                this.snackbar.text = "Não foi possível carregar as sessões.";
                this.snackbar.type = "warning";
                this.snackbarShow(this.snackbar);
                this.loading.sessions = false;
            })
        },

        loadSellers() {
            this.loading.sellers = true;

            this.$api.get(`/users/sellers`, {
                headers: {
                    Authorization: `Bearer ${this.accessToken()}`
                },
                params: {
                    search_session: this.selectedSessions,
                    search_region: this.selectedRegions
                }
            })
            .then(response => {
                this.sellers = response.data;
                this.loading.sellers = false;
            })
            .catch(() => {
                this.snackbar.text = "Não foi possível carregar os revendedores.";
                this.snackbar.type = "warning";
                this.snackbarShow(this.snackbar);
                this.loading.sellers = false;
            });
        },
    },

    watch: {
        // WATCH THE VALUE TO OPEN THE MODAL
        dialog: function (val) {
            if (val) {
                // this.loadDischargeRegions();
            }
            this.show = val;
        },

        // IF USER CLICKS OUTSIDE THE DIALOG 
        show: function (val) {
            val || this.close();
        },
    },
}

</script>

<style lang="scss" scoped>

::v-deep .v-dialog {
    background-color: #fff;
}

::v-deep .p-multiselect-label {
    white-space: pre-line;
}

.header-content {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1e88e5;

    img {
        width: 200px;
    }

    .header-title {
        font-weight: bold;
        font-size: 15px;
        color: #fff;
        margin-left: -50px;
    }
}

.section-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 25px;

    .title-content,
    .message-content {
        display: flex;
        flex-direction: column;
    }

    .inputs-content {
        display: flex;
        gap: 25px;

        .input {
            display: flex;
            flex-direction: column;
            width: 100%;
        }
    }

    .send-message-btn {
        padding: 15px;
        background-color: #1e88e5;
        border-radius: 3px;
        color: #fff;
        width: 100%;
    }
}

</style>